const print = {
    sperant_id: 23,
    route:"/print",
    page:"print",
    district:'Chorrillos',
    whatsapp: ["947302192"],
    titlehead: "Proyecto Print | Departamentos en Chorrillos",
	metadescription:"Vive en la zona más estratégica de Chorrillos. Departamentos de 2 y 3 dorms desde S/276,800. Stay es un proyecto diseñado para familias pequeñas que viven a su propio ritmo y para familias grandes que buscan experiencias únicas. Cotiza aquí",
	metakeywords:"departamentos en chorrillos, departamentos en venta en chorrillos, inmobiliaria, chorrillos, casaideal inmobiliaria, departamentos de 2 y 3 dormitorios, edificio en preventa, departamentos en preventa",
    iziPubKey: {
        prod: "70370416:publickey_UdMIVzQjkfnCWXtQGAiwdzvhOsYgQl5PXuKxscQwrDgw2",
        dev: "70370416:testpublickey_2oLGfavT6dAblPP6FPZvPVNU1a4BaqUITImpgIAt4Kp2C",
    },
    areas:{
        cant:4,
        comunes:[
            {title:"LOBBY & SALA DE ESPERA",icon:"mask sala-de-espera",desktop:require("../../Assets/images/Slider/print/areas-comunes/LOBBY.webp"),movil:require("../../Assets/images/Slider/print/areas-comunes/LOBBY.webp"),paragraph:"Una gran entrada para un condominio que deja huella en Chorrillos. Elegante y cálida para recibir a los tuyos."},
            {title:"ALAMEDA INTERIOR",icon:"mask flower",desktop:require("../../Assets/images/Slider/print/areas-comunes/ALAMEDA.webp"),movil:require("../../Assets/images/Slider/print/areas-comunes/ALAMEDA.webp"),paragraph:"Con vías centrales y alamedas paisajistas que dan vida y color a todo tu condominio."},
            {title:"SALA DE NIÑOS",icon:"mask area-child",desktop:require("../../Assets/images/Slider/print/areas-comunes/SALA-DE-NINOS.webp"),movil:require("../../Assets/images/Slider/print/areas-comunes/SALA-DE-NINOS.webp"),paragraph:"Un lugar para dejar correr la imaginación, con piscina de pelotas y juegos increíbles."},
            {title:"ZONA BBQ",icon:"mask terraza",desktop:require("../../Assets/images/Slider/print/areas-comunes/ZONA-BBQ.webp"),movil:require("../../Assets/images/Slider/print/areas-comunes/ZONA-BBQ.webp"),paragraph:"Un ambiente cálido y especial para disfrutar una parrilla en familia y de una vista espectacular."},
            {title:"ZONA FIT",icon:"mask gym",desktop:require("../../Assets/images/Slider/print/areas-comunes/ZONA-FIT.webp"),movil:require("../../Assets/images/Slider/print/areas-comunes/ZONA-FIT.webp"),paragraph:"Espacio implementado para recargar energías y empezar tu día al máximo."},
            {title:"SALA COWORKING",icon:"coworking",desktop:require("../../Assets/images/Slider/print/areas-comunes/SALA-COWORKING.webp"),position:"center 54%",movil:require("../../Assets/images/Slider/print/areas-comunes/SALA-COWORKING.webp"),paragraph:"Ambiente acogedor para trabajar desde casa y para que los chicos hagan su tarea con mayor tranquilidad."},
            {title:"ZONA RELAX",icon:"relax",desktop:require("../../Assets/images/Slider/print/areas-comunes/ZONA-RELAX.webp"),position:"center 54%",movil:require("../../Assets/images/Slider/print/areas-comunes/ZONA-RELAX.webp"),paragraph:"Un lugar para escaparse de la rutina, leer un libro o solo respirar aire fresco."},
            {title:"ZONA DE MASCOTAS",icon:"pet",desktop:require("../../Assets/images/Slider/print/areas-comunes/ZONA-DE-MASCOTAS.webp"),position:"center 54%",movil:require("../../Assets/images/Slider/print/areas-comunes/ZONA-DE-MASCOTAS.webp"),paragraph:"En Print todos tienen un lugar especial. Espacio con jardín, ideal para que tu mascota se divierta y tome un buen baño. "},
            {title:"PISCINA",icon:"piscina",desktop:require("../../Assets/images/Slider/print/areas-comunes/PISCINA.webp"),position:"center 54%",movil:require("../../Assets/images/Slider/print/areas-comunes/PISCINA.webp"),paragraph:"El alma del condominio, un espacio increíble para que los más pequeños disfruten."},
            {title:"ESTACIONAMIENTO DE BICICLETAS",icon:"mask bici",desktop:require("../../Assets/images/Slider/print/areas-comunes/ESTACIONAMIENTO-DE-BICICLETAS.webp"),movil:require("../../Assets/images/Slider/print/areas-comunes/ESTACIONAMIENTO-DE-BICICLETAS.webp"),paragraph:"Un lugar especial e implementado para guardar a tu amigo de aventuras."},
            {title:"SALA BAR",icon:"sala-estar",desktop:require("../../Assets/images/Slider/print/areas-comunes/SALA-BAR.webp"),position:"center 54%",movil:require("../../Assets/images/Slider/print/areas-comunes/SALA-BAR.webp"),paragraph:"Disfruta de unas copas con tus amigos después del trabajo, un lugar cómodo y fresco en las alturas."},
            {title:"ZONA DE LAVADO",icon:"lavado",desktop:require("../../Assets/images/Slider/print/areas-comunes/ZONA-DE-LAVADO.webp"),position:"center 54%",movil:require("../../Assets/images/Slider/print/areas-comunes/ZONA-DE-LAVADO.webp"),paragraph:"Siempre pensamos en tu practicidad, una lavandería con lavadoras y secadoras para tu comodidad."},

        ],
        interiores:[
            {title:"SALA COMEDOR",icon:"sala-estar",desktop:require("../../Assets/images/Slider/print/interiores/SALA_COMEDOR.webp"),position:"center 54%",movil:require("../../Assets/images/Slider/print/interiores/SALA_COMEDOR.webp"),paragraph:"La mejor parte de tu casa, dos ambientes hechos para compartir en familia."},
            {title:"COCINA",icon:"mask refri",desktop:require("../../Assets/images/Slider/print/interiores/COCINA.webp"),movil:require("../../Assets/images/Slider/print/interiores/COCINA.webp"),position2:"center 48%",paragraph:"Espacio para sacar tu lado culinario y sorprender a la familia y amigos con una cena especial."},
            {title:"DORMITORIO PRINCIPAL",icon:"mask bed",desktop:require("../../Assets/images/Slider/print/interiores/DORMITORIO-PRINCIPAL.webp"),movil:require("../../Assets/images/Slider/print/interiores/DORMITORIO-PRINCIPAL.webp"),paragraph:"Un lugar amplio y especial donde podrás descansar y relajarte a diario."},
            {title:"DORMITOR    IO SECUNDARIO",icon:"mask bed",desktop:require("../../Assets/images/Slider/print/interiores/DORMITORIO-SECUNDARIO.webp"),movil:require("../../Assets/images/Slider/print/interiores/DORMITORIO-SECUNDARIO.webp"),position:"center 65%",paragraph:"Los más pequeños también tendrán un lugar especial para divertirse y jugar."},
            {title:"BAÑO",icon:"mask ducha",desktop:require("../../Assets/images/Slider/print/interiores/bath.webp"),movil:require("../../Assets/images/Slider/print/interiores/bath.webp"),position2:"center 48%",paragraph:"Perfecto para tomar un baño refrescante y cerrar el día totalmente relajado."},
        ],
        exteriores:{
            cantButton:4,
            title:"Avance de obra Febrero",
            desktop:[
                {img:require("../../Assets/images/Slider/print/exteriores/CLOSE-UP.webp")},
                {img:require("../../Assets/images/Slider/print/exteriores/FACHADA-DIA.webp")},
                {img:require("../../Assets/images/Slider/print/exteriores/FACHADA-INTERIOR.webp")},
                {img:require("../../Assets/images/Slider/print/exteriores/FACHADA-NOCHE.webp")},
                
            ]
        },
        avance:{
            cantButton:4,
            title:"Avance de obra Febrero",
            desktop:[
                {img:require("../../Assets/images/Slider/exteriores/ingreso-town.jpg")},
                {img:require("../../Assets/images/Slider/exteriores/dia/town.jpg")},
                {img:require("../../Assets/images/Slider/exteriores/noche/town.jpg")},
            ]
        },
        end_project:{
            cantButton:5,
            title:"",
            images:[]
        },
        depa_piloto:[
            {img:require("../../Assets/images/Slider/print/depa_piloto/img-1.jpg")},
            {img:require("../../Assets/images/Slider/print/depa_piloto/img-2.jpg")},
            // {img:require("../../Assets/images/Slider/print/depa_piloto/img-3.jpg")},
            {img:require("../../Assets/images/Slider/print/depa_piloto/img-4.jpg")},
            {img:require("../../Assets/images/Slider/print/depa_piloto/img-5.jpg")},
            {img:require("../../Assets/images/Slider/print/depa_piloto/img-6.jpg")},
            {img:require("../../Assets/images/Slider/print/depa_piloto/img-7.jpg")},
            {img:require("../../Assets/images/Slider/print/depa_piloto/img-8.jpg")},
            {img:require("../../Assets/images/Slider/print/depa_piloto/img-9.jpg")},
            // {img:require("../../Assets/images/Slider/print/depa_piloto/img-10.jpg")},
        ],
        recorrido:"https://kuula.co/share/collection/7kwkx?logo=-1&info=0&fs=1&vr=1&sd=1&initload=0&thumbs=3&inst=es",
        avance:{
            cantButton:4,
            title:"Avance de obra Febrero",
            desktop:[]
        },
    },
    form:[
        {value:2,title:"2 Dormitorios"},
        {value:3,title:"3 Dormitorios"}
    ],
    info:{
        title:"CERCA DE ",subtitle:"TODO",
        entrega:'En Construcción',
        frase:'LA ZONA MÁS ESTRATÉGICA <span class="bold">DE CHORRILLOS</span>',
        pisos:"17",depas:"64",dorms:"2 y 3 Dorms.",ubicacion:"Calle Ontario 200, La Campiña, Chorrillos",precio:"S/276,800",desde:"50m²",hasta:"65m²",
        fachada:require("../../Assets/images/Slider/print/exteriores/FACHADA-NOCHE.webp"),
        fachadaMinified:require("../../Assets/images/Slider/print/exteriores/FACHADA-NOCHE.webp"),
        parrafo:`
            <p class="paragraph">Un cambio que tú y tu familia necesitan, encuentra en Print la exclusividad y calidad que mereces en un barrio accesible.</p>
            <p class="paragraph">Un proyecto diseñado para familias pequeñas que viven a su propio ritmo y para familias grandes que buscan experiencias únicas.</p>
            <p class="paragraph">Hacer de la cotidianidad un espacio de libertad y de tu barrio, una extensión de tu hogar.</p>
            `,
        parrafoArquitectura1:'Print es un condominio que cuenta con 3 torres y una arquitectura elegante con detalles en concreto caravista.',
        brochure:'/brochure/Brochure-print.pdf',
        arquitectura:'<span class="bold">ARQUITECTURA </span>& DISEÑO',
        // ubicación
        mapa:require("../../Assets/mapas/print.jpg"),
        sitios:[
            {title:"Centro comercial, mercados:",sitio:["Real plaza guardia civil","Metro","Mercado santa rosa","Precio uno ","Plaza Lima Sur"]},
            {title:"Bancos:",sitio:["BBVA","Plaza Vea","Cajero banco de la nación","Cajero bcp","Caja huancayo","Agente scotiabank"]},
            {title:"Salud::",sitio:["Policlínico Essalud Juan José Rodriguez","Inkafarma"]},
            {title:"Estudios:",sitio:["Escuela pnp"]},
           
        ]
    },
    slideDepa:[
            {title:"LOBBY & SALA DE ESPERA",icon:"mask sala-de-espera",desktop:require("../../Assets/images/Slider/print/areas-comunes/LOBBY.webp"),movil:require("../../Assets/images/Slider/print/areas-comunes/LOBBY.webp"),paragraph:"Espacio elegante y fresco que te invita a vivir la experiencia clásica y moderna de Print."},
            // {title:"ZONA BBQ",icon:"mask terraza",desktop:require("../../Assets/images/Slider/print/areas-comunes/resize-zona-bbq.jpg"),movil:require("../../Assets/images/Slider/print/areas-comunes/resize-zona-bbq.jpg"),paragraph:"Un espacio al aire libre con horno para pizzas y más. Disfruta una comida increíble acompañado de tu familia y amigos."},
            // {title:"ZONA FIT",icon:"mask gym",desktop:require("../../Assets/images/Slider/print/areas-comunes/resize-zona-fit.jpg"),movil:require("../../Assets/images/Slider/print/areas-comunes/resize-zona-fit.jpg"),paragraph:"Ejercítate sin salir de casa. Recarga energías para comenzar el día 100% activado."},
            // {title:"COWORKING",icon:"coworking",desktop:require("../../Assets/images/Slider/print/areas-comunes/resize-sala-coworking.jpg"),position:"center 54%",movil:require("../../Assets/images/Slider/print/areas-comunes/resize-sala-coworking.jpg"),paragraph:"Un ambiente ideal para hacer home office o recibir clientes sin salir de casa."},
            // {title:"ZONA RELAX",icon:"relax",desktop:require("../../Assets/images/Slider/print/areas-comunes/resize-zona-relax.jpg"),position:"center 54%",movil:require("../../Assets/images/Slider/print/areas-comunes/resize-zona-relax.jpg"),paragraph:"Un lugar para escaparse de la rutina, leer un libro o solo respirar aire fresco."},
            // {title:"PISCINA",icon:"piscina",desktop:require("../../Assets/images/Slider/print/areas-comunes/resize-piscina.jpg"),position:"center 54%",movil:require("../../Assets/images/Slider/print/areas-comunes/resize-piscina.jpg"),paragraph:"El alma del condominio, un espacio increíble para que los más pequeños disfruten."},
            // {title:"ESTACIONAMIENTO DE BICICLETAS",icon:"mask bici",desktop:require("../../Assets/images/Slider/print/areas-comunes/resize-estacionamiento-bici.jpg"),movil:require("../../Assets/images/Slider/print/areas-comunes/resize-estacionamiento-bici.jpg"),paragraph:"Un lugar especial para guardar a tu compañero de aventuras. Ayuda al medio ambiente y disfrútalo a la vez."},
            // {title:"SALA BAR",icon:"sala-estar",desktop:require("../../Assets/images/Slider/print/areas-comunes/resize-sala-estar.jpg"),position:"center 54%",movil:require("../../Assets/images/Slider/print/areas-comunes/resize-sala-estar.jpg"),paragraph:"Después de un día lleno de actividades, qué mejor que relajarse con una película."},
           
    ],
    atributos:[
        // {icon:"mask edificio",text:"<strong>Departamentos inteligentes</strong>"},
        {icon:"gas",text:"Conexiones de</br><strong>gas natural</strong>"},
        {icon:"mask area-chill",text:"Acceso para <strong> fibra óptica </strong>"},
        {icon:"sala-de-espera",text:"<strong>Lobby &</br> sala de espera</strong>"},
        {icon:"mask flower",text:"<strong>Alameda Interior</strong>"},
        {icon:"mask sky-bar",text:"<strong>Sala Bar</strong>"},
        {icon:"mask parrilla",text:"Zona <strong>BBQ</strong>"},
        {icon:"relax",text:"Zona <strong>Relax</strong>"},
        {icon:"mask piscina",text:"<strong>Piscina</strong>"},
        {icon:"mask coworking",text:"Sala <strong>Coworking</strong>"},
        {icon:"mask area-child",text:" <strong>Sala de niños</strong>"},
        {icon:"mask gym",text:"<strong>Zona Fit</strong>"},
        {icon:"mask bici",text:"Estacionamiento de </br><strong>bicicletas</strong>"},
        {icon:"mask lavado",text:"Zona de <strong>Lavado</strong>"},
        {icon:"mask pet",text:"Zona de <strong>Mascotas</strong>"},
    ],
    tipologias:{
        page:"print",
        template_id: 106,
        buttons:[{text:"02",value:2},{text:"03",value:3},{text:"Todos",value:"all"}],
        brand:require('../../Assets/images/proyectos/brand-print.svg').default,
        cards: [
            {id: 176,type:"FLAT 103A",dorms:"3",vista:"interior",img:require("../../Assets/images/Slider/print/tipologias/Torre_A_FLAT_103.jpg"),metersTotal:"69.30 m²",units:[{price:''}], units_available: 0},
            {id: 161,type:"FLAT TÍPICO 01A",dorms:"3",vista:"exterior",img:require("../../Assets/images/Slider/print/tipologias/Torre_A_FLAT_01.jpg"),metersTotal:"62.55 m²",units:[{price:''}], units_available: 0},
            {id: 162,type:"FLAT TÍPICO 02A",dorms:"2",vista:"exterior",img:require("../../Assets/images/Slider/print/tipologias/Torre_A_FLAT_02.jpg"),metersTotal:"57.33 m²",units:[{price:''}], units_available: 0},
            {id: 163,type:"FLAT TÍPICO 03A",dorms:"3",vista:"exterior",img:require("../../Assets/images/Slider/print/tipologias/Torre_A_FLAT_03.jpg"),metersTotal:"62.97 m²",units:[{price:''}], units_available: 0},
            {id: 164,type:"FLAT TÍPICO 04A",dorms:"3",vista:"interior",img:require("../../Assets/images/Slider/print/tipologias/Torre_A_FLAT_04.jpg"),metersTotal:"62.97 m²",units:[{price:''}], units_available: 0},
            {id: 165,type:"FLAT 05A Y 104A",dorms:"2",vista:"interior",img:require("../../Assets/images/Slider/print/tipologias/Torre_A_FLAT_05 Y 104.jpg"),metersTotal:"57.84 m²",units:[{price:''}], units_available: 0},
            {id: 166,type:"FLAT 06A Y 105A",dorms:"3",vista:"interior",img:require("../../Assets/images/Slider/print/tipologias/Torre_A_FLAT_06 Y 105.jpg"),metersTotal:"62.55 m²",units:[{price:''}], units_available: 0},
            
            {id: 179,type:"FLAT 103B",dorms:"3",vista:"interior",img:require("../../Assets/images/Slider/print/tipologias/Torre_B_FLAT_103.jpg"),metersTotal:"60.93 m²",units:[{price:''}], units_available: 0},
            {id: 180,type:"FLAT 104B",dorms:"3",vista:"interior",img:require("../../Assets/images/Slider/print/tipologias/Torre_B_FLAT_104.jpg"),metersTotal:"60.93 m²",units:[{price:''}], units_available: 0},
            {id: 181,type:"FLAT TIPO 01B",dorms:"3",vista:"interior",img:require("../../Assets/images/Slider/print/tipologias/Torre_B_FLAT_01.jpg"),metersTotal:"62.55 m²",units:[{price:''}], units_available: 0},
            {id: 182,type:"FLAT TIPO 02B",dorms:"3",vista:"interior",img:require("../../Assets/images/Slider/print/tipologias/Torre_B_FLAT_02.jpg"),metersTotal:"57.84 m²",units:[{price:''}], units_available: 0},
            {id: 183,type:"FLAT TIPO 03B",dorms:"3",vista:"interior",img:require("../../Assets/images/Slider/print/tipologias/Torre_B_FLAT_03.jpg"),metersTotal:"62.97 m²",units:[{price:''}], units_available: 0},
            {id: 184,type:"FLAT TIPO 04B",dorms:"3",vista:"interior",img:require("../../Assets/images/Slider/print/tipologias/Torre_B_FLAT_04.jpg"),metersTotal:"62.97 m²",units:[{price:''}], units_available: 0},
            {id: 185,type:"FLAT TIPO 05B",dorms:"2",vista:"interior",img:require("../../Assets/images/Slider/print/tipologias/Torre_B_FLAT_05.jpg"),metersTotal:"57.33 m²",units:[{price:''}], units_available: 0},
            {id: 186,type:"FLAT TIPO 06B",dorms:"2",vista:"interior",img:require("../../Assets/images/Slider/print/tipologias/Torre_B_FLAT_06.jpg"),metersTotal:"62.55 m²",units:[{price:''}], units_available: 0},
            
            {id: 187,type:"FLAT TIPO 01C",dorms:"3",vista:"interior",img:require("../../Assets/images/Slider/print/tipologias/C01X.png"),metersTotal:"64.82 m²",units:[{price:''}], units_available: 0},
            {id: 188,type:"FLAT TIPO 02C",dorms:"2",vista:"interior",img:require("../../Assets/images/Slider/print/tipologias/Torre_C_FLAT_02.jpg"),metersTotal:"50.59 m²",units:[{price:''}], units_available: 0},
            {id: 189,type:"FLAT TIPO 03C",dorms:"3",vista:"interior",img:require("../../Assets/images/Slider/print/tipologias/C03X.png"),metersTotal:"65.33 m²",units:[{price:''}], units_available: 0},
            
        ],
       
    }
}
export default print