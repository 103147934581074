import React, { Component } from 'react'
import "./proyectos.scss"
import $ from 'jquery'
// import inicio from '../../data/projects/pageInicio'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
class Proyectos extends Component {
    constructor(props) {
        super(props)
        this.state = {
            projects: this.props.value.projects,
        }
        this.changeDistric = this.changeDistric.bind(this);
    }
    
    setStateFromInput = ({target}) => this.setState({[target.name]: target.value})
    changeDistric(e){
        console.log(e.target.value)
        let items = this.props.value.projects
        let newFiltered = items.filter((item)=>{
            if(e.target.value == "all") return item
            if(item.ubicacion == e.target.value){
                return item
            }
        })
        this.setState({projects:newFiltered})

    }
    render() {
        return (
            <div className="Proyectos">
                <img class="tramado-1 desktop"src={require("../../Assets/images/iconos/tramado-1.png")}></img>
                <img class="tramado-2 desktop"src={require("../../Assets/images/iconos/tramado-2.png")}></img>
                <img class="tramado-3 desktop"src={require("../../Assets/images/iconos/tramado-3.png")}></img>
                <link href='https://fonts.googleapis.com/css?family=Bree+Serif' rel='stylesheet' type='text/css'></link>
                
                <div className="Content_proyectos" id="Content_proyectos">
                    <div className="selectores">
                        <h2 className="title"  id="all-proyectos">Nuestros Proyectos</h2>
                        <div className="content-select">
                            <span className="title-select">Distritos</span>
                            <select className="distritos" onChange={(e)=>{this.changeDistric(e)}}>
                                <option  value="all" selected>Seleccionar</option>
                                <option value="BARRANCO">Barranco</option>
                                <option value="CHORRILLOS">Chorrillos</option>
                                <option value="CERCADO DE LIMA">Cercado de Lima</option>
                                <option value="MAGDALENA">Magdalena del Mar</option>
                                <option value="PUEBLO LIBRE">Pueblo Libre</option>
                                <option value="SANTA BEATRIZ">Santa Beatriz</option>
                                <option value="JESÚS MARÍA">Jesús María </option>
                                <option value="all">Todos</option>
                            </select>
                        </div>
                    </div>
                <div className="builds">
                    {
                    this.state.projects.map((project, index) =>
                    <div className={`items img1 ${project.class} ${project.id}`} key={index} >
                            <div className="build ">
                                <div className='img-build'>
                                    <img src={project.img}></img>
                                </div>
                                <div className='info-build' style={{backgroundImage:`url(${project.imgProyectos})`}}>

                                    <div className='img_info_build' style={{backgroundImage:`url(${project.infImg})`}}></div>
                                    <div className='type-proyect'>{project.type}</div>
                                    <div className="paragraph">
                                        <span className="title d-block">{project.ubicacion}</span>
                                        <span className="attributes d-block bold">{project.dorms}</span>
                                        <div className="separate"></div>
                                        <p className="p-none heavy" >{project.desde}</p>
                                        <p className="p-none" >{project.hasta || project.address}</p>
                                        <div className="content-icon">
                                            <div className="rule mask"></div>
                                            <span className="meters">{project.desde}m²</span> a <span className="meters">{project.hasta}m²</span>
                                        </div>
                                    </div>
                                    <Link to={project.url} className="button-look">{project.ver}</Link>
                                </div>
                                <div className=''><img className='logo' src={project.bono}></img></div>  
                            </div>
                        </div>
                    )}
                    
                </div>
                </div>
            </div>
        )
    }
}
const MapStateProps = (state)=>{
    return({
        value:state.projectsStore
    })
}
export default connect(MapStateProps,null)(Proyectos)