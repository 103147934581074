import React, { Component } from 'react'
import Contacto from '../components/contacto/contacto'
import Information from '../components/info/information'
import Sliderdeimagenes from '../components/sliderImagenes/sliderdepartamento'
import "./template.scss"
import Areas from '../components/areas/areas'
import Mapa from '../components/mapa/mapa'
import Slider from "react-slick"
import $, { data } from 'jquery'
import Flotante from '../components/flotante/flotante'
import MediaQuery from 'react-responsive'
import { ref } from 'yup'
import Filtro from '../components/filtro/filtro'
import Calculator from '../components/calculadora/calculator'
import Data from '../components/data/data'
import QuoterModal from '../components/quoter/quoter'
import { SperantService } from '../components/common/Http/SperantService'
import { connect } from 'react-redux'
import { updateSelected } from '../data/calculator'
import { ProjectHandler } from './store/ProjectHandler'
import { Helmet } from 'react-helmet'
import { ModalProject } from '../components/modalproject/modalprojects'
import { HOST } from '../data/config'

class Templates extends Component {
    constructor(props){
        super(props)
        this.sperantService = new SperantService()
        this.projectHandler = new ProjectHandler();
        this.changeDorms = this.changeDorms.bind(this)
        this.vista = this.vista.bind(this)
        this.afterChange = this.afterChange.bind(this)
        this.btnNext = this.btnNext.bind(this)
        this.btnPrev = this.btnPrev.bind(this)
        this.activeCard = this.activeCard.bind(this)
        this.skip = this.skip.bind(this)
        this.showQuoter = this.showQuoter.bind(this)
        this.closeQuoter = this.closeQuoter.bind(this)
        this.state = {
            visible: false,
            allCards:[],
            cards: [],
            selectCard: {},
            numDorms:["all"],
            vista:["all"],
            type_id:"",
            data: {
                imagen : false,
                banner: [],
            },
            settings:{
                dots: false,
                arrows:false,
                infinite: false,
                speed: 300,
                pauseOnHover:false,
                autoplay: false,
                autoplaySpeed: 3000,
                variableWidth: true,
                slidesToShow: 3,
                slidesToScroll: 3,
                afterChange:this.afterChange,
                responsive: [
                    {
                      breakpoint: 1100,
                      settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                      }
                    },
                    {
                      breakpoint: 1600,
                      settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                      }
                    },
                ]
            },
        }
    }
    componentWillUpdate(prevProps){
        if(prevProps.data !== this.props.data) {
            this.setState({cards: this.props.data.tipologias.cards,selectCard:this.props.data.tipologias.cards[0]})
            let time = setInterval(() => {
                $('.content-img').lightGallery({
                    selector: '.expand',
                    thumbnail:true,
                    animateThumb: false,
                    showThumbByDefault: false,
                    subHtmlSelectorRelative: true,
                    rotate:false,
                    actualSize:false,
                    download:true,
                    pinterest:false,
                    googlePlus:false,
                    twitter:false,
                });
                clearInterval(time)
            }, 400);
        }
    }
    async fetching(page){
        // const data = await fetch("http://casaideal.test/wp-json/custom-api/v1/projects?page=1");
        // const projects = await data.json();
        // return projects;
        const data = await fetch(`${HOST}/wp-json/custom-api/v1/project?name=${page}`);
        const projects = await data.json();
        return projects;
    }
    async componentDidMount(){
        let data = await this.fetching(this.props.data.page) || false;
        if(data){
            this.setState({data:data})
        }
        window.scrollTo(0,0)
        // const {tipologias, page} = this.props.data;
        const project = await this.projectHandler.processProject(this.props.data);

        this.setState({allCards: project.tipologias.cards, cards: project.tipologias.cards, selectCard: project.tipologias.cards[0]})
        this.props.updateSelected(project.tipologias.cards[0])
        console.log(project.tipologias.cards)
        let time = setInterval(() => {
            $('.content-img-all').lightGallery({
                selector: '.item-img',
                thumbnail:true,
                animateThumb: false,
                showThumbByDefault: false,
                subHtmlSelectorRelative: true,
                rotate:false,
                actualSize:false,
                download:true,
                pinterest:false,
                googlePlus:false,
                twitter:false,
            });

            clearInterval(time)
        }, 400);
    }
    afterChange(){

    }
    showQuoter(type) {
        this.setState({type_id: type.id, visible: true})
    }
    changeDorms(value){
        // let fases = this.props.data.tipologias.cards.filter((e)=>{
        let fases = this.state.allCards.filter((e)=>{
            if(value == "all" && this.state.vista[0] == "all"){
                return e
            }
            else if(value == "all"){
                if(e.vista == this.state.vista[0]){
                    return e
                }
            }
            else if(this.state.vista[0] == "all"){
                if(e.dorms == value){
                    return e
                }
            }else if(e.dorms == value && e.vista == this.state.vista[0]){
                return e
            }
        })
        if(fases.length > 0){
            this.setState({numDorms:[value],cards:fases,selectCard:fases[0]})
        }else{
            this.setState({numDorms:[value],cards:fases})
        }
        let time =  setInterval(() => {
            $('.content-img-all').lightGallery({
                selector: '.item-img',
                thumbnail:true,
                animateThumb: false,
                showThumbByDefault: false,
                subHtmlSelectorRelative: true,
                rotate:false,
                actualSize:false,
                download:true,
                pinterest:false,
                googlePlus:false,
                twitter:false,
            })
            clearInterval(time)
        }, 400)
    }
    vista(value){
        let fases = this.state.allCards.filter((e)=>{
            if(value == "all" && this.state.numDorms[0] == "all"){
               return e
            }
            else if(value == "all"){
                if(e.dorms == this.state.numDorms[0]){
                    return e
                }
            }
            else if(this.state.numDorms[0] == "all"){
                if(e.vista == value){
                    return e
                }
            }else if(e.dorms == this.state.numDorms[0] && e.vista == value){
                return e
            }
        })
        if(fases.length > 0){
            this.setState({vista:[value],cards:fases,selectCard:fases[0]})
        }else{
            this.setState({vista:[value],cards:fases})
        }
        let time =  setInterval(() => {
            $('.content-img-all').lightGallery({
                selector: '.item-img',
                thumbnail:true,
                animateThumb: false,
                showThumbByDefault: false,
                subHtmlSelectorRelative: true,
                rotate:false,
                actualSize:false,
                download:true,
                pinterest:false,
                googlePlus:false,
                twitter:false,
            })
            clearInterval(time)
        }, 400)
    }
    btnNext(){
        this.slider.slickNext()
    }
    btnPrev(){
        this.slider.slickPrev()
    }
    activeCard(type){
        let tipologia = this.state.cards.filter((item)=>{
            if(item.type == type){
                return item
            }
        })
        this.setState({selectCard:tipologia[0]})
        // return tipologia
    }
    closeQuoter() {
        this.setState({visible: false})
    }
    skip(e){
        console.log(this.showSkip)
        e.target.style = "display:none"
        document.querySelector(".paragraph-2").classList.add("active")
    }
    updateSelectUnit=(item)=>{
        this.props.updateSelectUnit(item)
    }
    render(){
        return (
            <Data.Consumer>
            {context =>(
            <main className={`template ${this.props.data.page}`}>
                <Helmet>
                    <title>{this.props.data?.titlehead || ''}</title>
                    <meta name='description' content={this.props.data?.metadescription|| ''}></meta>
                    <meta name='keywords' content={this.props.data?.metakeywords || ''}></meta>
                </Helmet>
                <Calculator page={this.props.data.page} url={this.props.data.whatsapp}></Calculator>
                <Flotante project={this.props.data.page} phone={this.props.data.whatsapp} ></Flotante>
                <ModalProject img={this.state.data.imagen} showmodal={this.state.data?.show_modal}></ModalProject>
                <Sliderdeimagenes img={this.state.data.banner}/>
                <section id="contacto" className="information container">
                    <div className="info">
                        <span className="handing-over bold">{this.props.data.info.entrega}</span>
                        <span className="handing-over separe"><span className="spacing">DEPAS DESDE </span><span className="bold"> {this.props.data.info.precio}</span></span>
                        <span className="title" dangerouslySetInnerHTML={{__html:this.props.data.info.frase}}></span>
                        <div className="content-paragraph" dangerouslySetInnerHTML={{__html:this.props.data.info.parrafo}}>
                        </div>
                        <span className="skip" ref={showSkip =>(this.showSkip = showSkip)} onClick={(e)=>{this.skip(e)}}>Seguir leyendo</span>
                        <p className="paragraph place"><div className={`mask icon-place ${this.props.data.info.class}`}></div><span dangerouslySetInnerHTML={{__html:this.props.data.info.ubicacion}}></span></p>
                        <p className="paragraph place"><div className="mask rule"></div>{this.props.data.info.desde} a {this.props.data.info.hasta}</p>
                        <p className="paragraph place"><div className="mask dorm"></div>{this.props.data.info.dorms}</p>
                        {
                            this.props.data.info.brochure.length > 0 ? 
                            <a href={this.props.data.info.brochure} target="_blank" className="btn-radio outline">Ver brochure</a>
                            :null
                        }
                    </div>
                    <div className="form" id="formulario">
                        <Contacto project={this.props.data} form={this.props.data.form}></Contacto>
                    </div>
                </section>
                <section className="section-atributos">
                    <img className="background-black" src={require("../Assets/images/iconos/background-black.png")}></img>
                    <h2 className="title"><span className="bold">CARACTERÍSTICAS</span> DEL PROYECTO</h2>
                    <div className="content-atributos">
                    {
                        this.props.data.atributos.map((item,index)=>{
                            return(
                                <div key={"attr"+index} className="item">
                                    <div className={"mask "+item.icon}></div>
                                    <span className="text" dangerouslySetInnerHTML={{__html:item.text}}></span>
                                </div>
                            )
                        })
                    }
                    </div>
                </section>
                <section className="description">
                    <div className="container">
                        <img className="render" src={this.props.data.info.fachada}></img>
                        {/* <img className="fachada" src={this.props.data.info.ingreso}></img> */}
                        <div className="content-info">
                            <h2 className="title" dangerouslySetInnerHTML={{__html:this.props.data.info.arquitectura}}></h2>
                            <p className="paragraph" dangerouslySetInnerHTML={{__html:this.props.data.info.parrafoArquitectura1}}></p>
                        </div>
                    </div>
                </section>
                <Areas areas={this.props.data.areas} page={this.props.data.page}></Areas>
                <section id="tipologias" className="section-filter">
                    <div className="container">
                        <div className="options">
                            <h2 className="title">ELIGE TU <span className="bold">CASAIDEAL</span></h2>
                            <span className="sub-title filter-title">FILTROS</span>
                            <span className="sub-title">Número de dormitorios</span>
                            <div className={`content-dorms cant-${this.props.data.tipologias.buttons.length}`}>
                                {
                                    this.props.data.tipologias.buttons.map((e)=>{
                                        return(
                                            <div className={`btn-radio ${e.value} btn-radio-${e.value} ${this.state.numDorms.includes(e.value) ? "active" : ""}`} onClick={element=>this.changeDorms(e.value)}>{e.text}</div>
                                        )
                                    })
                                }
                            </div>
                            <span className="sub-title">Vista del departamento</span>
                            <div className="content-vista">
                                <div className={`btn-radio ${this.state.vista.includes("exterior") ? "active" : ""}`} onClick={e=>this.vista("exterior")}>Exterior</div>
                                <div className={`btn-radio ${this.state.vista.includes("interior") ? "active" : ""}`} onClick={e=>this.vista("interior")}>Interior</div>
                                <div className={`btn-radio ${this.state.vista.includes("all") ? "active" : ""}`} onClick={e=>this.vista("all")}>Todos</div>
                            </div>
                        </div>
                        <div className="result">
                        <MediaQuery query="(min-width: 550px)">
                            {
                            this.state.cards.length == 0 ? <div className="message">Lo sentimos, ya no nos quedan departamentos con esas características.</div> :
                            <div className="content-result">
                                <div className="content-view">
                                    <div className="content-img">
                                        <div className="content-img-all">
                                        {
                                            this.state.allCards.map((item,index)=>{
                                                return(
                                                    <div className='item-img' href={item.img}  data-sub-html={"#tipo-"+index} >
                                                        <img className={`img ${this.state.selectCard.type == item.type ? "active" : ""}`} src={item.img}></img>
                                                        <div className='data-tipo' id={"tipo-"+index}>
                                                            <div className='content-data-tipo'>
                                                               
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        } 
                                        </div>
                                        
                                        <div className="expand" href={this.state.selectCard.img}>
                                            <img className="lupa" src={require("../Assets/iconos/lupa.svg").default}></img>
                                        </div>
                                    </div>
                                    <div className="content-data">
                                        {/* <img className="brand" src={this.props.data.tipologias.brand}></img> */}
                                        <div className="content-info">
                                            <div className="row row-1">
                                                <div className="tipologia type-tipo">
                                                    <span className="info-title">Tipología</span>
                                                    <span className="sub-title">{this.state.selectCard.type}</span>
                                                </div>
                                                {
                                                    this.state.selectCard.dorms ? 
                                                    <div className="area type-tipo">
                                                        <span className="info-title">N° de dorms</span>
                                                        <span className="sub-title">{this.state.selectCard.dorms ? this.state.selectCard.dorms : null}</span>
                                                    </div> : null
                                                }
                                            </div>
                                            <div className="row element-top">
                                                <div className="btn-radio" onClick={(e)=>{context.openCalculator("open",this.state.selectCard, this.props.data.page)}}>Calcular cuota</div>
                                            </div>
                                            <div className="row">
                                                <div className="btn-radio active" onClick={(e)=>{this.showQuoter(this.state.selectCard)}}>Cotizar</div>
                                            </div>
                                        </div>
                                        <div className="content-selection">
                                            <div className="content-slider">
                                            <Slider {...this.state.settings} ref={slider => (this.slider = slider)}>
                                                {
                                                    this.state.cards.map((item)=>{
                                                        return(
                                                        <div className={"card " +item.id} onClick={(e)=>{this.activeCard(item.type);this.props.updateSelected(item)}}>
                                                            <div className="content-card">
                                                                <img src={item.img}></img>
                                                                <div className="content-title">
                                                                    <span className="card-title">{item.type}</span>
                                                                    <span className="card-subtitle">{item.metersTotal ? item.metersTotal : item.areaTechada}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                            </Slider>
                                            </div>
                                            <div className="btn-next" onClick={(e)=>{this.btnNext()}}></div>
                                            <div className="btn-prev" onClick={(e)=>{this.btnPrev()}}></div>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                            }
                        </MediaQuery>
                        <MediaQuery query="(max-width: 550px)">
                            {
                            this.state.cards.length == 0 ? <div className="message">Lo sentimos, ya no nos quedan departamentos con esas características.</div> :
                            <div className="content-result">
                                <div className="content-view">
                                    <div className="content-data">
                                        <div className="content-info">
                                            <div className="row row-1">
                                                <div className="image">
                                                    <img className="brand" src={this.props.data.tipologias.brand}></img>
                                                </div>
                                                <div className="content-meters">
                                                    <div className="tipologia">
                                                        <span className="info-title">TIPOLOGÍA</span>
                                                        <span className="sub-title">{this.state.selectCard.type}</span>
                                                    </div>
                                                    {
                                                        this.state.selectCard.areaTechada ? <div className="area">
                                                            <span className="info-title">ÁREA TECHADA</span>
                                                            <span className="sub-title">{this.state.selectCard.areaTechada}</span>
                                                        </div> : null
                                                    }
                                                    {
                                                        this.state.selectCard.areaLibre ? <div className="tipologia">
                                                            <span className="info-title">ÁREA LIBRE </span>
                                                            <span className="sub-title">{this.state.selectCard.areaLibre}</span>
                                                        </div> : null
                                                    }
                                                        
                                                    {/* {
                                                        this.state.selectCard.aroudFlat ?
                                                    } */}
                                                    {
                                                     this.state.selectCard.metersTotal ?
                                                    <div className="area">
                                                        <span className="info-title">ÁREA TOTAL</span>
                                                        <span className="sub-title">{this.state.selectCard.metersTotal}</span>
                                                    </div> : null 
                                                    }
                                                </div>
                                            </div>
                                            <div className="content-img">
                                                <div className="content-img-all">
                                                {
                                                    this.state.cards.map((item,index)=>{
                                                        return(
                                                            <div className={`item-img ${this.state.selectCard.type == item.type ? "active" : ""}`} href={item.img}  key={'data-'+index} >
                                                                <img className={`img `} src={item.img}></img>
                                                                <div className='data-tipo' id={"tipo-"+index}>
                                                                    
                                                                </div>
                                                            </div>
                                                            // <img className={`img ${this.state.selectCard.type == item.type ? "active" : ""}`} src={item.img} ></img>
                                                        )
                                                    })
                                                }
                                                </div>
                                                <div className="expand" href={this.state.selectCard.img}>
                                                    <img className="lupa" src={require("../Assets/iconos/lupa.svg").default}></img>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="content-selection">
                                    <div className="content-slider">
                                    <Slider {...this.state.settings} ref={slider => (this.slider = slider)}>
                                        {
                                            this.state.cards.map((item)=>{
                                                return(
                                                <div className="card" onClick={(e)=>{this.activeCard(item.type)}}>
                                                    <div className="content-card">
                                                        <img src={item.img}></img>
                                                        <div className="content-title">
                                                            <span className="card-title">{item.type}</span>
                                                            <span className="card-subtitle">{item.metersTotal ? item.metersTotal : item.areaTechada}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                    </Slider>
                                    </div>
                                    <div className="btn-next" onClick={(e)=>{this.btnNext()}}></div>
                                    <div className="btn-prev" onClick={(e)=>{this.btnPrev()}}></div>
                                </div>
                                <div className="row p-6-m">
                                    <div className="btn-radio middle" onClick={(e)=>{context.openCalculator("open",this.state.selectCard, this.props.data.page)}}>Calcular cuota</div>
                                    <div className="btn-radio middle active" onClick={(e)=>{this.showQuoter(this.state.selectCard)}}>Cotizar</div>
                                </div>
                                {/* <div className="row p-6-m">
                                    <div className="btn-radio active">Separar</div>
                                </div> */}
                            </div>
                            }
                            </MediaQuery>
                        </div>
                    </div>
                </section>
                <Mapa  ubicacion={this.props.data.info} page={this.props.data.page}></Mapa>
                <QuoterModal
                    visible={this.state.visible}
                    actionModalCancel={this.closeQuoter}
                    type_id={this.state.type_id}
                    page={this.props.data.page}
                    template_id={this.props.data.tipologias.template_id}
                    sperant_id={this.props.data.sperant_id}
                    />
            </main>
        )}
        </Data.Consumer>
        )
    }
}
export default connect(null,{updateSelected})(Templates)