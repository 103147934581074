import axios from "axios"
import { MailService } from "./MailService"
import { projects } from '../../../data/projects'
import axiosRetry from "axios-retry"

export class SperantService {
  constructor() {
    this.api = axios.create({
      baseURL: "https://cors.formulaperu.com/https://api.sperant.com/v3",
      headers: {
        "Cache-Control": "no-cache",
        "Authorization": "N7GmbRfBu89m1jiW1Wh2aaLdse3OKZJD6yJIgQSy",
      },
    })
    axiosRetry(this.api, { retries: 3 })
    this.mailgun = new MailService()
    this.interceptors()
  }

  interceptors() {
    this.api.interceptors.response.use(
      response => {
        if (window.dataLayer) {
          // console.log(response.config)
          if (response.config.url === "/clients") {
            const reqBody = JSON.parse(response.config.data)
            console.log(reqBody)
            const project = Array.from(projects.values()).find(p => p.sperant_id === reqBody.project_id)
            console.log(project)
            window.dataLayer?.push({ event: "dleLead", project: project.page})
            window.dataLayer?.push({ event: `dleLead_${project.page}`})
          }
        }
        return response
      },
      error => {
        this.mailgun.from("Casaideal <no-reply@casaideal.com.pe>")
            .to("sistemas@formulaperu.com")
            .subject("Sperant API error: " + error.message)
            .html(`
              <h1>${error.message}</h1>
              <h3>${error.config.method.toUpperCase()} ${error.config.baseURL + error.config.url}</h3>
              <h2>Payload</h2>
              <pre>${JSON.stringify(error.config?.data)}</pre>
              <h2>Response</h2>
              <pre>${JSON.stringify(error.response?.data)}</pre>
            `).send()

        return Promise.reject(error)
      }
    )
  }
  createClient(client) {
    const url_query = localStorage.getItem('url_query')
    client.source_id = 4
    if((/google|adwords|googleads|gclid|cpc/i).test(url_query)) client.source_id = 8 // Google Ads
    if((/facebook|fbclid|pixel/i).test(url_query)) client.source_id = 5 // facebook
    if((/mail/i).test(url_query)) client.source_id = 27 // mailing
    
    new URLSearchParams(url_query).forEach((v, k) => {
      /utm_/.test(k) && (client[`${k.toLowerCase()}`] = v)
    })

    return this.api.post("/clients", client)
  }

  projects(id = "") {
    return this.api.get(`/projects/${id}`)
  }

  types(parameters) {
    return this.api.get("/types", { data: parameters })
  }

  units(projectId, filter) {
    return this.api.get(`/projects/${projectId}/units`, {params: filter})
  }

  createBudget(budget) {
    new URLSearchParams(localStorage.getItem("url_query")).forEach((v, k) => {
      /utm_/.test(k) && (budget[`${k.toLowerCase()}`] = v)
    })
    return this.api.post("/budgets", { data: budget })
  }
}