import React, { Component , createRef, useState } from 'react'
import { Link } from "react-router-dom";
import './navbar.scss'
import MediaQuery from 'react-responsive';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import { openCart } from '../../data/carts';
class Navbar extends Component {
    constructor(props){
        super(props)
        this.activePosition = this.activePosition.bind(this)
        this.close = this.close.bind(this)
        this.toggle = this.toggle.bind(this)
        this.toggleLink = this.toggleLink.bind(this)
        this.offset = this.offset.bind(this)
        this.moveTo =  this.moveTo.bind(this)
        this.state = {
            expanded:"panel5",
            activeLine:1,
            activeNav:false,
            closeMore:false,
            toggle:false,
        }
        this.nav = React.createRef()
        
    }
    componentDidMount(){
        if(window.location.pathname == "/"){
            this.setState({activeLine:1})
        }
        if(window.location.pathname == "/nosotros"){
            this.setState({activeLine:5})
        }
        if(window.location.pathname == "/postventa/proyectos"){
            this.setState({activeLine:4})
        }
        if(window.location.pathname == "/postventa"){
            this.setState({activeLine:4})
        }
        if(window.location.pathname == "/proyectos"){
            this.setState({activeLine:2})
        }
        if(window.location.pathname.includes("/beneficios")){
            this.setState({activeLine:3})
        }
    }
    activePosition(e,position){
        console.log(position)
        this.setState({activeLine:position})
        this.toggleLink()
        if(window.location.search.includes("proyectos")){
            this.moveTo("#inicio-page","#all-proyectos")
        }   
    }
    close(e){
        this.setState({closeMore:true})
    }
    toggleLink(e){
        this.setState({toggle:!this.state.toggle})
    }
    toggle(e){
        this.setState({toggle:!this.state.toggle})
    }
    moveTo(elementFrom,elementTo){
        let navHeight = document.querySelector("#nav").clientHeight
        if(document.querySelector(elementTo)){
            window.scrollTo(this.offset(elementFrom).top,this.offset(elementTo).top-(navHeight-50))
        }
    }
    offset(e){
        let element = document.querySelector(e)
        if(element){
            try { 
                let top = element.getBoundingClientRect().top + window.scrollY 
                let left = element.getBoundingClientRect().left
                return {top:top,left: left}
            }catch(err){
                return "dosnt exist"
            }
        }else{
            console.log("doesn't exist")
        }
    }
    
    render() {
        return (
            <nav ref={this.nav} id="nav" className={`navbar ${this.state.activeNav ? "active" : ""}`}>
                <div className="container content-nav">
                    <Link to="/"className="brand">
                        <img className="logo-white" src={require("../../Assets/iconos/casaideal.svg").default}></img>
                    </Link>
                    <MediaQuery query="(min-width: 1200px)">
                        <OptionsNav options={this.state} position={this.activePosition} ></OptionsNav>
                    </MediaQuery>
                    <MediaQuery query="(max-width: 1200px)">
                        <div className={`toggle ${this.state.toggle == true ? "active" : ""}`} onClick={(e)=>{this.toggle(e)}}>
                            <div className="line line-1"></div>
                            <div className="line line-2"></div>
                        </div>
                    </MediaQuery>
                </div>
                <MediaQuery query="(max-width: 1200px)">
                    <OptionsNav options={this.state} position={this.activePosition} moveTo={this.moveTo}></OptionsNav>
                </MediaQuery>
            </nav>
        )
    }
}

class OptionNav extends Component{
    constructor(props){
        super(props)
        this.state = {
            expanded:""
        }
    }
    handleChange = (panel) => (event, newExpanded) => {
        this.setState({expanded: newExpanded ? panel : false})
    };
    render(){
        return(
        <div className={`container-link ${this.props.options.toggle ? "active" : ""}`}>
        <div className="links">
            <div className="center">
                <Link to="/" id="inicio" className={`link ${this.props.options.activeLine == 1 ? "active" : ""}`}
                    onClick={(e)=>{this.props.position(e,1)}}>
                    <div className="content-text cart">
                        <span className="text-link cart">Inicio</span>
                        <span className="barra-line"></span>
                    </div>
                </Link>
                
                <MediaQuery query="(min-width:1200px)">
                    <div id="proyectos" className={`link ${this.props.options.activeLine == 2 ? "active" : ""}`}>
                        <div className="content-text cart">
                            <span className="text-link cart">Proyectos</span>
                            <span className="barra-line"></span>
                        </div>
                        <div className="content-link-hover">
                            <Link to="/proyectos-en-venta" className="projects-sell" onClick={(e)=>{this.props.position(e,2)}}>Proyectos en venta 
                            
                            </Link>
                            <Link  to="/proyectos-entregados" className="projects-ended" onClick={(e)=>{this.props.position(e,2)}}>Proyectos entregados 
                                
                            </Link>
                        </div>
                    </div>
                </MediaQuery>
                <MediaQuery query="(max-width:1200px)">
                    <Link id="proyectos-en-venta" to="/proyectos-en-venta" className={`link ${this.props.options.activeLine == 2.1 ? "active" : ""}`}
                        onClick={(e)=>{this.props.position(e,2.1)}}
                        >
                        <div className="content-text cart">
                            <span className="text-link cart">Proyectos en venta</span>
                            <span className="barra-line"></span>
                        </div>
                    </Link>
                    
                    <Link id="" to="/proyectos-entregados" className={`link ${this.props.options.activeLine == 2.2 ? "active" : ""}`}
                        onClick={(e)=>{this.props.position(e,2.2)}}
                        >
                        <div className="content-text cart">
                            <span className="text-link cart">Proyectos entregados</span>
                            <span className="barra-line"></span>
                        </div>
                    </Link>

                </MediaQuery>
                <Link to="/beneficios" id="inicio" className={`link ${this.props.options.activeLine == 3 ? "active" : ""}`}
                    onClick={(e)=>{this.props.position(e,3)}}>
                    <div className="content-text cart">
                        <span className="text-link cart">Beneficios</span>
                        <span className="barra-line"></span>
                    </div>
                </Link>
                <Link id="postventa" to="/postventa" className={`link ${this.props.options.activeLine == 4 ? "active" : ""}`}
                    onClick={(e)=>{this.props.position(e,4)}}
                    >
                    <div className="content-text cart">
                        <span className="text-link cart">Postventa</span>
                        <span className="barra-line"></span>
                    </div>
                </Link>
                <Link id="nosotros" to="/nosotros" className={`link ${this.props.options.activeLine == 5 ? "active" : ""}`}
                    onClick={(e)=>{this.props.position(e,5)}}
                    >
                    <div className="content-text cart">
                        <span className="text-link cart">Nosotros</span>
                        <span className="barra-line"></span>
                    </div>
                </Link>
                <Link id="contactanos" to="/contactanos" className={`link ${this.props.options.activeLine == 6 ? "active" : ""}`}
                    onClick={(e)=>{this.props.position(e,6)}}
                    >
                    <div className="content-text cart">
                        <span className="text-link cart">Contáctanos</span>
                        <span className="barra-line"></span>
                    </div>
                </Link>
                <Link id="contactanos" to="/Separa-tu-depa" className={`link ${this.props.options.activeLine == 6 ? "active" : ""}`}
                    onClick={(e)=>{this.props.position(e,7)}}
                    >
                    <div className="content-text btn-cart radius b-red">
                        <span className="text-link cart">Compra Online</span>
                        <span className="barra-line"></span>
                    </div>
                </Link>
            </div>
            <div className="link-buttons">
                <a target="_blank" className={`link button button_cart`} onClick={()=>{this.props.openCart()}}>
                    <div  className="redes mask cart-buy">
                    </div>
                    {
                        this.props.value.tipologiesSelected.length > 0 ?
                        <div className="btn-cant-tipos_selected">{ this.props.value.tipologiesSelected.length}</div>
                        : null
                    }
                </a>
                <div className='line'>
                </div>
                <a target="_blank" href="https://www.instagram.com/casaideal.inmobiliaria/" className={`link button`}>
                    <div  className="redes mask instagram">
                    </div>
                </a>
                <a target="_blank" href="https://www.linkedin.com/company/casaideal-inmobiliaria" className={`link button`}>
                    <div  className="redes mask linkdin">
                    </div>
                </a>
                <a  target="_blank" href="https://www.facebook.com/casaideal.com.pe" className={`link button`}>
                    <div className="redes mask facebook">
                    </div>
                </a>
            </div>
        </div>
    </div>
    )
    }
}
const MapStateProps = (state)=>{
    return(
        {
            value:state.cartStore
        }
    )
}
const OptionsNav = connect(MapStateProps,{openCart})(OptionNav)



export default connect(MapStateProps,{openCart})(Navbar)