import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import "./Assets/scss/index.scss";
import Routes from "./routes";
import $ from "jquery";
import {Provider} from 'react-redux'
import store from './data/store'
window.$ = $
window.jQuery = window.$

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <Routes/>
        </Provider>
    </React.StrictMode>
, document.getElementById('root')
);

if(window.location.search){
    localStorage.setItem('url_query', window.location.search)
}

serviceWorker.unregister();
