import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import "./footer.scss"
export default class Footer extends Component {
    render() {
        const email = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.15 15.07"><title>iconosRecurso 1casaideal</title><g id="Capa_2" data-name="Capa 2"><g id="Capa_1-2" data-name="Capa 1"><path d="M18.25,2.93,11.08,9.25,3.9,2.93a.68.68,0,0,0-1,.06A.69.69,0,0,0,3,4l7.63,6.72a.67.67,0,0,0,.91,0L19.16,4a.69.69,0,0,0,.06-1,.68.68,0,0,0-1-.06"/><path d="M20.08,0h-18A2.07,2.07,0,0,0,0,2.07V13a2.07,2.07,0,0,0,2.07,2.07h18A2.07,2.07,0,0,0,22.15,13V2.07A2.07,2.07,0,0,0,20.08,0m.69,13a.69.69,0,0,1-.69.69h-18A.69.69,0,0,1,1.38,13V2.07a.69.69,0,0,1,.69-.69h18a.69.69,0,0,1,.69.69Z"/></g></g></svg>'
        const phone = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.7 22.32"><title>iconosRecurso 2casaideal</title><g id="Capa_2" data-name="Capa 2"><g id="Capa_1-2" data-name="Capa 1"><path d="M18.77,2.92A9.92,9.92,0,0,0,11.71,0a.8.8,0,0,0-.79.79.79.79,0,0,0,.79.79,8.4,8.4,0,0,1,8.4,8.4.8.8,0,0,0,1.59,0,9.93,9.93,0,0,0-2.93-7.06"/><path d="M15.52,10a.79.79,0,0,0,1.58,0A5.39,5.39,0,0,0,11.71,4.6a.79.79,0,0,0,0,1.58A3.81,3.81,0,0,1,15.52,10"/><path d="M13.77,14a2.38,2.38,0,0,0-2.12,1.26.8.8,0,0,0,.21,1.1.78.78,0,0,0,1.1-.21c.35-.5.51-.58.72-.57a10.18,10.18,0,0,1,3.65,2.67.78.78,0,0,1,0,.58A2.69,2.69,0,0,1,16,20.55a2.53,2.53,0,0,1-2-.06A22.41,22.41,0,0,1,6.6,15.71h0A22.51,22.51,0,0,1,1.83,8.39a2.55,2.55,0,0,1-.06-2A2.69,2.69,0,0,1,3.47,5a.78.78,0,0,1,.58,0A10.15,10.15,0,0,1,6.72,8.63c0,.22-.07.38-.57.73a.78.78,0,0,0-.21,1.1.8.8,0,0,0,1.1.21A2.38,2.38,0,0,0,8.3,8.55c-.07-1.26-2.52-4.61-3.7-5A2.34,2.34,0,0,0,3,3.5,4.21,4.21,0,0,0,.33,5.69,4.06,4.06,0,0,0,.37,9a24,24,0,0,0,5.11,7.85l0,0A23.89,23.89,0,0,0,13.34,22a4.6,4.6,0,0,0,1.76.37A3.63,3.63,0,0,0,16.63,22a4.21,4.21,0,0,0,2.19-2.64,2.34,2.34,0,0,0,0-1.63c-.43-1.18-3.77-3.63-5-3.7"/></g></g></svg>'
        const site = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.04 24.7"><title>iconosRecurso 3casaideal</title><g id="Capa_2" data-name="Capa 2"><g id="Capa_1-2" data-name="Capa 1"><path d="M9.52,0A9.53,9.53,0,0,0,0,9.52a16.88,16.88,0,0,0,4.54,11,24.15,24.15,0,0,0,4.51,4.07.84.84,0,0,0,.93,0,23.47,23.47,0,0,0,4.51-4.07A16.85,16.85,0,0,0,19,9.52,9.53,9.53,0,0,0,9.52,0m0,22.8C7.77,21.49,1.71,16.47,1.71,9.52a7.81,7.81,0,0,1,15.62,0c0,7-6.06,12-7.81,13.28"/><path d="M9.52,5.74A3.78,3.78,0,1,0,13.3,9.52,3.78,3.78,0,0,0,9.52,5.74m0,5.85a2.07,2.07,0,1,1,2.07-2.07,2.07,2.07,0,0,1-2.07,2.07"/></g></g></svg>'
        const year = new Date().getFullYear();
        return (
            <div className={"footer "+this.props.page}>
                <div className="column container">
                    <div className="brands">
                        <div className="brand">
                            <img className="item casaideal" src={require("../../Assets/iconos/casaideal-black.svg").default}></img>
                            
                            <div className="content-item">
                                <span className="title ">MIEMBRO DE:</span>
                                <div className="two">
                                    <img alt="asei" className="item asei" src={require("../../Assets/iconos/brand-asei.png")}></img>
                                    <img alt="capeco" className="item capeco" src={require("../../Assets/iconos/brand-capeco.png")}></img>
                                    <a className='dci' href='https://dci.pe/formulario-de-reclamo/'>
                                        <img className="item dci" src={require("../../Assets/images/iconos/dci.png")}></img>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                   
                    <div className='content-column'>
                        <div className="footer-column item1">
                            <h5 className="bold">PROYECTOS</h5>
                            <a href={"/nou"} className="words">Nou</a>
                            {/* <a href={"/town"} className="words">Town</a> */}
                            {/* <a href={"/trend"} className="words">Trend</a> */}
                            <a href={"/unbox"} className="words">Unbox</a>
                            <a href={"/unity"} className="words">Unity</a>
                            <a href={"/midway"} className="words">Midway</a>
                            <a href={"/stay"} className="words">Stay</a>
                            <a href={"/vive"} className="words">Print</a>
                        </div>
                        <div className="footer-column item2">
                            <h5 className="bold">CASAIDEAL</h5>
                            <a target="blank" href="/" className="words">Inicio</a>
                            <a target="blank" href="/Beneficios" className="words">Beneficios</a>
                            {/* <a target="blank" href="/proyectos-en-venta" className="words">Proyectos en venta</a>
                            <a target="blank" href="/proyectos-entregados" className="words">Proyectos entregados</a> */}
                            <a target="blank" href="/nosotros" className="words">Nosotros</a>
                            <a target="blank" href="/postventa" className="words">Postventa</a>
                            <a target="blank" href="/vende-tu-terreno" className="words">Vende tu terreno</a>
                            <a target="blank" href="/contactanos" className="words">Contáctanos</a>
                            <a target="blank" href="/libro-de-reclamaciones" className="words">Libro de reclamaciones</a>
                        </div>
                        <div className="footer-column item4">
                            <h5 className="bold">CONTACTO</h5>
                            <a target="_blank" href="tel:(01) 437 6918" className="words"><i className="icono phone" dangerouslySetInnerHTML={{__html:phone}}></i>(01) 437 6918</a>
                            <a target="_blank" href="mailto:informes@casaideal.com" className="words"><i className="icono email" dangerouslySetInnerHTML={{__html:email}}></i>informes@casaideal.com.pe</a>
                            <a target="_blank" href="https://goo.gl/maps/Y7Z5ZJ7ZFrHBZQEeA"  className="words">
                                <i className="icono site" dangerouslySetInnerHTML={{__html:site}}></i>
                                Av. El Polo Nro. 670 Int. C401, Santiago de Surco
                            </a>
                        </div>
                    </div>
                    <div className="footer-column item5">
                        <span className="title bold">MIEMBRO DE:</span>
                        <div className="content-item ">
                            <img alt="asei" className="item asei" src={require("../../Assets/iconos/brand-asei.png")}></img>
                            <img alt="capeco" className="item capeco" src={require("../../Assets/iconos/brand-capeco.png")}></img>
                            <img className="item dci" src={require("../../Assets/images/iconos/dci.png")}></img>
                        </div>
                    </div>
                </div>
                <div className="copyright">
                    <span className="content_copyright">© {year} CASAIDEAL S.A.C. RUC: 20511456747 . Todos los derechos reservados. Desarrollado por <a href="https://www.formulaperu.com/" target="_blank">Fórmula</a>, agencia de marketing.</span>
                </div>
            </div>
        )
    }
}
