import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    shop:{
        header:require("../../Assets/shop/background-main.png")
    }
}

export const shopStore = createSlice({
    name:'shop',
    initialState,
    reducers:{
        
    }
})

export default shopStore.reducer