const stay = {
	sperant_id: 22,
	route: '/stay',
	page: 'stay',
	district: 'Jesús María',
	whatsapp: ['926852305','970459738'],
	titlehead: "Proyecto Stay | Departamentos en Jesús María",
	metadescription:"Vive en la zona más residencial de Jesús María, límite con San Isidro. Departamentos de 1, 2 y 3 dorms desde S/305,400. Stay es un proyecto lleno de inventiva con una arquitectura moderna, una distribución única y excelentes áreas comunes. Cotiza aquí.",
	metakeywords:"departamentos en jesús maría, departamentos en venta en jesús maría, inmobiliaria, jesús maría, casaideal inmobiliaria, departamentos límite con san Isidro, departamentos de 1, 2 y 3 dormitorios, edificio en construcción, departamentos en construcción",
	iziPubKey: {
		prod: '37525957:publickey_rPOnzYTKtaox2xhlCa9Pv6GpQ6C1S50NLJrF9aU7BadjM',
		dev: '37525957:testpublickey_evoeiDJ09UkEIqXNdcnvFwFWUmZQ0wRW6yyePsOR8ABZZ',
	},
	areas: {
		cant: 4,
		comunes: [
			{
				title: 'LOBBY & SALA DE ESPERA',
				icon: 'mask sala-de-espera',
				desktop: require('../../Assets/images/Slider/stay/comunes/desktop/lobby-stay.jpg'),
				movil: require('../../Assets/images/Slider/stay/comunes/desktop/lobby-stay.jpg'),
				paragraph: 'Espacio elegante y fresco que te invita a vivir la experiencia clásica y moderna de Stay.',
				position: 'center 50%',
			},
			{
				title: 'BAR LOUNGE',
				icon: 'mask bebida',
				desktop: require('../../Assets/images/Slider/stay/comunes/desktop/bar-lounge.jpg'),
				movil: require('../../Assets/images/Slider/stay/comunes/desktop/bar-lounge.jpg'),
				paragraph: 'Nada más especial que tomar una copa de vino con una vista increíble desde las alturas.',
			},
			{
				title: 'COFFEE ROOM & STUDIO',
				icon: 'mask coffee',
				desktop: require('../../Assets/images/Slider/stay/comunes/desktop/coffee.jpg'),
				movil: require('../../Assets/images/Slider/stay/comunes/desktop/coffee.jpg'),
				paragraph: 'Prueba trabajar o estudiar en nuestro coffee room studio, un lugar especial pensado en tu comodidad.',
			},
			{
				title: 'GAME ROOM',
				icon: 'mask game',
				desktop: require('../../Assets/images/Slider/stay/comunes/desktop/game-room.jpg'),
				movil: require('../../Assets/images/Slider/stay/comunes/desktop/game-room.jpg'),
				paragraph: 'Juega y diviértete con tus amigos y familia en nuestra gran sala de juegos, donde uno vuelve a ser niño.',
			},
			{
				title: 'JARDÍN ZEN ',
				icon: 'mask jardin',
				desktop: require('../../Assets/images/Slider/stay/comunes/desktop/jardin-zen.jpg'),
				movil: require('../../Assets/images/Slider/stay/comunes/desktop/jardin-zen.jpg'),
				paragraph: 'Un lugar relajante, especialmente pensado como un espacio para recargar energías del cuerpo y de la mente.',
			},
			{
				title: 'PETSHOWER',
				icon: 'mask pet',
				desktop: require('../../Assets/images/Slider/stay/comunes/desktop/petshower.jpg'),
				movil: require('../../Assets/images/Slider/stay/comunes/desktop/petshower.jpg'),
				position: 'center 65%',
				paragraph: 'Ideal para bañar y secar a tu engreído de cuatro patas sin ensuciar tu departamento.',
			},
			{
				title: 'SALA CREATIVA DE NIÑOS',
				icon: 'mask area-child',
				desktop: require('../../Assets/images/Slider/stay/comunes/desktop/sala-niños.jpg'),
				movil: require('../../Assets/images/Slider/stay/comunes/desktop/sala-niños.jpg'),
				paragraph: 'Un lugar para que los más chiquitos dejen volar su imaginación.',
			},
			{
				title: 'SKY PIZZA & GRILL',
				icon: 'mask terraza',
				desktop: require('../../Assets/images/Slider/stay/comunes/desktop/sky.jpg'),
				movil: require('../../Assets/images/Slider/stay/comunes/desktop/sky.jpg'),
				paragraph: 'Un espacio al aire libre con horno para pizzas y más. Disfruta una comida increíble acompañado de tu familia y amigos.',
			},
			{
				title: 'TRAINING CENTER',
				icon: 'mask gym',
				desktop: require('../../Assets/images/Slider/stay/comunes/desktop/training-center.jpg'),
				movil: require('../../Assets/images/Slider/stay/comunes/desktop/training-center.jpg'),
				paragraph: 'Ejercítate sin salir de casa. Recarga energías para comenzar el día 100% activado.',
			},
			{
				title: 'BIKE PARKING',
				icon: 'mask bici',
				desktop: require('../../Assets/images/Slider/stay/comunes/desktop/bike.jpg'),
				movil: require('../../Assets/images/Slider/stay/comunes/desktop/bike.jpg'),
				paragraph: 'Un lugar especial para guardar a tu compañero de aventuras. Ayuda al medio ambiente y disfrútalo a la vez.',
			},
		],
		interiores: [
			{
				title: 'SALA COMEDOR',
				icon: 'mask relax',
				desktop: require('../../Assets/images/Slider/stay/interiores/desktop/SALA-COMEDOR.webp'),
				movil: require('../../Assets/images/Slider/stay/interiores/desktop/SALA-COMEDOR.webp'),
				position: 'center 65%',
				paragraph: 'Espacio abierto y acogedor para compartir diariamente con la familia y amigos.',
			},
			
			{
				title: 'COCINA',
				icon: 'mask refri',
				desktop: require('../../Assets/images/Slider/stay/interiores/desktop/kitchen-stay.jpg'),
				movil: require('../../Assets/images/Slider/stay/interiores/desktop/kitchen-stay.jpg'),
				position: 'center 38%',
				paragraph: 'Cocina semi abierta para tener la privacidad que necesitas y a la vez compartir esas cenas especiales en esta increíble barra.',
			},
			{
				title: 'COCINA',
				icon: 'mask refri',
				desktop: require('../../Assets/images/Slider/stay/interiores/desktop/kitchen-2-stay.jpg'),
				movil: require('../../Assets/images/Slider/stay/interiores/desktop/kitchen-2-stay.jpg'),
				position: 'center 50%',
				paragraph: 'Cocina semi abierta para tener la privacidad que necesitas y a la vez compartir esas cenas especiales en esta increíble barra.',
			},
			{
				title: 'DORMITORIO PRINCIPAL',
				icon: 'mask bed',
				desktop: require('../../Assets/images/Slider/stay/interiores/desktop/dormitorio-principal.jpg'),
				movil: require('../../Assets/images/Slider/stay/interiores/desktop/dormitorio-principal.jpg'),
				paragraph: 'El espacio perfecto para descansar y relajarse, con ventanales que dan una gran iluminación y ventilación a tu día a día.',
			},
			{
				title: 'DORMITORIO SECUNDARIO',
				icon: 'mask bed',
				desktop: require('../../Assets/images/Slider/stay/interiores/desktop/dormitorio-secundario.jpg'),
				movil: require('../../Assets/images/Slider/stay/interiores/desktop/dormitorio-secundario.jpg'),
				position: 'center 65%',
				paragraph: 'El lugar ideal para que los más chiquitos tengan un espacio propio donde jugar, descansar y crear nuevas historias.',
			},
			
			
			{
				title: 'TERRAZA DE FLAT',
				icon: 'mask terraza',
				desktop: require('../../Assets/images/Slider/stay/interiores/desktop/terraza-flat.jpg'),
				movil: require('../../Assets/images/Slider/stay/interiores/desktop/terraza-flat.jpg'),
				position: 'center 65%',
				paragraph: 'Un lugar perfecto al aire libre para compartir una buena parrilla sin salir de casa.',
			},
			{
				title: 'BAÑO PRINCIPAL',
				icon: 'mask ducha',
				desktop: require('../../Assets/images/Slider/stay/interiores/desktop/bath-stay.jpg'),
				movil: require('../../Assets/images/Slider/stay/interiores/desktop/bath-stay.jpg'),
				position: 'center 40%',
				paragraph: 'Cálido y fresco, perfecto para tomar un buen baño relajante.',
			},
		],
		exteriores: {
			cantButton: 4,
			title: 'Avance de obra Febrero',
			desktop: [
				{
					img: require('../../Assets/images/Slider/exteriores/ingreso-stay.jpg'),
				},
				{
					img: require('../../Assets/images/Slider/exteriores/dia/stay-dia.jpg'),
				},
				{
					img: require('../../Assets/images/Slider/exteriores/noche/stay-noche.jpg'),
				},
			],
		},
		avance: {
			cantButton:"avance-none",
			title: 'Avance de obra Junio',
			desktop:[
                {img:require("../../Assets/images/Slider/stay/avance/img-1.webp")},
                {img:require("../../Assets/images/Slider/stay/avance/img-2.webp")},
                {img:require("../../Assets/images/Slider/stay/avance/img-3.webp")},
                {img:require("../../Assets/images/Slider/stay/avance/img-4.webp")},
                {img:require("../../Assets/images/Slider/stay/avance/img-5.webp")},
                {img:require("../../Assets/images/Slider/stay/avance/img-6.webp")},
            ],
		},
		end_project: {
			cantButton: 5,
			title: '',
			images: [],
		},
		depa_piloto: [
			{
				img: require('../../Assets/images/Slider/stay/depa_piloto/compress-_DSC1849.webp'),
			},
			{
				img: require('../../Assets/images/Slider/stay/depa_piloto/compress-_DSC1859.webp'),
			},
			{
				img: require('../../Assets/images/Slider/stay/depa_piloto/compress-_DSC1874.webp'),
			},
			{
				img: require('../../Assets/images/Slider/stay/depa_piloto/compress-_DSC1944.webp'),
			},
			{
				img: require('../../Assets/images/Slider/stay/depa_piloto/compress-_DSC1954.webp'),
			},
			{
				img: require('../../Assets/images/Slider/stay/depa_piloto/compress-_DSC1969.webp'),
			},
			{
				img: require('../../Assets/images/Slider/stay/depa_piloto/compress-_DSC2089.webp'),
			},
			{
				img: require('../../Assets/images/Slider/stay/depa_piloto/compress-_DSC2199.webp'),
			},
			{
				img: require('../../Assets/images/Slider/stay/depa_piloto/compress-_DSC2209.webp'),
			},
		],
		recorrido: 'https://kuula.co/share/collection/7kFmp?logo=1&info=1&fs=1&vr=0&sd=1&thumbs=1',
	},
	form: [
		{ value: 1, title: '1 Dormitorios' },
		{ value: 2, title: '2 Dormitorios' },
		{ value: 3, title: '3 Dormitorios' },
	],
	info: {
		title: 'CERCA DE ',
		subtitle: 'TODO',
		entrega: 'En Construcción',
		frase: 'LA ZONA MÁS RESIDENCIAL DE <br> <span class="bold">JESÚS MARÍA LÍMITE CON SAN ISIDRO</span>',
		pisos: '17',
		depas: '64',
		dorms: '1, 2 y 3 Dorms.',
		ubicacion: 'Av. San Felipe N° 537-539, Jesús María',
		precio: 'S/305,400',
		desde: '38m²',
		hasta: '77m²',
		fachada: require('../../Assets/images/fachada/Slide/render_page.png'),
		fachadaMinified: require('../../Assets/images/fachada/Slide/render_page.png'),
		parrafo: '<p class="paragraph">Stay es vivir en un distrito donde todo fluye, donde lo clásico cruza la frontera de la modernidad. Un proyecto lleno de inventiva con una arquitectura moderna y una distribución sinigual.</p><p class="paragraph">Jesús María, un distrito que despierta contigo, que le importa tu vida, tu tiempo y tu familia.</p>',
		parrafoArquitectura1: 'Stay cuenta con una arquitectura y urbanismo que hacen honor al buen gusto, con detalles en ladrillo rococho y perfilería de aluminio color negro.',
		brochure: '/brochure/Brochure-stay.pdf',
		arquitectura: '<span class="bold">ARQUITECTURA </span>& DISEÑO',
		// ubicación
		mapa: require('../../Assets/mapas/mapa-stay.jpg'),
		sitios: [
			{ title: 'Supermercados', sitio: ['Metro', 'Plaza Vea', 'Mass'] },
			{
				title: 'Centros comerciales',
				sitio: ['Real Plaza Salaverry', 'C.C. Risso'],
			},
			{
				title: 'Universidades e institutos',
				sitio: ['UPC', 'Universidad del Pacífico'],
			},
			{ title: 'Colegios', sitio: ['Colegio Los Álamos'] },
			{
				title: 'Entretenimiento',
				sitio: ['Galería de Arte Ryochi Jinnai', 'Plazuela Domingo García Rada', 'Parque Raimondi'],
			},
			{
				title: 'Centros médicos',
				sitio: ['Hospital Rebagliati, Clínica Los Ángeles, Clínica Stella Maris'],
			},
		],
	},
	slideDepa: [
		{
			position: '0 center',
			desktop: require('../../Assets/images/Slider/stay/sliderMain/newStay-9.jpg'),
			movil: require('../../Assets/images/Slider/stay/sliderMain/newStay-9.jpg'),
		},
		{
			position: '0 49%',
			desktop: require('../../Assets/images/Slider/stay/sliderMain/newStay-2.jpg'),
			movil: require('../../Assets/images/Slider/stay/sliderMain/newStay-2.jpg'),
		},
		{
			position: '0 43%',
			desktop: require('../../Assets/images/Slider/stay/sliderMain/newStay-3.jpg'),
			movil: require('../../Assets/images/Slider/stay/sliderMain/newStay-3.jpg'),
		},
		{
			position: '0px 66%',
			desktop: require('../../Assets/images/Slider/stay/sliderMain/newStay-4.jpg'),
			movil: require('../../Assets/images/Slider/stay/sliderMain/newStay-4.jpg'),
		},
		{
			position: '0 62%',
			desktop: require('../../Assets/images/Slider/stay/sliderMain/newStay-5.jpg'),
			movil: require('../../Assets/images/Slider/stay/sliderMain/newStay-5.jpg'),
		},
		{
			position: '0 59%',
			desktop: require('../../Assets/images/Slider/stay/sliderMain/newStay-6.jpg'),
			movil: require('../../Assets/images/Slider/stay/sliderMain/newStay-6.jpg'),
		},
		{
			position: '0 66%',
			desktop: require('../../Assets/images/Slider/stay/sliderMain/newStay-8.jpg'),
			movil: require('../../Assets/images/Slider/stay/sliderMain/newStay-8.jpg'),
		},
		{
			position: '0  63%',
			desktop: require('../../Assets/images/Slider/stay/sliderMain/newStay-10.jpg'),
			movil: require('../../Assets/images/Slider/stay/sliderMain/newStay-10.jpg'),
		},
	],
	atributos: [
		// {
		// 	icon: 'mask edificio',
		// 	text: '<strong>Departamentos inteligentes</strong>',
		// },
		{ icon: 'gas', text: 'Conexiones de</br><strong>gas natural</strong>' },
		{
			icon: 'mask area-chill',
			text: 'Acceso para <strong> fibra óptica </strong>',
		},
		{
			icon: 'sala-de-espera',
			text: '<strong>Lobby &</br> sala de espera</strong>',
		},
		{ icon: 'mask terraza', text: '<strong>Sky pizza & grill</strong>' },
		{ icon: 'mask coffee', text: '<strong>Coffee room & studio</strong>' },
		{
			icon: 'mask area-child',
			text: 'Sala <strong>creativa de niños</strong>',
		},
		{ icon: 'mask gym', text: '<strong>Training center</strong>' },
		{ icon: 'mask game', text: '<strong>Game room</strong>' },
		{ icon: 'mask jardin', text: '<strong>jardín Zen</strong>' },
		{ icon: 'mask bici', text: ' <strong>Bike parking</strong>' },
		{ icon: 'mask pet', text: '<strong>Petshower</strong>' },
		{ icon: 'mask bebida', text: '<strong>Bar lounge</strong>' },
		{ icon: 'none desktop', text: '' },
	],
	tipologias: {
		page: 'stay',
		template_id: 93,
		buttons: [
			{ text: '01', value: 1 },
			{ text: '02', value: 2 },
			{ text: '03', value: 3 },
			{ text: 'Todos', value: 'all' },
		],
		brand: require('../../Assets/images/iconos/brand-stay.jpeg'),
		cards: [
			{
				id: 151,
				type: 'Flat 102',
				dorms: '1',
				vista: 'interior',
				img: require('../../Assets/images/tipologias/stay/Modelo-102.jpg'),
				metersTotal: '67,39 m²',
				areaTechada: '48,94 m²',
				areaLibre: '18,45 m²',
				units: [{ price: '' }],
				units_available: 0,
			},
			{
				id: 153,
				type: 'Flat X01',
				dorms: '3',
				vista: 'interior',
				img: require('../../Assets/images/tipologias/stay/Modelo-X01.jpg'),
				metersTotal: '65,6 m²',
				units: [{ price: '' }],
				units_available: 0,
			},
			{
				id: 154,
				type: 'Flat X02',
				dorms: '2',
				vista: 'interior',
				img: require('../../Assets/images/tipologias/stay/Modelo-X02.png'),
				metersTotal: '56,06 m²',
				units: [{ price: '' }],
				units_available: 0,
			},
			{
				id: 155,
				type: 'Flat X03',
				dorms: '3',
				vista: 'exterior',
				img: require('../../Assets/images/tipologias/stay/Modelo-X03.jpg'),
				metersTotal: '77,36 m²',
				units: [{ price: '' }],
				units_available: 0,
			},
			{
				id: 158,
				type: 'Flat X05-A',
				dorms: '3',
				vista: 'exterior',
				img: require('../../Assets/images/tipologias/stay/Modelo-X05A.jpg'),
				metersTotal: '82,70 m²',
				units: [{ price: '' }],
				units_available: 0,
			},
			{
				id: 159,
				type: 'Flat X05-B',
				dorms: '1',
				vista: 'exterior',
				img: require('../../Assets/images/tipologias/stay/Modelo-X05B.jpg'),
				metersTotal: '42,26 m²',
				units: [{ price: '' }],
				units_available: 0,
			},
			{
				id: 157,
				type: 'Flat X06',
				dorms: '1',
				vista: 'interior',
				img: require('../../Assets/images/tipologias/stay/Modelo-X06.jpg'),
				metersTotal: '38,17 m²',
				units: [{ price: '' }],
				units_available: 0,
			},
		],
	},
};
export default stay;
