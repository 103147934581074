import React, { Component } from 'react'
import './calidad.scss'
import $ from 'jquery'
import sr from '../scrollreveal/scrollreveal'


export default class Calidad extends Component {
    componentDidMount(){
        $(document).ready(function(){
            sr.reveal(".description", {origin:"right",duration:1800,distance:"120px",delay:200,reset:false,easing:"ease-out"});
            sr.reveal(".right", {origin:"right",duration:1800,distance:"120px",delay:200,reset:false,easing:"ease-out"});
            sr.reveal(".left", {origin:"left",duration:1800,distance:"120px",delay:200,reset:false,easing:"ease-out"});
            // sr.reveal(".center", {origin:"left",duration:1800,distance:"0px",scale:0.8,delay:200,reset:false,easing:"ease-out"});
        });
    }
    render() {
        const desarollo = require("../../Assets/images/inicio/caracteristicas.png");
        return (
            <div className="calidad">
                <div className="desarrollo_sostenible" ref="center">
                    <div className="left" ref='box2'>
                        <p className="paragraph">Nos comprometemos con el desarrollo de <span className="Fourth">proyectos sostenibles</span></p>
                        <div className="barra"></div>
                        <p className="paragraph-2">
                            <span className="d-block">Construimos hogares eficientes en el uso de recursos,</span>reduciendo el impacto ambiental y contribuyendo al ahorro de las familias.</p>
                    </div>
                    <div className="center">
                        <img src={require("../../Assets/images/certificacion/corazon.png")}></img>
                    </div>
                    <div className="right" ref='box1'>
                        <div className="companies">
                            <img src={desarollo}></img>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
