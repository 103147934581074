import { createSlice } from '@reduxjs/toolkit';
import { projects } from '.';

export const initialState = {
    projects: Array.from(projects.values()),
}

export const compactProject = createSlice({
    name:'shop',
    initialState,
    reducers:{
        modified:(state,projects)=>{
            state.projects = projects.payload
        }
    }
})

export const { modified } = compactProject.actions

export default compactProject.reducer