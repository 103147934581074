const promosform = {
    iniciaTuCuota:[
        {text:"Nou"},
        {text:"Unity"},
        {text:"Midway"},
        {text:"Stay"},
        {text:"Print"},
    ],
    pagaAlaEntrega:[
        {text:"Midway"},
        {text:"Unity"},
        {text:"Print"},
    ],
    referidos:[
        {text:"Nou"},
        {text:"Unity"},
        {text:"Midway"},
        {text:"Stay"},
        {text:"Print"},
        {text:"Unbox"},
    ],
    cocinaRegalo:[
        {text:"Nou"},
        {text:"Unbox"},
        {text:"Unity"},
        {text:"Midway"},
        {text:"Stay"},
    ],
    kitDomatica:[
        {text:"Unbox"},
    ]
}
export default  promosform