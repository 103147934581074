import React, { Component, Suspense} from 'react'
import { BrowserRouter as Router, Route, Switch} from "react-router-dom";
// import Inicio from '../src/components/inicio/inicio'

import Navbar from './components/navbar/navbar';
import Proyectos from './components/proyectos/proyectos';
import Politicas from './components/politicasdeprivacidad/politicasdeprivacidad';
import Calidad from './components/calidad/calidad';
import Footer from './components/footer/footer';
import Contactanos from './components/contactanos/contacto';
import Postventa from './components/postventa/contacto';
import Vendetuterreno from './components/vendetuterreno/contacto';
import Nosotros from './components/nosotros/nosotros';
import Data from './components/data/data'
import $, { ready } from 'jquery'
import axios from "axios"
import { SperantService } from './components/common/Http/SperantService';
import Templates from './pages/templates';
// import town from "./data/projects/town";
import unbox from "./data/projects/unbox";
import midway from "./data/projects/midway";
import nou from "./data/projects/nou";
import trend from './data/projects/trend';
import unity from './data/projects/unity';
import benefits from './data/projects/benefits';
import ContactoInicio from './components/inicio/contacto';
import inicio from './data/projects/pageInicio'
import Loading from './components/UI/loading/loading';
import Terminos from './pages/terminos/terminos';
import Beneficios from './pages/beneficios/beneficios';
import Proyectosenventa from './components/proyectosEnVenta/proyectosenventa';
import ProyectosEntregados from './pages/proyectosEntregados/proyectosEntregados';
import promosform from './data/projects/promosform';
import stay from './data/projects/stay';
import Shop from './pages/store/shop'
import Cart from './components/cart/cart'
import SepararDepa from './pages/separarDepa/seraparDepa'
import print from './data/projects/print';
import Popupselect from './components/popup/popupselect';
import Thanks from './components/thanks/thanks';
import PagePostventa from './pages/Postventa/postventa';
import {Helmet} from "react-helmet";
import Flotante from './components/flotante/flotante';
import { projects } from './data/projects';
import { Politics } from './pages/politicasdeseguridad/politicasdeseguridad';
import { Error404 } from './pages/404/404';
import { ContentThanks } from './pages/thanks/thanksprint';
// import LibroDeReclamacion from './pages/libro-de-reclamacion/libro';

const Inicio = React.lazy(() => import(/* webpackChunkName: "inicio"*//* webpackPreload: true */"../src/components/inicio/inicio"));
const LibroDeReclamacion = React.lazy(() => import(/* webpackChunkName: "LibroDeReclamacion"*//* webpackPreload: true */"../src/pages/libro-de-reclamacion/libro"));

export default class Routes extends Component {
    constructor(props){
        super(props)
        this.openCalculator = this.openCalculator.bind(this);
        this.closeCalculator = this.closeCalculator.bind(this);
        this.calculate = this.calculate.bind(this);
        this.createClient = this.createClient.bind(this);
        this.recalculate = this.recalculate.bind(this);
        this.changeCalculate = this.changeCalculate.bind(this);
        this.firstStep = this.firstStep.bind(this);
        this.editBack = this.editBack.bind(this);
        this.openCart = this.openCart.bind(this);
        this.hideCart = this.hideCart.bind(this)
        this.sperantService = new SperantService();
        this.deleteItem = this.deleteItem.bind(this);
        this.discount = this.discount.bind(this)
        this.showPoliticas = this.showPoliticas.bind(this)

        this.state = {
            firstStep:"",
            open: "",
            calculate: false,
            showPoliticas:false,
            // tipologias: tipologias,
            monthly_fee: 0,
            initial_fee: 0,
            client: {},
            firstStepValues: {},
            random: Math.floor(Math.random() * (0 - 2) + 1),
            result: {
                monthly_fee: 0,
                initial_fee: 0,
            },
            card:{img:"",type:"",dorms:"",brand:"",meters:"",metersTotal:""},
            items:[],
            price:0,
            descuento:0,
            totalPrice:0,
            openCart: false,
        }
    }
    componentDidMount(){
        
    }
    onInitialized(){
       
    }
    hideCart(e){
        if(e.target.dataset.type == "modal-cart"){
            this.setState({openCart:!this.state.openCart})
        }
    }
    closeCalculator(value){
        $(".card").removeClass("choose")
        $(".card").removeClass("opacity")
        this.setState({
            open:value,
        })
    }
    openCalculator(value, card, project) {
        // console.log(card)
        this.setState({
            open: value,
            card: card,
            project: project.charAt(0).toUpperCase() + project.slice(1),
            calculate:"",
        })
    }
    showPoliticas(value){
        this.setState({showPoliticas:value});
        if(window.innerWidth < 700){
            if(value){
                document.querySelector(".template").style = "z-index: 21;position: relative;"
            }else{
                document.querySelector(".template").style = ""
            }
        }
    }
    editBack(){
        this.setState({firstStep: ""})
    }
    firstStep(){
        this.setState({firstStep: "active"})
    }
    changeCalculate(){
        this.setState({calculate: false})
    }
    createClient = async (values) => {
        console.log("fff", this.state.project)
        this.sperantService.createClient({
            fname: values.fname,
            lname: values.lname,
            email: values.email,
            phone: values.telephone,
            interest_type_id: 4,
            project_id: projects.get(this.state.project).sperant_id,
            input_channel_id: 4,
            observation: [
                values.floor && `Unidad: ${values.floor}`,
                values.salary && `Ingreso mensual: S/.${values.salary}`,
                values.initial_fee && `Puede pagar inicial: S/.${values.initial_fee}`,
                values.afp_savings && `Ahorro en AFP: S/.${values.afp_savings}`,
                values.motive && `Motivo: ${values.motive}`,
            ].join(", "),
            ...values.utms
        }).then(({data: {client}}) => {
            this.setState({client: client, firstStepValues: values})
            window.fbq && window.fbq('track', 'Lead')
            this.firstStep()
        })
    }
    calculate = async (values) => {
        const {price, initial_fee, afp_savings} = values
        const initial_required = price.replace(/,/g, '') * 0.1
        const actual_initial = parseFloat(initial_fee.replace(/,/g, '')) + (afp_savings.replace(/,/g, '') * .25)
        // const amount_to_finance = price.replace(/,/g, '') - Math.max(initial_required, actual_initial)
        const amount_to_finance = price.replace(/,/g, '')

        const TEA = 8
        const YEARS = 20
        const YEARLY_FEES = 12

        // Tipo de interés fraccionado (del periodo)
        const im = TEA / YEARLY_FEES / 100;
        const im2 = Math.pow((1 + im), -(YEARLY_FEES * YEARS));

        // Cuota Cap. + Int.
        const cuota = ((amount_to_finance * im) / (1 - im2)).toFixed(2);

        if (!isNaN(cuota)) {
            const isNegative = n => n < 0
            this.setState({
                monthly_fee: !isNegative(cuota) ? cuota : 0,
                initial_fee: !isNegative(actual_initial) ? actual_initial > price ? price : actual_initial : 0,
                calculate: 'active'
            })
        }
        
        let utms = {}
        new URLSearchParams(localStorage.getItem("url_query"))
            .forEach((v, k) => /utm_/.test(k) && (utms[`${k.toLowerCase()}`] = v))

        this.createClient({...this.state.firstStepValues, ...values, utms: utms})

        const date = new Date().toLocaleString('es-PE', { timeZone: 'America/Lima' })
        const {client} = this.state
        const client_phone = this.state.firstStepValues.telephone || client.phone || client.phone
        axios.post("https://cors.formulaperu.com/https://script.google.com/macros/s/AKfycbws7GCpc1eEN5ScQ_IisUkLEwKQHvY_XCe5_KEbXA3ytUWVtA/exec", {
            "ss_id": "1gE-gi5sEibxdSTN9PqiCYjgZ2SwKJFnhGGQlKldOD34",
            "range": `Cotizador!A:XX`,
            "values": [[
                date, client.id, client.fname, client.lname, client_phone, client.email, this.state.project,
                this.state.card.type, values.price, values.initial_fee, values.afp_savings, values.salary, values.motive,
                this.state.monthly_fee, this.state.initial_fee, values.floor,
                utms.utm_source, utms.utm_medium, utms.utm_campaign, utms.utm_content, utms.utm_term
            ]],
        }, {headers: {"X-Requested-With": "XMLHttpRequest"}})
    }
    recalculate(){
        this.setState({calculate: ''})
    }
    openCart(){
        this.setState({openCart:!this.state.openCart})
    }
    
    deleteItem(id){
        document.getElementById(`btn-${id}`).querySelector(".text").textContent = "Separar"
        let items = this.state.items.filter((item)=>{
            return item.id !== id;
        })

        localStorage.setItem("data",JSON.stringify(items))
        this.setState({items:items})
        let update = setInterval(() => {
            this.setState({price:1500 * this.state.items.length});
            clearInterval(update)
        }, 300);
    }
    discount(e){
        e.preventDefault()
        this.setState({descuento:500,totalPrice:this.state.totalPrice-500})
    }
    render() {
       
        return (
            <div className="Main-pages">
                
                <Router>
                    <Data.Provider value={{
                            data: this.state,
                            openCalculator: this.openCalculator,
                            closeCalculator: this.closeCalculator,
                            repeat: this.repeat,
                            pullApart: this.pullApart,
                            calculate: this.calculate,
                            createClient: this.createClient,
                            recalculate: this.recalculate,
                            changeCalculate: this.changeCalculate,
                            firstStep: this.firstStep,
                            editBack:this.editBack,
                            openCart:this.openCart,
                            deleteItem:this.deleteItem,
                            discount:this.discount,
                            hideCart:this.hideCart,
                            showPoliticas:this.showPoliticas
                        }}>
                    <Navbar></Navbar>
                    <Politicas></Politicas>
                    {
                        process.env.NODE_ENV == 'development' ? null : <Loading></Loading>
                    }
                    <Cart></Cart>
                    <Popupselect></Popupselect>
                    <Switch>
                        <Route exact path="/">
                            <Helmet>
                                <title>Casaideal Inmobiliaria | Departamentos en venta en Lima</title>
                                <meta name='description' content='Más de 15 años de experiencia construyendo proyectos multifamiliares en Lima. Conoce nuestros proyectos en venta en Barranco, Cercado de Lima, Jesús María, Pueblo Libre, Magdalena y Chorrillos.'></meta>
                                <meta name='keywords' content='departamentos, departamentos en venta,venta de departamentos, departamentos en Lima, inmobiliaria,inmobiliaria peruana, inmobiliaria en lima, casaideal inmobiliaria, casaideal,proyectos inmobiliarios,departamentos en pueblo libre, departamentos en barranco,departamentos en chorrillos,departamentos en jesus maria,departamentos en magdalena,departamentos en cercado de lima'></meta>
                            </Helmet>
                            <Suspense fallback={<div></div>}><Inicio/></Suspense>
                            <Proyectos/>
                            <Calidad/>
                            <Flotante phone={["014376918"]} ></Flotante>
                            <ContactoInicio form={inicio.form} page="inicio"></ContactoInicio>
                        </Route>
                        {/* {Array.from(projects).map(([name, project], index) =>
                            <Route exact path={project.route}>
                                <Templates data={project}></Templates>
                            </Route>
                        )} */}
                        <Route exact path="/trend">
                            <Templates data={trend}></Templates>
                        </Route>
                        <Route exact path="/nou">
                            <Templates data={nou}></Templates>
                        </Route>
                        <Route exact path="/midway">
                            <Templates data={midway}></Templates>
                        </Route>
                        {/* <Route exact path="/town">
                            <Templates data={town}></Templates>
                        </Route> */}
                        <Route exact path="/unity">
                            <Templates data={unity}></Templates>
                        </Route>
                        <Route exact path="/unbox">
                            <Templates data={unbox}></Templates>
                        </Route>
                        <Route exact path="/stay">
                            <Templates data={stay}></Templates>
                        </Route>
                        <Route exact path="/print">
                            <Templates data={print}></Templates>
                        </Route>
                        <Route exact path="/postventa">
                            <Helmet>
                                <title>Casaideal Inmobiliaria | Postventa</title>
                                <meta name='description' content='Más de 15 años de experiencia construyendo proyectos multifamiliares en Lima. Conoce nuestros proyectos en venta en Barranco, Cercado de Lima, Jesús María, Pueblo Libre, Magdalena y Chorrillos.'></meta>
                                <meta name='keywords' content='departamentos, departamentos en venta,venta de departamentos, departamentos en Lima, inmobiliaria,inmobiliaria peruana, inmobiliaria en lima, casaideal inmobiliaria, casaideal,proyectos inmobiliarios,departamentos en pueblo libre, departamentos en barranco,departamentos en chorrillos,departamentos en jesus maria,departamentos en magdalena,departamentos en cercado de lima'></meta>
                            </Helmet>
                            <PagePostventa></PagePostventa>
                        </Route>
                        <Route exact path="/postventa/:id">
                            <Helmet>
                                <meta name="robots" content="noindex"/>
                            </Helmet>
                            <Postventa page={"proximo"}/>
                        </Route>
                        <Route exact path="/contactanos">
                            <Helmet>
                                <title>Casaideal Inmobiliaria | Contáctanos</title>
                                <meta name='description' content='Más de 15 años de experiencia construyendo proyectos multifamiliares en Lima. Conoce nuestros proyectos en venta en Barranco, Cercado de Lima, Jesús María, Pueblo Libre, Magdalena y Chorrillos.'></meta>
                                <meta name='keywords' content='departamentos, departamentos en venta,venta de departamentos, departamentos en Lima, inmobiliaria,inmobiliaria peruana, inmobiliaria en lima, casaideal inmobiliaria, casaideal,proyectos inmobiliarios,departamentos en pueblo libre, departamentos en barranco,departamentos en chorrillos,departamentos en jesus maria,departamentos en magdalena,departamentos en cercado de lima'></meta>
                            </Helmet>
                            <Contactanos page={"proximo"}/>
                        </Route>
                        <Route exact path="/vende-tu-terreno">
                            <Helmet>
                                <title>Casaideal Inmobiliaria | Vende tu terreno</title>
                                <meta name='description' content='Más de 15 años de experiencia construyendo proyectos multifamiliares en Lima. Conoce nuestros proyectos en venta en Barranco, Cercado de Lima, Jesús María, Pueblo Libre, Magdalena y Chorrillos.'></meta>
                                <meta name='keywords' content='departamentos, departamentos en venta,venta de departamentos, departamentos en Lima, inmobiliaria,inmobiliaria peruana, inmobiliaria en lima, casaideal inmobiliaria, casaideal,proyectos inmobiliarios,departamentos en pueblo libre, departamentos en barranco,departamentos en chorrillos,departamentos en jesus maria,departamentos en magdalena,departamentos en cercado de lima'></meta>
                            </Helmet>
                            <Vendetuterreno page={"proximo"}/>
                        </Route>
                        <Route exact path="/nosotros">
                            <Helmet>
                                <title>Casaideal Inmobiliaria | Nosotros</title>
                                <meta name='description' content='Más de 15 años de experiencia construyendo proyectos multifamiliares en Lima. Conoce nuestros proyectos en venta en Barranco, Cercado de Lima, Jesús María, Pueblo Libre, Magdalena y Chorrillos.'></meta>
                                <meta name='keywords' content='departamentos, departamentos en venta,venta de departamentos, departamentos en Lima, inmobiliaria,inmobiliaria peruana, inmobiliaria en lima, casaideal inmobiliaria, casaideal,proyectos inmobiliarios,departamentos en pueblo libre, departamentos en barranco,departamentos en chorrillos,departamentos en jesus maria,departamentos en magdalena,departamentos en cercado de lima'></meta>
                            </Helmet>
                            <Nosotros></Nosotros>
                        </Route>
                        <Route exact path="/beneficios">
                            <Helmet>
                                <title>Casaideal Inmobiliaria | Beneficios y Promociones</title>
                                <meta name='description' content='Más de 15 años de experiencia construyendo proyectos multifamiliares en Lima. Conoce nuestros proyectos en venta en Barranco, Cercado de Lima, Jesús María, Pueblo Libre, Magdalena y Chorrillos.'></meta>
                                <meta name='keywords' content='departamentos, departamentos en venta,venta de departamentos, departamentos en Lima, inmobiliaria,inmobiliaria peruana, inmobiliaria en lima, casaideal inmobiliaria, casaideal,proyectos inmobiliarios,departamentos en pueblo libre, departamentos en barranco,departamentos en chorrillos,departamentos en jesus maria,departamentos en magdalena,departamentos en cercado de lima'></meta>
                            </Helmet>
                            <Beneficios></Beneficios>
                        </Route>
                        <Route exact path="/Separa-tu-depa">
                            <Helmet>
                                <title>Casaideal Inmobiliaria | Separa tu depa</title>
                                <meta name='description' content='Más de 15 años de experiencia construyendo proyectos multifamiliares en Lima. Conoce nuestros proyectos en venta en Barranco, Cercado de Lima, Jesús María, Pueblo Libre, Magdalena y Chorrillos.'></meta>
                                <meta name='keywords' content='departamentos, departamentos en venta,venta de departamentos, departamentos en Lima, inmobiliaria,inmobiliaria peruana, inmobiliaria en lima, casaideal inmobiliaria, casaideal,proyectos inmobiliarios,departamentos en pueblo libre, departamentos en barranco,departamentos en chorrillos,departamentos en jesus maria,departamentos en magdalena,departamentos en cercado de lima'></meta>
                            </Helmet>
                            <Shop></Shop>
                        </Route>
                        <Route exact path="/Realizar-compra">
                            <Helmet>
                                <title>Casaideal Inmobiliaria | Realizar compra</title>
                                <meta name='description' content='Más de 15 años de experiencia construyendo proyectos multifamiliares en Lima. Conoce nuestros proyectos en venta en Barranco, Cercado de Lima, Jesús María, Pueblo Libre, Magdalena y Chorrillos.'></meta>
                                <meta name='keywords' content='departamentos, departamentos en venta,venta de departamentos, departamentos en Lima, inmobiliaria,inmobiliaria peruana, inmobiliaria en lima, casaideal inmobiliaria, casaideal,proyectos inmobiliarios,departamentos en pueblo libre, departamentos en barranco,departamentos en chorrillos,departamentos en jesus maria,departamentos en magdalena,departamentos en cercado de lima'></meta>
                            </Helmet>
                            <SepararDepa></SepararDepa>
                        </Route>
                        <Route exact path="/beneficios/8-porciento-de-ayuda-en-tu-cuota-inicial">
                            <Terminos data={benefits.CuotaInicial} option={promosform.iniciaTuCuota}></Terminos>
                        </Route>
                        <Route exact path="/beneficios/comprar-hoy-y-paga-a-la-entrega">
                            <Terminos data={benefits.pagarAlaEntrega} option={promosform.pagaAlaEntrega}></Terminos>
                        </Route>
                        <Route exact path="/beneficios/campaña-de-referidos">
                            <Terminos data={benefits.Referidos} option={promosform.referidos}></Terminos>
                        </Route>
                        <Route exact path="/beneficios/Kit-de-cocina-de-regalo">
                            <Terminos data={benefits.cocinaDeRegalo} option={promosform.cocinaRegalo}></Terminos>
                        </Route>
                        <Route exact path="/beneficios/kit-de-domotica-de-regalo">
                            <Terminos data={benefits.domaticaRegalo} option={promosform.kitDomatica} 
                            kitStandar={benefits.productStandard} productPremiun={benefits.promosform}
                            ></Terminos>
                        </Route>
                        <Route exact path="/proyectos-en-venta">
                            <Helmet>
                                <title>Casaideal Inmobiliaria | Proyectos en venta en Lima</title>
                                <meta name='description' content='Más de 15 años de experiencia construyendo proyectos multifamiliares en Lima. Conoce nuestros proyectos en venta en Barranco, Cercado de Lima, Jesús María, Pueblo Libre, Magdalena y Chorrillos.'></meta>
                                <meta name='keywords' content='departamentos, departamentos en venta,venta de departamentos, departamentos en Lima, inmobiliaria,inmobiliaria peruana, inmobiliaria en lima, casaideal inmobiliaria, casaideal,proyectos inmobiliarios,departamentos en pueblo libre, departamentos en barranco,departamentos en chorrillos,departamentos en jesus maria,departamentos en magdalena,departamentos en cercado de lima'></meta>
                            </Helmet>
                            <Proyectosenventa></Proyectosenventa>
                        </Route>
                        <Route exact path="/proyectos-entregados">
                            <Helmet>
                                <title>Casaideal Inmobiliaria | Proyectos entregados en Lima</title>
                                <meta name='description' content='Más de 15 años de experiencia construyendo proyectos multifamiliares en Lima. Conoce nuestros proyectos en venta en Barranco, Cercado de Lima, Jesús María, Pueblo Libre, Magdalena y Chorrillos.'></meta>
                                <meta name='keywords' content='departamentos, departamentos en venta,venta de departamentos, departamentos en Lima, inmobiliaria,inmobiliaria peruana, inmobiliaria en lima, casaideal inmobiliaria, casaideal,proyectos inmobiliarios,departamentos en pueblo libre, departamentos en barranco,departamentos en chorrillos,departamentos en jesus maria,departamentos en magdalena,departamentos en cercado de lima'></meta>
                            </Helmet>
                            <ProyectosEntregados></ProyectosEntregados>
                        </Route>
                        <Route exact path="/gracias/beneficios/:id">
                            <Thanks/>
                        </Route>
                        <Route exact path="/politicas-de-privacidad">
                            <Politics/>
                        </Route>
                        
                        <Route exact path="/gracias/formulario-print">
                            <ContentThanks id="AW-11176589870/NZVkCL-6m7sYEK70tNEp"/>
                        </Route>
                        <Route exact path="/gracias/formulario-stay">
                            <ContentThanks id="AW-11176589870/cWS8CNSnn7sYEK70tNEp"/>
                        </Route>
                        <Route exact path="/gracias/formulario-unity">
                            <ContentThanks id="AW-11176589870/GoHcCLWjn7sYEK70tNEp"/>
                        </Route>
                        <Route exact path="/gracias/formulario-unbox">
                            <ContentThanks id="AW-11176589870/1OOJCMPUlLsYEK70tNEp"/>
                        </Route>
                        <Route exact path="/gracias/formulario-midway">
                            <ContentThanks id="AW-11176589870/CM_2COLklLsYEK70tNEp"/>
                        </Route>
                        <Route exact path="/gracias/:id">
                            <Thanks/>
                        </Route>
                        <Route exact path="/libro-de-reclamaciones">
                            <Helmet>
                                <title>Casaideal Inmobiliaria | Libro de reclamaciones</title>
                                <meta name='description' content='Más de 15 años de experiencia construyendo proyectos multifamiliares en Lima. Conoce nuestros proyectos en venta en Barranco, Cercado de Lima, Jesús María, Pueblo Libre, Magdalena y Chorrillos.'></meta>
                                <meta name='keywords' content='departamentos, departamentos en venta,venta de departamentos, departamentos en Lima, inmobiliaria,inmobiliaria peruana, inmobiliaria en lima, casaideal inmobiliaria, casaideal,proyectos inmobiliarios,departamentos en pueblo libre, departamentos en barranco,departamentos en chorrillos,departamentos en jesus maria,departamentos en magdalena,departamentos en cercado de lima'></meta>
                            </Helmet>
                            <Suspense fallback={<div></div>}>
                                <LibroDeReclamacion></LibroDeReclamacion>
                            </Suspense>
                        </Route>
                        <Route exact path="/*">
                            <Error404/>
                        </Route>
                    </Switch>
                        </Data.Provider>
                    <Footer/>
                </Router>

            </div>
        )
    }
}
