import { configureStore } from "@reduxjs/toolkit";
import shopStore from "./projects/shopStore";
import projectsStore from "./projects/pageInicio";
import compactProjects from "./projects/compactProjects";
import cartStore from "./carts";
import CalculatorStore from "./calculator";
export default configureStore({
    reducer:{
        shopStore, projectsStore, compactProjects, cartStore, CalculatorStore
    }
})