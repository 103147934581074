const nou = {
	sperant_id: 16,
	route: '/nou',
	page: 'nou',
	district: 'Pueblo Libre',
	whatsapp: ['994006347'],
	titlehead:"Proyecto Nou | Departamentos en Pueblo Libre",
    metadescription:"Vive en una ubicación estratégica en Pueblo Libre. Departamentos de 2 y 3 dormitorios desde S/423,400 en Entrega Inmediata. Nou es la opción ideal para disfrutar tu propio espacio rodeado de todas las comodidades que esperabas. Cotiza aquí.",
    metakeywords:"departamentos en pueblo libre, departamentos en venta en pueblo libre, inmobiliaria, pueblo libre, casaideal inmobiliaria, departamentos en la avenida brasil, departamentos de 2 y 3 dormitorios, edificio en entrega inmediata, departamentos en entrega inmediata",
	
	iziPubKey: {
		prod: '49596977:publickey_GFHM0KK4eijwSWc1vrHLUkB5uGMATCADODTloZz1ElwkD',
		dev: '49596977:testpublickey_HUgEIProEd6gJNDhtKNdED6zWsFxVepdvr396ATnOZpeS',
	},
	
	areas: {
		cant: 4,
		comunes: [
			{ title: 'LOBBY', icon: 'lobby', desktop: require('../../Assets/images/Slider/brasil/areas_comunes/desktop/c-lobby.jpg'), movil: require('../../Assets/images/Slider/brasil/areas_comunes/movil/c-lobby.jpg'), position: 'center 20%', paragraph: 'El diseño transmite vida y estilo desde el ingreso.' },
			{ title: 'ZONA DE PARRILLA', icon: 'parrilla', desktop: require('../../Assets/images/Slider/brasil/areas_comunes/desktop/c-parrilla.jpg'), movil: require('../../Assets/images/Slider/brasil/areas_comunes/movil/c-parrilla.jpg'), paragraph: 'Disfruta cocinando en compañía de tu familia y/o amigos con una increíble vista de la ciudad.' },
			{ title: 'ZONA DE PIZZA Y BAR', icon: 'parrilla', desktop: require('../../Assets/images/Slider/brasil/areas_comunes/desktop/c-bar.jpg'), movil: require('../../Assets/images/Slider/brasil/areas_comunes/movil/c-bar.jpg'), paragraph: 'Disfruta con tu familia y amigos de fines de semana diferentes.' },
			{ title: 'JARDÍN ZEN', icon: 'jardin', desktop: require('../../Assets/images/Slider/brasil/areas_comunes/desktop/c-jardin.jpg'), movil: require('../../Assets/images/Slider/brasil/areas_comunes/movil/c-jardin.jpg'), paragraph: 'Desconéctate de un día agitado sin salir de casa.' },
			{ title: 'SALA COWORKING', icon: 'sala-coworking', desktop: require('../../Assets/images/Slider/brasil/areas_comunes/desktop/c-sala.jpg'), movil: require('../../Assets/images/Slider/brasil/areas_comunes/movil/c-sala.jpg'), paragraph: 'Un ambiente ideal para hacer home office o recibir clientes sin salir de casa.' },
			{ title: 'SALA DE ESTAR', icon: 'sala-estar', desktop: require('../../Assets/images/Slider/brasil/areas_comunes/desktop/c-sala2.jpg'), movil: require('../../Assets/images/Slider/brasil/areas_comunes/movil/c-sala2.jpg'), paragraph: 'Después de un día lleno de actividades, que mejor que relajarse con una película.' },
			{ title: 'SALA DE NIÑOS', icon: 'zona-child', desktop: require('../../Assets/images/Slider/brasil/areas_comunes/desktop/c-salachild.jpg'), movil: require('../../Assets/images/Slider/brasil/areas_comunes/movil/c-salachild.jpg'), paragraph: 'El espacio perfecto para compartir con los pequeños de la familia.' },
			{ title: 'SALA DE JUEGOS', icon: 'sala-juegos', desktop: require('../../Assets/images/Slider/brasil/areas_comunes/desktop/c-salajuegos.jpg'), movil: require('../../Assets/images/Slider/brasil/areas_comunes/movil/c-salajuegos.jpg'), position: 'center 20%', paragraph: 'Un espacio para que pases un buen rato con amigos y familiares.' },
			{ title: 'YOGA Y BIENESTAR', icon: 'yoga', desktop: require('../../Assets/images/Slider/brasil/areas_comunes/desktop/c-yoga.jpg'), movil: require('../../Assets/images/Slider/brasil/areas_comunes/movil/c-yoga.jpg'), paragraph: 'Ejercítate sin salir de casa y lleva un estilo de vida saludable.' },
			{ title: 'RELAX ZONE', icon: 'relax', desktop: require('../../Assets/images/Slider/brasil/areas_comunes/desktop/c-relax-zone.jpg'), movil: require('../../Assets/images/Slider/brasil/areas_comunes/movil/c-relax-zone.jpg'), position: 'center 36%', paragraph: 'Tu zona ideal de descanso acompañada de una mesa de fuego.' },
		],
		interiores: [
			{ title: 'COCINA', icon: 'refri', desktop: require('../../Assets/images/Slider/brasil/interiores/desktop/Cocina_1.jpg'), movil: require('../../Assets/images/Slider/brasil/interiores/movil/Cocina_1.jpg'), paragraph: 'Claridad, limpieza y amplitud. Excelente distribución del área con muebles altos y bajos para ahorrar espacio.' },
			{ title: 'SALA', icon: 'sala-estar', desktop: require('../../Assets/images/Slider/brasil/areas_comunes/desktop/c-sala-2.jpg'), movil: require('../../Assets/images/Slider/brasil/areas_comunes/desktop/c-sala-2.jpg'), position: 'center 20%', paragraph: 'Espacios bien iluminados que transmiten confort' },
			{ title: 'DORMITORIO PRINCIPAL', icon: 'bed', desktop: require('../../Assets/images/Slider/brasil/interiores/desktop/Dormitorio-principal.jpg'), movil: require('../../Assets/images/Slider/brasil/interiores/movil/Dormitorio-principal.jpg'), paragraph: 'Habitación ideal para tu día a día. Buena luz natural con amplio espacio.' },
			{ title: 'DORMITORIO SECUNDARIO', icon: 'bed', desktop: require('../../Assets/images/Slider/brasil/interiores/desktop/Dormitorio-secundario.jpg'), movil: require('../../Assets/images/Slider/brasil/interiores/movil/Dormitorio-secundario.jpg'), paragraph: 'Habitación ideal para los pequeños de la casa.' },
			{ title: 'SALA COMEDOR', icon: 'sala-estar', desktop: require('../../Assets/images/Slider/brasil/interiores/desktop/Sala-comedor.jpg'), movil: require('../../Assets/images/Slider/brasil/interiores/movil/Sala-comedor.jpg'), paragraph: 'Espacios funcionales conectados por estilo, diseño y una perfecta distribución de los ambientes.' },
		],
		exteriores: {
			cantButton: 4,
			title: 'Avance de obra Marzo',
			desktop: [{ img: require('../../Assets/images/Slider/exteriores/dia/nou.jpg') }, { img: require('../../Assets/images/Slider/exteriores/noche/nou.jpg') }],
		},
		recorrido: 'https://kuula.co/share/collection/7lXvY?fs=1&vr=1&sd=1&initload=0&thumbs=3&inst=es&info=0&logo=-1',
		avance: {
			cantButton: 4,
			title: 'Avance de obra Mayo',
			desktop: [
				// {img:require("../../Assets/images/Slider/brasil/avance/1.jpg")},
				// {img:require("../../Assets/images/Slider/brasil/avance/2.jpg")},
				// {img:require("../../Assets/images/Slider/brasil/avance/3.jpg")},
				// {img:require("../../Assets/images/Slider/brasil/avance/4.jpg")},
				// {img:require("../../Assets/images/Slider/brasil/avance/5.jpg")},
			],
		},
		end_project: {
			cantButton: 5,
			title: '',
			images: [{ img: require('../../Assets/images/Slider/brasil/end_project/img-1.jpg') }, { img: require('../../Assets/images/Slider/brasil/end_project/img-2.jpg') }, { img: require('../../Assets/images/Slider/brasil/end_project/img-3.jpg') }, { img: require('../../Assets/images/Slider/brasil/end_project/img-4.jpeg') }, { img: require('../../Assets/images/Slider/brasil/end_project/img-5.jpeg') }, { img: require('../../Assets/images/Slider/brasil/end_project/img-6.jpeg') }, { img: require('../../Assets/images/Slider/brasil/end_project/img-7.jpeg') }, { img: require('../../Assets/images/Slider/brasil/end_project/img-8.jpeg') }, { img: require('../../Assets/images/Slider/brasil/end_project/img-9.jpeg') }, { img: require('../../Assets/images/Slider/brasil/end_project/img-10.jpeg') }, { img: require('../../Assets/images/Slider/brasil/end_project/img-11.jpeg') }, { img: require('../../Assets/images/Slider/brasil/end_project/img-12.jpeg') }],
		},
		depa_piloto: [{ img: require('../../Assets/images/Slider/depa_piloto/compress-img-1.webp') }, { img: require('../../Assets/images/Slider/depa_piloto/compress-img-2.webp') }, { img: require('../../Assets/images/Slider/depa_piloto/compress-img-3.webp') }, { img: require('../../Assets/images/Slider/depa_piloto/compress-img-4.webp') }, { img: require('../../Assets/images/Slider/depa_piloto/compress-img-5.webp') }, { img: require('../../Assets/images/Slider/depa_piloto/compress-img-6.webp') }, { img: require('../../Assets/images/Slider/depa_piloto/compress-img-7.webp') }, { img: require('../../Assets/images/Slider/depa_piloto/compress-img-8.webp') }, { img: require('../../Assets/images/Slider/depa_piloto/compress-img-9.webp') }, { img: require('../../Assets/images/Slider/depa_piloto/compress-img-10.webp') }],
	},
	info: {
		title: 'VERSÁTIL',
		subtitle: '& PERFECTO',
		pisos: '22',
		depas: '137',
		dorms: `2 dorms, 3 dorms`,
		ubicacion: 'Av. Brasil N° 2790, Pueblo Libre',
		precio: 'S/423,400',
		desde: '58m²',
		hasta: '72m²',
		entrega: 'Entrega Inmediata',
		entregamovil: 'Entrega Inmediata',
		frase: 'UBICACIÓN ESTRATÉGICA EN <span class="bold">PUEBLO LIBRE</span>',
		fachada: require('../../Assets/images/fachada/Slide/NOU.jpg'),
		fachadaMinified: require('../../Assets/images/fachada/Slide/resize/resize-NOU.jpg'),
		// ingreso:require("../../Assets/images/fachada/nou/ingreso.jpg"),
		parrafo: '<p class="paragraph paragraph-1">NOU es la opción ideal para disfrutar tu propio espacio rodeado de todas las comodidades que esperabas.</p><p class="paragraph paragraph-2">Descubre los beneficios de este moderno concepto que entrega versátiles áreas sociales diseñadas para tu estilo de vida y la de tu familia, junto a departamentos con gran iluminación natural.</p>',
		parrafoArquitectura1: 'Una fachada icónica con elegancia en cada detalle.<div class="span"></div>Ubicado en una esquina para potenciar la iluminación natural de cada ambiente.',
		brochure: '/brochure/Brochure-nou.pdf',
		arquitectura: '<span class="bold">ARQUITECTURA </span>& DISEÑO',
		mapa: require('../../Assets/mapas/nou.jpg'),
		sitios: [
			{ title: 'Supermercados', sitio: ['Plaza Vea', 'Candy'] },
			{ title: 'Centros comerciales', sitio: ['C.C. Risso', 'Real Plaza Salaverry'] },
			{ title: 'Universidades e institutos', sitio: ['Universidad de Pacifico', 'UPC', 'ISIL'] },
			{ title: 'Colegios', sitio: ['Pamer', 'Colegio de Jesús'] },
			{ title: 'Entretenimiento', sitio: ['C.C. Peruano Japones'] },
			{ title: 'Centros médicos', sitio: ['Hospital Militar', 'Hospital de la Policía'] },
		],
	},
	form: [
		{ value: 2, title: '2 Dorms' },
		{ value: 3, title: '3 Dorms' },
	],
	slideDepa: [
		{ desktop: require('../../Assets/images/Slider/brasil/sliderMain/newNou-1.jpg'), movil: require('../../Assets/images/Slider/brasil/sliderMain/newNou-1.jpg') },
		{ desktop: require('../../Assets/images/Slider/brasil/sliderMain/newNou-2.jpg'), movil: require('../../Assets/images/Slider/brasil/sliderMain/newNou-2.jpg') },
		{ desktop: require('../../Assets/images/Slider/brasil/sliderMain/newNou-3.jpg'), movil: require('../../Assets/images/Slider/brasil/sliderMain/newNou-3.jpg') },
		{ desktop: require('../../Assets/images/Slider/brasil/sliderMain/newNou-4.jpg'), movil: require('../../Assets/images/Slider/brasil/sliderMain/newNou-4.jpg') },
		{ desktop: require('../../Assets/images/Slider/brasil/sliderMain/newNou-5.jpg'), movil: require('../../Assets/images/Slider/brasil/sliderMain/newNou-5.jpg') },
		{ desktop: require('../../Assets/images/Slider/brasil/sliderMain/newNou-6.jpg'), movil: require('../../Assets/images/Slider/brasil/sliderMain/newNou-6.jpg') },
		{ desktop: require('../../Assets/images/Slider/brasil/sliderMain/newNou-7.jpg'), movil: require('../../Assets/images/Slider/brasil/sliderMain/newNou-7.jpg') },
		{ desktop: require('../../Assets/images/Slider/brasil/sliderMain/newNou-8.jpg'), movil: require('../../Assets/images/Slider/brasil/sliderMain/newNou-8.jpg') },
	],
	atributos: [
		{ icon: 'gas', text: 'Conexiones de</br><strong>gas natural</strong>' },
		{ icon: 'camara', text: '<strong>Cámaras de seguridad</strong></br> en áreas comunes' },
		{ icon: 'lobby', text: 'Lobby de </br><strong>doble altura</strong>' },
		{ icon: 'parrilla', text: 'Zona de <strong>parrillas,</strong></br><strong>Pizza & Bar</strong>' },
		{ icon: 'jardin', text: '<strong>Jardín Zen</strong>' },
		{ icon: 'sala-coworking', text: '<strong>Sala Coworking</strong>' },
		{ icon: 'sala-estar', text: '<strong>Sala de Estar</strong>' },
		{ icon: 'area-child', text: '<strong>Sala de niños</strong>' },
		{ icon: 'game', text: '<strong>Sala de juegos</strong>' },
		{ icon: 'yoga', text: '<strong>Yoga y bienestar</strong>' },
		{ icon: 'fogata', text: '<strong>Relax zone</strong>' },
		{ icon: 'none', text: '' },
	],
	tipologias: {
		page: 'nou',
		template_id: 87,
		buttons: [
			{ text: '02', value: 2 },
			{ text: '03', value: 3 },
			{ text: 'Todos', value: 'all' },
		],
		brand: require('../../Assets/images/iconos/brand-nou.jpg'),
		cards: [
			{ id: 21, type: 'TIPO x01', dorms: '2', vista: 'interior', plus: true, img: require('../../Assets/images/tipologias/nou/Flat-x01.jpg'), metersTotal: '52.47m²', units: [{ price: '' }], units_available: 0 },
			{ id: 22, type: 'TIPO x02', dorms: '2', vista: 'exterior', img: require('../../Assets/images/tipologias/nou/Tipo-02.jpg'), metersTotal: '64.92m²', units: [{ price: '' }], units_available: 0 },
			{ id: 24, type: 'TIPO x03', dorms: '2', vista: 'exterior', img: require('../../Assets/images/tipologias/nou/Tipo-03.jpg'), metersTotal: '58.83m²', units: [{ price: '' }], units_available: 0 },
			{ id: 27, type: 'TIPO x04-A', dorms: '2', vista: 'exterior', plus: true, img: require('../../Assets/images/tipologias/nou/Tipo-04-A.jpg'), metersTotal: '66.42 m²', units: [{ price: '' }], units_available: 0 },
			{ id: 28, type: 'TIPO x04-B', dorms: '2', vista: 'exterior', img: require('../../Assets/images/tipologias/nou/Tipo-04-A.jpg'), metersTotal: '49.54 m²', units: [{ price: '' }], units_available: 0 },
			{ id: 30, type: 'TIPO x05-A', dorms: '2', vista: 'exterior', plus: true, img: require('../../Assets/images/tipologias/nou/Tipo-05-A.jpg'), metersTotal: '76.28 m²', units: [{ price: '' }], units_available: 0 },
			{ id: 31, type: 'TIPO x05-B', dorms: '2', vista: 'exterior', plus: true, img: require('../../Assets/images/tipologias/nou/nou-x05B-op1.jpg'), metersTotal: '64.00 m²', units_range: [1305, 2205], units: [{ price: '' }], units_available: 0 },
			{ id: 31, type: 'TIPO x05-B', dorms: '2', vista: 'exterior', plus: true, img: require('../../Assets/images/tipologias/nou/nou-x05B-op2.jpg'), metersTotal: '64.00 m²', units_range: [905, 1205], units: [{ price: '' }], units_available: 0 },
			{ id: 32, type: 'TIPO x06-A', dorms: '3', vista: 'interior', img: require('../../Assets/images/tipologias/nou/flat-x06a.jpg'), metersTotal: '69.06 m²', units: [{ price: '' }], units_available: 0 },
			{ id: 34, type: 'TIPO x06-C', dorms: '2', vista: 'exterior', img: require('../../Assets/images/tipologias/nou/Tipo-06-C.jpg'), metersTotal: '68.70 m²', units: [{ price: '' }], units_available: 0 },
			{ id: 35, type: 'TIPO x07', dorms: '3', vista: 'interior', img: require('../../Assets/images/tipologias/nou/plano-tipo-x07.png'), metersTotal: '72.54 m²', units: [{ price: '' }], units_available: 0 },
			{ id: 148, type: 'TIPO x07B', dorms: '2', vista: 'interior', img: require('../../Assets/images/tipologias/nou/nou-x07B.jpg'), metersTotal: '72.54 m²', units: [{ price: '' }], units_available: 0 },
		],
	},
};

export default nou;
