export const ubicacion = {
    nou:{
        mapa:require("./Assets/mapas/nou.jpg"),
        sitios:[
            {title:"Supermercados",sitio:["Plaza Vea","Candy"]},
            {title:"Centros comerciales",sitio:["C.C. Risso","Real Plaza Salaverry"]},
            {title:"Universidades e institutos",sitio:["Universidad de Pacifico","UPC","ISIL"],},
            {title:"Colegios",sitio:["Pamer","Colegio de Jesús"]},
            {title:"Entretenimiento",sitio:["C.C. Peruano Japones"]},
            {title:"Centros médicos",sitio:["Hospital Militar","Hospital de la Policía"]},
        ],
    },
    salaverry:{
        mapa:require("./Assets/mapas/Salaverry.jpg"),
        sitios:[
            {title:"Supermercados",sitio:["Metro","Vivanda","Wong"]},
            {title:"Centros comerciales",sitio:["Real Plaza Salaverry","C.C. San Felipe"]},
            {title:"Universidades e institutos",sitio:["Universidad de Pacifico","UPC","ISIL","Alianza Francesa"],},
            {title:"Colegios",sitio:["Colegio Los Alamos","Colegio Sagrado Corazón Sophianum"]},
            {title:"Entretenimiento",sitio:["Teatro Ricardo Blume","Teatro Peruano Japones","Blue Moon"]},
            {title:"Centros médicos",sitio:["Clínica San Felipe","Policlínico Peruano Japonés"]},
        ],
    },
    town:{
        mapa:require("./Assets/mapas/Town.jpg"),
        sitios:[
            {title:"Supermercados",sitio:["Metro","Plaza Vea","Maxiahorro","Mass"]},
            {title:"Centros comerciales",sitio:["C.C. Risso","Real Plaza Centro Cívico"]},
            {title:"Universidades e institutos",sitio:["UTP","Telesup","Norbeth Wiener"],},
            {title:"Colegios",sitio:["Colegio 10 De Octubre","Colegio Elvira García y García"]},
            {title:"Entretenimiento",sitio:["Teatro Pirandello","Parque de la Reserva","Campo de Marte","Estadio Nacional"]},
            {title:"Centros médicos",sitio:["Hospital Rebagliati"]},
            ]
        },
    trend:{
        mapa:require("./Assets/mapas/Trend.jpg"),    
        sitios:[
            {title:"Supermercados",sitio:["Wong","Metro","Plaza Vea"]},
            {title:"Centros comerciales",sitio:["Plaza San Miguel","Open Plaza","C.C. Nuevo Milenio"]},
            {title:"Universidades e institutos",sitio:["Universidad Católico","Británico"],},
            {title:"Colegios",sitio:["Colegio 10 De Octubre","Colegio Elvira García y García"]},
            {title:"Entretenimiento",sitio:["Parque de Las Leyendas","Museo Larco","Huaca La Luz"]},
            {title:"Centros médicos",sitio:["Clínica Stella Maris","Hospital Santa Rosa"]},
        ],
    },
    unbox:{
        mapa:require("./Assets/mapas/unbox.jpg"),    
        sitios:[
            {title:"Supermercados",sitio:["Plaza Vea Cortijo","Metro Barranco"]},
            {title:"Colegios y Universidades",sitio:["Colegio Los Reyes Rojos","Colegio Santa Rosa","Colegio Pedro de Osma","Cenfotur","UAP Arquitectura","UTEC"],},
            {title:"Cafés & Rest",sitio:["La 73","La bodega Verde","Isolina","Canta Rana"]},
            {title:"Iglesias",sitio:["Iglesia Sagrado Corazón de Jesús","Iglesia del Nazareno de Barranco"]},
            {title:"Entretenimiento",sitio:["MAC","M.A.T.E","Dedalo"]},
            {title:"Interconexión con",sitio:["Costa Verde","Paseo de la república","Miraflores"]},
        ],
    },
    unity:{
        mapa:require("./Assets/mapas/unity-map.jpg"),    
        sitios:[
            {title:"Clínicas - Hospitales",sitio:["Policlínico Jesús María","INCOR","Hospital Edgardo Rebagliati","ESSALUD","Clínica Santa Beatriz","Policlínico Pablo Bermúdez","Clínica de Fertilidad"]},
            {title:"Bancos",sitio:["Cajero BCP","Scotiabank"],},
            {title:"Entidades",sitio:["Serpost","SUTRAN","MINSA","MININTER"]},
            {title:"Colegios, institutos",sitio:["Instituto SISE","Instituto Italiano de Cultura","Trilce"]},
            {title:"Teatros, museos, parques",sitio:["Museo de Historia Natural UNMSM","Teatro Pirandello","Circuito Mágico del Agua","Parque Habich","Jardín Botánico de Plantas Medicinales","Estadio Nacional","Campo de Marte"]},
            {title:"Metropolitano",sitio:["Estación México"]},
        ],
    },
    midway:{
        mapa:require("./Assets/mapas/mapa-midway.jpg"),    
        sitios:[
            {title:"Centros de Salud",sitio:["Hospital Militar","Centro Médico Jesús María","Policlínico Peruano Japonés","Medicis","Centro de Salud Magdalena","MedSolutions"]},
            {title:"Colegios y Centros de estudio",sitio:["Colegio San Felipe","Isil","Euroidiomas","Bibliotea Municipal","Universidad Jaime Bausate y Meza","Colegio de Jesú","Colegio Newton","Colegio Retama","Pamer","CEPRE UNI","Toulouse Lautrec"]},
            {title:"Supermercados y centros comenrciales",sitio:["Metro","Centro Comercial San Felipe","Real Plaza Salaverry","Vivanda"]},
        ],
    }
}