import React, { Component } from 'react';
import { connect } from 'react-redux';
import './shop.scss';
import 'lightgallery/dist/css/lightgallery.css';
import 'lightgallery/dist/js/lightgallery';
import 'lightgallery/dist/js/lightgallery-all';
import { addCard, deleteCard, openCart, ShowCard, updateUnits, openMessageSelect } from '../../data/carts';
import { modified } from '../../data/projects/compactProjects';
import $, { type } from 'jquery';
import Slider from 'react-slick';
import MediaQuery from 'react-responsive';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { SperantService } from '../../components/common/Http/SperantService';
import { ProjectHandler } from './ProjectHandler';
import { ProductSkeletons } from '../../components/skeletons/ProductSkeletons';

class Shop extends Component {
	constructor(props) {
		super(props);
		this.sperantService = new SperantService();
		this.projectHandler = new ProjectHandler();
		this.onsubmitFilter = this.onsubmitFilter.bind(this);
		this.openFilter = this.openFilter.bind(this);
		this.state = {
			image: false,
			filterOpen: false,
			heightFilter: '3.4rem',
			projects: [],
			percent: 10,
			step: 1,
			result: {},
			currentSlide: 0,
			rangeData: [
				{ value: [200000, 299999], title: 'Desde S/200,000 hasta S/299,999' },
				{ value: [300000, 399999], title: 'Desde S/300,000 hasta S/399,999' },
				{ value: [400000, 499999], title: 'Desde S/400,000 hasta S/499,999' },
				{ value: [500000, 599999], title: 'Desde S/500,000 hasta S/599,999' },
				{ value: [600000, 699999], title: 'Desde S/600,000 hasta S/699,999' },
				{ value: [700000, 799999], title: 'Desde S/700,000 hasta S/799,999' },
				{ value: [800000, 899999], title: 'Desde S/800,000 hasta S/899,999' },
				{ value: [900000, 999999], title: 'Desde S/900,000 hasta S/999,999' },
				{ value: [1000000, 1099999], title: 'Desde S/1,000,000 hasta S/1,099,999' },
			],
			typesSlider: {
				dots: false,
				arrows: false,
				infinite: false,
				centerMode: false,
				slidesToShow: 1,
				slidesToScroll: 1,
				variableWidth: true,
				easing: 'linear',
				centerPadding: '10px',
				afterChange: event => {
					let position = event !== 0 ? 1 + event : 0;
					position = this.props.dataProjects.projects.length - 0.2 === position ? this.props.dataProjects.projects.length : position;
					this.setState({ currentSlide: position || 1, percent: (position / this.props.dataProjects.projects.length) * 100 });
				},
			},
		};
	}
	checked = card => {
		let check = this.props.cart.tipologiesSelected
			.map(e => {
				return e.id;
			})
			.includes(card.id);

		return check;
	};
	componentDidUpdate() {
		$('.content-img').lightGallery({
			selector: '.lupa',
			thumbnail: true,
			animateThumb: false,
			showThumbByDefault: false,
			subHtmlSelectorRelative: true,
			rotate: false,
			actualSize: false,
			download: true,
			pinterest: false,
			googlePlus: false,
			twitter: false,
		});
	}
	async componentDidMount() {
		let savedProjects = JSON.parse(sessionStorage.getItem('projects_shop'));
		if (!savedProjects) {
			const projects = await this.projectHandler.processProjects(this.props.dataProjects.projects); 
			console.log(projects)
			this.props.modified(projects);
			this.setState({ projects: projects });
		} else {
			this.props.modified(savedProjects);
			this.setState({ projects: savedProjects });
		}

		$('.content-img').lightGallery({
			selector: '.lupa',
			thumbnail: true,
			animateThumb: false,
			showThumbByDefault: false,
			subHtmlSelectorRelative: true,
			rotate: false,
			actualSize: false,
			download: true,
			pinterest: false,
			googlePlus: false,
			twitter: false,
		});
	}
	submitFilter(e) {
		e.preventDefault();
	}

	onsubmitFilter(event) {
		event.preventDefault();
		let district = event.target.district.value;
		let projects = event.target.projects.value;
		let dorms = event.target.dorms.value;
		let vistas = event.target.vistas.value;
		let range_price = event.target.range_price.value.split(',');
		let filter = [
			{ type: 'dorms', value: dorms },
			{ type: 'vista', value: vistas },
			{ type: 'range', value: range_price },
		].filter(e => e.value != 'all');

		let base = this.state.projects;

		let data = JSON.parse(
			JSON.stringify(
				base.filter(e => {
					if (district == 'all' && projects == 'all') {
						return e;
					} else if (district == 'all' && projects == e.page) {
						return e;
					} else if (projects == 'all' && district == e.district) {
						return e;
					} else if (projects == e.page && district == e.district) {
						return e;
					}
				})
			)
		);

		let newData = data;
		const combineFilters =
			(...filters) =>
			item => {
				return filters.map(filter => filter(item)).every(x => x === true);
			};
		const filterDorm = item => {
			if (item.dorms == dorms || dorms == 'all') {
				return true;
			} else {
				return false;
			}
		};
		const filterVista = item => {
			if (item.vista == vistas || vistas == 'all') {
				return true;
			} else {
				return false;
			}
		};

		const filterRange = item => {
			if ((item.min_price >= range_price[0] && item.min_price <= range_price[1]) || range_price == 'all') {
				return true;
			} else {
				return false;
			}
		};

		if (filter.length > 0) {
			newData = data.filter(project_data => {
				let newCards;
				newCards = project_data.tipologias.cards.filter(combineFilters(filterDorm, filterVista, filterRange));
				if (newCards.length > 0) {
					project_data.tipologias.cards = newCards;
					return project_data;
				}
			});
		}

		this.props.modified(newData);
		this.openFilter();
	}

	formatCurrency = value =>
		value &&
		!isNaN(value.toString().replace(/,/g, '')) &&
		parseFloat(value.toString().replace(/,/g, ''))
			.toFixed(2)
			.toString()
			.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

	openFilter() {
		let heightFilter = document.querySelector('.form-filter').getBoundingClientRect().height;

		this.setState({
			filterOpen: !this.state.filterOpen,
			heightFilter: this.state.filterOpen == false ? heightFilter + 54 + 'px' : '54px',
		});
	}
	toggleShowCard = value => {
		let toggle = JSON.parse(JSON.stringify(this.props.cart.toggleShowCard));
		if (toggle.includes(value)) {
			const index = toggle.indexOf(value);
			toggle.splice(index, 1);
		} else {
			toggle.push(value);
		}

		this.props.ShowCard(toggle);
	};
	capitalize = str => {
		return str.charAt(0).toUpperCase() + str.slice(1);
	};
	render() {
		return (
			<main className='store'>
				<section>
					<div className='SliderDepartamentos'>
						<div className='background d-flex'>
							<picture>
								<source className='background ' media='(min-width: 740px)' srcset={this.props.value.shop.header}></source>
								<img className='background' src={this.props.value.shop.header}></img>
							</picture>
						</div>
					</div>
				</section>
				<section className='Filter'>
					<div className={`content-filter container-shop ${this.state.filterOpen == true ? 'active' : ''}`} style={{ height: this.state.heightFilter }}>
						<div className='title-filter'>
							<span className='bold'>Añadir Filtros </span>
							<div
								className={`mask ${this.state.filterOpen == true ? 'less' : 'more'}`}
								onClick={() => {
									this.openFilter();
								}}
							></div>
						</div>
						<form className='form-filter' onSubmit={this.onsubmitFilter}>
							<div className='content-inputs'>
								<div className='content-input'>
									<span className='title'>Proyectos</span>
									<select className='select proyectos' name='projects'>
										<option value='all' selected>
											Todos
										</option>
										{this.state.projects.map(item => {
											return <option value={item.page}>{this.capitalize(item.page)}</option>;
										})}
									</select>
								</div>
								<div className='content-input'>
									<span className='title'>Distritos</span>
									<select className='select' name='district'>
										<option value='all' selected>
											Todos
										</option>
										<option value='Barranco'>Barranco</option>
										<option value='Cercado de Lima'>Cercado de Lima</option>
										<option value='Chorrillos'>Chorrillos</option>
										<option value='Magdalena'>Magdalena del Mar</option>
										<option value='Pueblo Libre'>Pueblo Libre</option>
										<option value='Santa Beatriz'>Santa Beatriz</option>
										<option value='Jesús María'>Jesús María</option>
									</select>
								</div>
								<div className='content-input'>
									<span className='title'>Dormitorios</span>
									<select className='select dorms' name='dorms'>
										<option value='all' selected>
											Todos
										</option>
										<option value='1'>01 Dorm</option>
										<option value='2'>02 Dorms</option>
										<option value='3'>03 Dorms</option>
									</select>
								</div>
								<div className='content-input'>
									<span className='title'>Vistas</span>
									<select className='select vistas' name='vistas'>
										<option value='all' selected>
											Todos
										</option>
										<option value='interior'>Interior</option>
										<option value='exterior'>Exterior</option>
									</select>
								</div>
								<div className='content-input range'>
									<span className='title'>Precios</span>
									<MediaQuery query='(max-width: 499px)'>
										{this.state.rangeData.map((item, index) => {
											return (
												<div className='content-radio'>
													<input className='radio-input' type='radio' id={'radio-' + index} name='range_price' value={`${item.value[0]},${item.value[1]}`} />
													<label className='select-radio' for={'radio-' + index}></label>
													<span className='title-price'>{item.title}</span>
												</div>
											);
										})}
									</MediaQuery>
									<MediaQuery query='(min-width: 499px)'>
										{
											<select className='select range' name='range_price'>
												<option value='all' selected>
													Todos
												</option>
												{this.state.rangeData.map(item => {
													return <option value={`${item.value[0]},${item.value[1]}`}>{item.title}</option>;
												})}
											</select>
										}
									</MediaQuery>
								</div>
							</div>
							<button type='submit' className='btn-submit radius b-red c-white'>
								Buscar
							</button>
						</form>
					</div>
				</section>
				<section className='All-projects'>
					<div className='container-shop'>
						{/* this.props.dataProjects.projects.length == 0 ? 
                    <div className='message-project'>
                        Lo sentimos no contamos con proyectos que contengas esas características
                    </div> : */}
						{!this.state.projects?.length ? (
							<ProductSkeletons number={2} cards={5} />
						) : (
							this.props.dataProjects.projects.map((item, index) => {
								return (
									// key={new Date().getTime()}
									<div className='content-project' style={{ zIndex: 50 - index }}>
										<div className='reference-project'>
											<div className='content-text'>
												<span className='text'>
													PROYECTO <strong>{item.page}</strong>
												</span>
												<span className='radius b-secondary semi-bold-poppins c-white uppercase'>{item.district}</span>
											</div>
											<div className='content-img'>
												<img className='' src={item.info.fachadaMinified}></img>
											</div>
										</div>
										<div className='targets'>
											<div className='resume'>
												<div
													className='radius b-white c-black shadow'
													onClick={() => {
														this.toggleShowCard(item.page);
													}}
												>
													{`${this.props.cart.toggleShowCard.includes(item.page) ? 'Mostrar más' : 'Ocultar'}`}
													<img className={`${this.props.cart.toggleShowCard.includes(item.page) ? 'close-card' : ''}`} src={require('../../Assets/images/iconos/signal-down.svg').default}></img>
												</div>
											</div>
											<div className='content-cards'>
												<MediaQuery query='(min-width: 500px)'>
													{item.tipologias.cards.map((card, index) => {
														return <CardStoreComponent card={card} active={this.state.toggleShowCard} project={item} checked={this.checked} index={index} />;
													})}
												</MediaQuery>
												<MediaQuery query='(max-width: 499px)'>
													<SliderStoreComponent item={item} active={this.state.toggleShowCard} project={item} />
												</MediaQuery>
											</div>
										</div>
									</div>
								);
							})
						)}
					</div>
				</section>
			</main>
		);
	}
}

class SliderCard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			percent: 1,
			currentSlide: 0,
			typesSlider: {
				dots: false,
				arrows: false,
				infinite: false,
				centerMode: false,
				slidesToShow: 1,
				slidesToScroll: 1,
				variableWidth: true,
				easing: 'linear',
				centerPadding: '10px',
				afterChange: event => {
					this.setState({ percent: event + 1 });
				},
			},
		};
	}
	render() {
		return (
			<div className='content-slider-card'>
				<Slider {...this.state.typesSlider}>
					{this.props.item.tipologias.cards.map((card, index) => {
						return <CardStoreComponent card={card} active={this.state.active} project={this.props.item} checked={this.checked} index={index} />;
					})}
				</Slider>
				<div className='progress-container'>
					<progress id='slider-progress' max={this.props.item.tipologias.cards.length} value={this.state.percent}></progress>
					<label for='slider-progress'>{`${this.state.percent} / ${this.props.item.tipologias.cards.length}`}</label>
				</div>
			</div>
		);
	}
}
class Card extends Component {
	constructor(props) {
		super(props);
		this.state = {
			image: false,
			active: false,
			height: '0px',
			innerHtml: '',
			unit: 0,
			card: this.props.card,
		};
		this.list = React.createRef();
		this.item = React.createRef();
	}
	componentDidMount() {
		if (document.querySelector('.content-attr')) {
			$('.content-img').click(function () {
				try {
					document.querySelector('.lg-outer').classList.add('tipologies');
					document.querySelector('.lg').classList.add('tipologies');
				} catch (error) {}
			});
		}
		// console.log('format',this.formatCurrency(2.8))
	}
	checked = card => {
		let check = this.props.cart.tipologiesSelected
			.map(e => {
				return e.id;
			})
			.includes(card.id);
		return check;
	};
	formatCurrency = value =>
		value &&
		!isNaN(value.toString().replace(/,/g, '')) &&
		parseFloat(value.toString().replace(/,/g, ''))
			.toFixed(0)
			.toString()
			.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	handleImage() {
		this.setState({ image: true });
	}
	selectUnit = (e, item, index) => {
		let html = document.querySelector(`#${e}`).innerHTML;
		let card = JSON.parse(JSON.stringify(this.props.card))
		card['unit'] = item;
		this.props.updateUnits({ item: item, card: this.props.card });
		this.setState({ innerHtml: html, card: card });
		this.toggle();
		window.dataLayer?.push({ ecommerce: null });  // Clear the previous ecommerce object.
		window.dataLayer?.push({
			event: "select_item",
			ecommerce: {
				item_list_id: this.props.project.page.toLowerCase(),
				item_list_name: this.props.project.page.toLowerCase(),
				items: [
					{
						item_id: this.props.card.id,
						item_name: item.type,
						item_brand: "Casaideal",
						item_category: this.props.project.page.toLowerCase(),
						item_category2: this.props.card.vista,
						item_category3: this.props.card.dorms,
						item_category4: item.total_area,
						item_variant: item.name,
						price: item.price,
						quantity: 1
					}
				]
			}
		})
	};
	toggle = () => {
		let height;
		if (this.state.active == false) {
			height = this.list.current.clientHeight;
		} else {
			height = 0;
		}
		this.setState({ active: !this.state.active, height: `${height}px` });
	};

	addToCart(){
		this.openModal();
		this.initFlat();
		window.dataLayer?.push({ ecommerce: null })
		window.dataLayer?.push({
			event: "add_to_cart",
			ecommerce: {
				currency: this.state.card.unit.currency,
				value: this.props.cart.priceSeparation,
				items: [
					{
						item_id: this.state.card.id,
						item_name: this.state.card.unit.type,
						item_brand: "Casaideal",
						item_category: this.props.project.page.toLowerCase(),
						item_category2: this.state.card.vista,
						item_category3: this.state.card.dorms,
						item_category4: this.state.card.unit.total_area,
						item_variant: this.state.card.unit.name,
						price: this.state.card.unit.price,
						quantity: 1
					}
				]
			}
		})
	}

	openModal = () => {
		if (this.state.innerHtml.length < 1) {
			this.props.openMessageSelect();
		} else {
			this.props.addCard({ card: this.state.card, project: this.props.project, unit: this.state.unit });
			this.props.openCart();
		}
	};

	initFlat = () => {
		// console.log('card',this.props.card);
	};
	render() {
		return (
			<div className={`card ${this.props.cart.toggleShowCard.includes(this.props.project.page) ? 'hide' : ''}`} id={'card-' + this.props.card.id} style={{ zIndex: 50 - this.props.index }}>
				<div className='content-attr'>
					<div className='header'>
						<div className='type'>{this.props.card.type}</div>
						<div className='dorms semi-bold-poppins b-secondary c-white radius'>{`${this.props.card.dorms} ${this.props.card.dorms == 1 ? 'Dorm' : 'Dorms'}`}</div>
					</div>
					<div className='content-img'>
						<img className='lupa' src={require('../../Assets/iconos/lupa-tipo.svg').default} href={this.props.card.img}></img>
						{this.state.image == false ? <Skeleton /> : null}
						<img
							className='img'
							src={this.props.card.img}
							onLoad={() => {
								this.handleImage();
							}}
						></img>
					</div>
					<div className='info'>
						<div
							className={`select-header ${this.state.active == true ? 'active' : ''}`}
							onClick={() => {
								this.toggle();
							}}
						>
							<span className='title-header'>{this.state.innerHtml.length > 1 ? null : 'Selecciona un piso'}</span>
							<div className='inner' dangerouslySetInnerHTML={{ __html: this.state.innerHtml }}></div>
							<div className='icon'>
								<img className='' src={require('../../Assets/images/iconos/down.svg').default}></img>
							</div>
						</div>
						<div className={`price ${this.state.active == true ? 'active' : ''}`} style={{ height: this.state.height }}>
							<div className='content-price' ref={this.list}>
								{this.props.card.units?.length > 0
									? this.props.card.units.map((item, index) => {
											// console.log(item)
											return (
												<div
													className={`prices`}
													id={'item-' + item.name + index}
													ref={this.item}
													key={index+item.name}
													onClick={() => {
														this.selectUnit('item-' + item.name + index, item, index);
													}}
												>
													<span className='name'>{item.name}</span> - 
													<span className='meters'>{item.construction?.total_area}m²</span> - 
													<div className='price-column'>
														<span className='nexa-bold '>S/ {this.formatCurrency(parseInt(item.price) - parseInt(item.price) * 0.03)}</span>
														<span className='c-gray nexa-bold sub-title'>
															antes S/
															<span className='line-thought'>{this.formatCurrency(item.price)}</span>
														</span>
													</div>
												</div>
											);
									  })
									: null}
							</div>
						</div>
					</div>
				</div>
				<div
					className={`separe-depa b-red c-white nexa-bold `}
					onClick={() => {
						this.addToCart();
					}}
				>
					<span className='' dangerouslySetInnerHTML={{ __html: `${this.checked(this.props.card) == true ? 'Separado' : `Separar tu depa <span class="bold">S/ ${this.formatCurrency(this.props.cart.priceSeparation)}</span>`}` }}></span>
				</div>
			</div>
		);
	}
}
const MapStateProps = state => {
	return {
		value: state.shopStore,
		dataProjects: state.compactProjects,
		cart: state.cartStore,
	};
};

const CardStoreComponent = connect(MapStateProps, { addCard, openCart, updateUnits, openMessageSelect })(Card);
const SliderStoreComponent = connect(MapStateProps, { addCard, openCart })(SliderCard);

export default connect(MapStateProps, { addCard, deleteCard, modified, openCart, ShowCard })(Shop);
