const trend = {
	sperant_id: 18,
	route: '/trend',
	page: 'trend',
	district: 'Cercado de Lima',
	titlehead: "",
	metakeywords:"",
	metadescription:"",
	whatsapp: ['936830642'],
	iziPubKey: {
		prod: '74082626:publickey_DhXfdvFBRyA3UvZcMfkKydNZ0KAtYQ18AYygRnrJ5swGP',
		dev: '74082626:testpublickey_14bMtq4HpfWZTh8qgpyMLWyDE8DHSarQ1VtQ9UAd7AhAi',
	},
	areas: {
		webp: 4,
		comunes: [
			{ title: 'LOBBY', icon: 'lobby', desktop: require('../../Assets/images/Slider/trend/comunes/desktop/lobby.jpg'), movil: require('../../Assets/images/Slider/trend/comunes/desktop/lobby.jpg'), paragraph: 'El diseño transmite vida y estilo desde el ingreso.' },
			{ title: 'ÁREA DE PADRES', icon: 'sala-estar', desktop: require('../../Assets/images/Slider/trend/comunes/desktop/area-de-padres.jpg'), movil: require('../../Assets/images/Slider/trend/comunes/desktop/area-de-padres.jpg'), paragraph: 'Ideal para conversar o disfrutar de un buen libro.' },
			{ title: 'ÁREA CHILL', icon: 'area-chill', desktop: require('../../Assets/images/Slider/trend/comunes/desktop/area-chill.jpg'), movil: require('../../Assets/images/Slider/trend/comunes/desktop/area-chill.jpg'), paragraph: 'Después de un día lleno de actividades, que mejor que relajarse con una película.' },
			{ title: 'RELAX ZONE', icon: 'relax', desktop: require('../../Assets/images/Slider/trend/comunes/desktop/relax-zone.jpg'), movil: require('../../Assets/images/Slider/trend/comunes/desktop/relax-zone.jpg'), paragraph: 'Una terraza con todo lo necesario para que te relajes en las alturas.' },
			{ title: 'SKY BAR', icon: 'sky-bar', desktop: require('../../Assets/images/Slider/trend/comunes/desktop/skybar.jpg'), movil: require('../../Assets/images/Slider/trend/comunes/desktop/skybar.jpg'), paragraph: 'El lugar ideal para disfrutar de unos tragos en compañía de los amigos.' },
			{ title: 'TERRAZA GRILL', icon: 'parrilla', desktop: require('../../Assets/images/Slider/trend/comunes/desktop/terraza-grill.jpg'), movil: require('../../Assets/images/Slider/trend/comunes/desktop/terraza-grill.jpg'), paragraph: 'Disfruta cocinando en compañía de tu familia y/o amigos con una increíble vista de la ciudad.' },
			{ title: 'KIDS ROOM', icon: 'zona-child', desktop: require('../../Assets/images/Slider/trend/comunes/desktop/Kids-Room.jpg'), movil: require('../../Assets/images/Slider/trend/comunes/desktop/Kids-Room.jpg'), paragraph: 'El espacio perfecto para compartir con los pequeños de la familia.' },
			{ title: 'ZONA DE YOGA Y BIENESTAR', icon: 'yoga', desktop: require('../../Assets/images/Slider/trend/comunes/desktop/Yoga-y-bienestar.jpg'), movil: require('../../Assets/images/Slider/trend/comunes/desktop/Yoga-y-bienestar.jpg'), paragraph: 'Ejercítate sin salir de casa y lleva un estilo de vida saludable.' },
		],
		interiores: [
			{ title: 'COCINA', icon: 'refri', desktop: require('../../Assets/images/Slider/trend/interiores/desktop/cocina.jpg'), movil: require('../../Assets/images/Slider/trend/interiores/desktop/cocina.jpg'), paragraph: 'Cocina de concepto abierto con detalles en granito, muebles altos y bajos con luminarias elegantes para hacer de este un espacio acogedor.' },
			{ title: 'DORMITORIO PRINCIPAL', icon: 'bed', desktop: require('../../Assets/images/Slider/trend/interiores/desktop/dormitorio-principal.jpg'), movil: require('../../Assets/images/Slider/trend/interiores/desktop/dormitorio-principal.jpg'), paragraph: 'Habitación ideal para tu día a día. Buena luz natural con amplio espacio.' },
			{ title: 'DORMITORIO DE NIÑA', icon: 'famele', desktop: require('../../Assets/images/Slider/trend/interiores/desktop/dormitorio-nina.jpg'), movil: require('../../Assets/images/Slider/trend/interiores/desktop/dormitorio-nina.jpg'), paragraph: 'Habitación ideal para los pequeños de la casa.' },
			{ title: 'DORMITORIO DE NIÑO', icon: 'male', desktop: require('../../Assets/images/Slider/trend/interiores/desktop/dormitorio-niño.jpg'), movil: require('../../Assets/images/Slider/trend/interiores/desktop/dormitorio-niño.jpg'), paragraph: 'Habitación ideal para los pequeños de la casa.' },
			{ title: 'SALA', icon: 'relax', desktop: require('../../Assets/images/Slider/trend/interiores/desktop/sala-comedor.jpg'), movil: require('../../Assets/images/Slider/trend/interiores/desktop/sala-comedor.jpg'), paragraph: 'Espacios funcionales conectados por estilo, diseño y una perfecta distribución de los ambientes.' },
		],
		exteriores: {
			cantButton: 'avance-none',
			title: 'Avance de obra Agosto',
			desktop: [{ img: require('../../Assets/images/Slider/exteriores/ingreso-trend.jpg') }, { img: require('../../Assets/images/Slider/exteriores/dia/trend.jpg') }, { img: require('../../Assets/images/Slider/exteriores/noche/trend.jpg') }],
		},
		recorrido: 'https://kuula.co/share/collection/7lZ3H?fs=1&vr=1&sd=1&initload=0&thumbs=3&inst=es&info=0&logo=-1',
		avance: {
			cantButton: 'avance-none',
			title: 'Avance de obra Septiembre',
			desktop: [],
		},
		end_project: {
			cantButton: 5,
			title: '',
			images: [{ img: require('../../Assets/images/Slider/trend/end/img-1.JPG') }, { img: require('../../Assets/images/Slider/trend/end/img-2.JPG') }, { img: require('../../Assets/images/Slider/trend/end/img-3.JPG') }, { img: require('../../Assets/images/Slider/trend/end/img-4.JPG') }, { img: require('../../Assets/images/Slider/trend/end/img-5.JPG') }, { img: require('../../Assets/images/Slider/trend/end/img-6.JPG') }, { img: require('../../Assets/images/Slider/trend/end/Fachada-Trend-Casaideal.webp') }, { img: require('../../Assets/images/Slider/trend/end/lobby.webp') }, { img: require('../../Assets/images/Slider/trend/end/area-de-padres.webp') }, { img: require('../../Assets/images/Slider/trend/end/area-chill.webp') }, { img: require('../../Assets/images/Slider/trend/end/relax-zone.webp') }, { img: require('../../Assets/images/Slider/trend/end/skybar.webp') }, { img: require('../../Assets/images/Slider/trend/end/terraza-grill.webp') }, { img: require('../../Assets/images/Slider/trend/end/Kids-Room.webp') }, { img: require('../../Assets/images/Slider/trend/end/Yoga-y-bienestar.webp') }],
		},
		depa_piloto: [
			{img: require('../../Assets/images/Slider/trend/depa_piloto/Depa-1.jpg')},
			{img: require('../../Assets/images/Slider/trend/depa_piloto/Depa-2.jpg')},
			{img: require('../../Assets/images/Slider/trend/depa_piloto/Depa-3.jpg')},
			{img: require('../../Assets/images/Slider/trend/depa_piloto/Depa-4.jpg')},
			{img: require('../../Assets/images/Slider/trend/depa_piloto/Depa-5.jpg')},
			{img: require('../../Assets/images/Slider/trend/depa_piloto/Depa-6.jpg')},
			{img: require('../../Assets/images/Slider/trend/depa_piloto/Depa-7.jpg')},
			{img: require('../../Assets/images/Slider/trend/depa_piloto/Depa-8.jpg')},
			{img: require('../../Assets/images/Slider/trend/depa_piloto/Depa-9.jpg')},
			{img: require('../../Assets/images/Slider/trend/depa_piloto/Depa-10.jpg')},
		],
	},
	form: [
		{ value: 2, title: '2 Dorms' },
		{ value: 3, title: '3 Dorms' },
	],
	info: {
		title: 'CREA TU PROPIA',
		subtitle: 'HISTORIA',
		entrega: 'Entrega inmediata',
		entregamovil: 'Entrega inmediata',
		frase: 'LAS ZONA MÁS CÉNTRICA  DE LIMA,</br> LÍMITE CON <span class="bold">PUEBLO LIBRE</span>',
		pisos: '22',
		depas: '84',
		dorms: '3 dorms ',
		ubicacion: 'Av. Mariano Cornejo N° 1848, Cercado de Lima',
		precio: 'S/451,300 ',
		desde: '60m²',
		hasta: '68m²',
		fachada: require('../../Assets/images/fachada/Slide/TREND.jpg'),
		fachadaMinified: require('../../Assets/images/fachada/Slide/resize/resize-TREND.jpg'),
		// ingreso:require("../../Assets/images/fachada/trend/ingreso.jpg"),
		parrafo: '<p class="paragraph paragraph">Hay una historia que compartimos y una historia personal. Una vida en común, y una vida propia: La de tu familia, la de tus hijos y la de sus estudios. La de su futuro.</p><p class="paragraph paragraph-2">Trend está a un paso de todo lo que tu familia necesita: Educación, cultura y espacios verdes.</p>',
		parrafoArquitectura1: 'Un concepto vanguardista que se refleja en cada uno de sus espacios.<div class="span"></div>Interiores con los mejores acabados en la zona.',
		brochure: '/brochure/Brochure-trend.pdf',
		arquitectura: '<span class="bold">ARQUITECTURA </span>& DISEÑO',
		mapa: require('../../Assets/mapas/Trend.jpg'),
		class: 'top',
		sitios: [
			{ title: 'Supermercados', sitio: ['Wong', 'Metro', 'Plaza Vea'] },
			{ title: 'Centros comerciales', sitio: ['Plaza San Miguel', 'Open Plaza', 'C.C. Nuevo Milenio'] },
			{ title: 'Universidades e institutos', sitio: ['Universidad Católico', 'Británico'] },
			{ title: 'Colegios', sitio: ['Colegio 10 De Octubre', 'Colegio Elvira García y García'] },
			{ title: 'Entretenimiento', sitio: ['Parque de Las Leyendas', 'Museo Larco', 'Huaca La Luz'] },
			{ title: 'Centros médicos', sitio: ['Clínica Stella Maris', 'Hospital Santa Rosa'] },
		],
	},
	slideDepa: [
		{ desktop: require('../../Assets/images/Slider/trend/sliderMain/newTrend-1.jpg'), movil: require('../../Assets/images/Slider/trend/sliderMain/newTrend-1.jpg') },
		{ desktop: require('../../Assets/images/Slider/trend/sliderMain/newTrend-2.jpg'), movil: require('../../Assets/images/Slider/trend/sliderMain/newTrend-2.jpg') },
		{ desktop: require('../../Assets/images/Slider/trend/sliderMain/newTrend-3.jpg'), movil: require('../../Assets/images/Slider/trend/sliderMain/newTrend-3.jpg') },
		{ desktop: require('../../Assets/images/Slider/trend/sliderMain/newTrend-4.jpg'), movil: require('../../Assets/images/Slider/trend/sliderMain/newTrend-4.jpg') },
		{ desktop: require('../../Assets/images/Slider/trend/sliderMain/newTrend-5.jpg'), movil: require('../../Assets/images/Slider/trend/sliderMain/newTrend-5.jpg') },
	],
	atributos: [
		{ icon: 'gas', text: 'Conexiones de</br><strong>gas natural</strong>' },
		{ icon: 'camara', text: '<strong>Cámaras de seguridad</strong></br> en áreas comunes' },
		{ icon: 'area-verde', text: 'Rodeado de</br><strong>Áreas verdes</strong>' },
		{ icon: 'lobby', text: 'Lobby de </br><strong>doble altura</strong>' },
		{ icon: 'sky-bar', text: '<strong>Sky Bar</strong>' },
		{ icon: 'sala-coworking', text: '<strong>Área chill</strong>' },
		{ icon: 'relax', text: '<strong>Relax zone</strong>' },
		{ icon: 'terraza', text: '<strong>Terraza Grill</strong>' },
		{ icon: 'area-child', text: '<strong>Kids Room</strong>' },
		{ icon: 'sala-estar', text: '<strong>Área de padres</strong>' },
		{ icon: 'yoga', text: '<strong>Zona de </br>Yoga y bienestar</strong>' },
		{ icon: 'none', text: '' },
	],
	tipologias: {
		page: 'trend',
		template_id: 89,
		buttons: [
			{ text: '02', value: 2 },
			{ text: '03', value: 3 },
			{ text: 'Todos', value: 'all' },
		],
		brand: require('../../Assets/images/iconos/brand-trend.jpg'),
		cards: [
			{ id: 47, type: 'Modelo X01', dorms: '3', vista: 'exterior', img: require('../../Assets/images/tipologias/trend/Modelo-X01_1.jpg'), metersTotal: '66.19 m²', units: [{ price: '' }], units_available: 0 },
			{ id: 48, type: 'Modelo X02', dorms: '3', vista: 'interior', img: require('../../Assets/images/tipologias/trend/Modelo-X02.jpg'), metersTotal: '71.64 m²', units: [{ price: '' }], units_available: 0 },
			{ id: 49, type: 'Modelo X02-A', dorms: '2', vista: 'interior', img: require('../../Assets/images/tipologias/trend/Modelo-X02-A_1.jpg'), metersTotal: '53.67 m²', units: [{ price: '' }], units_available: 0 },
			{ id: 51, type: 'Modelo X03-A', dorms: '2', vista: 'interior', img: require('../../Assets/images/tipologias/trend/Modelo-X03-A_1.jpg'), metersTotal: '60.24 m²', units: [{ price: '' }], units_available: 0 },
			{ id: 53, type: 'Modelo X03-C', dorms: '2', vista: 'interior', img: require('../../Assets/images/tipologias/trend/Modelo-X03-C.jpg'), metersTotal: '55.71 m²', units: [{ price: '' }], units_available: 0 },
			{ id: 54, type: 'Modelo X04', dorms: '2', vista: 'interior', img: require('../../Assets/images/tipologias/trend/trend-x04.jpg'), metersTotal: '59.95 m²', units: [{ price: '' }], units_available: 0 },
			{ id: 55, type: 'Modelo X04-A', dorms: '2', vista: 'interior', img: require('../../Assets/images/tipologias/trend/Modelo-X04-A_1.jpg'), metersTotal: '55.71 m²', units: [{ price: '' }], units_available: 0 },
			{ id: 56, type: 'Modelo X04-B', dorms: '3', vista: 'exterior', img: require('../../Assets/images/tipologias/trend/Modelo-X04-B.jpg'), metersTotal: '68.39 m²', units: [{ price: '' }], units_available: 0 },
			{ id: 57, type: 'Modelo X05', dorms: '3', vista: 'exterior', img: require('../../Assets/images/tipologias/trend/Modelo-X05.jpg'), metersTotal: '68.39 m²', units: [{ price: '' }], units_available: 0 },
		],
	},
};

export default trend;
