const unbox = {
	sperant_id: 19,
	route: '/unbox',
	page: 'unbox',
	district: 'Barranco',
	whatsapp: ['924785126'],
	titlehead:"Proyecto Unbox | Departamentos en Barranco",
    metadescription:"Vive en Barranco, en la Av. San Martín, a un paso del Malecón Armendáriz. Departamentos de 2 y 3 dorms desde S/773,100 en Entrega Inmediata. Moderno edificio con una arquitectura de tendencia y departamentos bien distribuidos. Cotiza aquí.",
    metakeywords:"departamentos en barranco, departamentos en venta en barranco, inmobiliaria, barranco, casaideal inmobiliaria, departamentos a un paso del malecón armendáriz, departamentos de 2 y 3 dormitorios, edificio en entrega inmediata, departamentos en entrega inmediata",
	iziPubKey: {
		prod: '33403700:publickey_q6keNIUeSlP3VsvF5cou6skBFAvQTPhFdLtuu4bUiMSH5',
		dev: '33403700:testpublickey_xIgRf2LCmnUxHbjfmz9IgmUWamkoUYyINw8EpKEq1UoKj',
	},
	areas: {
		cant: 4,
		comunes: [
			{
				title: 'LOBBY',
				icon: 'lobby',
				desktop: require('../../Assets/images/Slider/unbox/comunes/desktop/lobby.jpg'),
				position: 'center 54%',
				movil: require('../../Assets/images/Slider/unbox/comunes/movil/lobby.jpg'),
				paragraph: 'Lobby con doble altura con detalles en mármol y cemento expuesto dan modernidad al ingreso.',
			},
			{
				title: 'TERRAZA BBQ',
				icon: 'parrilla',
				desktop: require('../../Assets/images/Slider/unbox/comunes/desktop/terraza.jpg'),
				position: 'center 84%',
				movil: require('../../Assets/images/Slider/unbox/comunes/desktop/terraza.jpg'),
				paragraph: 'Ubicado en el último piso, con una zona lounge y una terraza donde podrás pasar momentos increíbles con la familia y amigos.',
			},
			{
				title: 'ZONA LOUNGE',
				icon: 'copa',
				desktop: require('../../Assets/images/Slider/unbox/comunes/desktop/terraza-estar.jpg'),
				movil: require('../../Assets/images/Slider/unbox/comunes/desktop/terraza-estar.jpg'),
				paragraph: 'Ubicado en el último piso, con una zona lounge y una terraza donde podrás pasar momentos increíbles con la familia y amigos.',
			},
			{
				title: 'COWORKING',
				icon: 'coworking',
				desktop: require('../../Assets/images/Slider/unbox/comunes/desktop/Coworking.jpg'),
				position: 'center 54%',
				movil: require('../../Assets/images/Slider/unbox/comunes/movil/Coworking.jpg'),
				paragraph: 'Un ambiente ideal para hacer home office o recibir clientes sin salir de casa.',
			},
			{
				title: 'PET SHOWER',
				icon: 'pet',
				desktop: require('../../Assets/images/Slider/unbox/comunes/desktop/Pet-shower.jpg'),
				position: 'center 72%',
				movil: require('../../Assets/images/Slider/unbox/comunes/movil/Pet-shower.jpg'),
				paragraph: 'Un excelente lugar para el cuidado que tu mascota necesita.',
			},
		],
		interiores: [
			{
				title: 'SALA',
				icon: 'sillon',
				desktop: require('../../Assets/images/Slider/unbox/interiores/desktop/sala.jpg'),
				movil: require('../../Assets/images/Slider/unbox/interiores/desktop/sala.jpg'),
				paragraph: 'Balcón que refresca y piso texturizado para darle calidez al departamento',
			},
			{
				title: 'SALA COMEDOR',
				icon: 'sillon',
				desktop: require('../../Assets/images/Slider/unbox/interiores/desktop/comedor.jpg'),
				movil: require('../../Assets/images/Slider/unbox/interiores/desktop/comedor.jpg'),
				paragraph: 'Cocina de concepto abierto en detalles de Cuarzo con muebles altos y bajos que garantizan la elegancia y funcionalidad.Cocina de concepto abierto en detalles de Cuarzo con muebles altos y bajos que garantizan la elegancia y funcionalidad.',
			},
			{
				title: 'COCINA',
				icon: 'refri',
				desktop: require('../../Assets/images/Slider/unbox/interiores/desktop/Casaideal- unbox-cocina.jpg'),
				movil: require('../../Assets/images/Slider/unbox/interiores/desktop/Casaideal- unbox-cocina.jpg'),
				paragraph: 'Cocina de concepto abierto con detalles en granito, muebles altos y bajos con luminarias elegantes para hacer de este un espacio acogedor.',
			},
			{
				title: 'DORMITORIO PRINCIPAL',
				icon: 'bed',
				desktop: require('../../Assets/images/Slider/unbox/interiores/desktop/DORMITORIO-PRINCIPAL.jpg'),
				movil: require('../../Assets/images/Slider/unbox/interiores/desktop/DORMITORIO-PRINCIPAL.jpg'),
				paragraph: 'Habitación ideal para tu día a día. Buena luz natural con amplio espacio.',
			},
			{
				title: 'DORMITORIO DE NIÑA',
				icon: 'famele',
				desktop: require('../../Assets/images/Slider/unbox/interiores/desktop/DORMITORIO-SECUNDARIO-NIÑA.jpg'),
				movil: require('../../Assets/images/Slider/unbox/interiores/desktop/DORMITORIO-SECUNDARIO-NIÑA.jpg'),
				paragraph: 'Dormitorio amplio con espacio para hacer tareas y con un balcón que traerá la frescura de la tarde.',
			},
			{
				title: 'DORMITORIO DE NIÑO',
				icon: 'male',
				desktop: require('../../Assets/images/Slider/unbox/interiores/desktop/DORMITORIO-SECUNDARIO-NIÑO.jpg'),
				movil: require('../../Assets/images/Slider/unbox/interiores/desktop/DORMITORIO-SECUNDARIO-NIÑO.jpg'),
				paragraph: 'Dormitorio para el más pequeño, donde podrá jugar y hacer de este su propio lugar.',
			},
			{
				title: 'BAÑO PRINCIPAL',
				icon: 'ducha',
				desktop: require('../../Assets/images/Slider/unbox/interiores/desktop/bath-principal.jpg'),
				position: 'center 46%',
				movil: require('../../Assets/images/Slider/unbox/interiores/desktop/bath-principal.jpg'),
				paragraph: 'Con acabados en granito y detalles en madera para tener momentos relajantes.',
			},
			{
				title: 'BAÑO SECUNDARIO',
				icon: 'ducha',
				desktop: require('../../Assets/images/Slider/unbox/interiores/desktop/bath-secundario.jpg'),
				movil: require('../../Assets/images/Slider/unbox/interiores/desktop/bath-secundario.jpg'),
				paragraph: 'Con mamparas de vidrio que dan una sensación de amplitud y modernidad.',
			},
		],
		exteriores: {
			cantButton: 'avance-none',
			title: 'Avance de obra Agosto',
			desktop: [
				{
					img: require('../../Assets/images/Slider/exteriores/ingreso-unbox.jpg'),
				},
				{
					img: require('../../Assets/images/Slider/exteriores/dia/unbox.jpg'),
				},
				{
					img: require('../../Assets/images/Slider/exteriores/noche/unbox.jpg'),
				},
			],
		},
		end_project: {
			cantButton: 5,
			title: '',
			images: [
				{
					img: require('../../Assets/images/Slider/unbox/depa_piloto/compress-DJI_0743.webp'),
				},
				{
					img: require('../../Assets/images/Slider/unbox/depa_piloto/compress-DJI_0761.webp'),
				},
				{
					img: require('../../Assets/images/Slider/unbox/depa_piloto/compress-DJI_0799.webp'),
				},
				{
					img: require('../../Assets/images/Slider/unbox/depa_piloto/compress-DJI_0810.webp'),
				},
				{
					img: require('../../Assets/images/Slider/unbox/depa_piloto/compress-DSC07748.webp'),
				},
				{
					img: require('../../Assets/images/Slider/unbox/depa_piloto/compress-DSC07853.webp'),
				},
				{
					img: require('../../Assets/images/Slider/unbox/depa_piloto/compress-DSC07896.webp'),
				},
				{
					img: require('../../Assets/images/Slider/unbox/depa_piloto/compress-DSC07961.webp'),
				},
				{
					img: require('../../Assets/images/Slider/unbox/depa_piloto/compress-DSC07973.webp'),
				},
				{
					img: require('../../Assets/images/Slider/unbox/depa_piloto/compress-DSC07983.webp'),
				},
				{
					img: require('../../Assets/images/Slider/unbox/depa_piloto/compress-DSC08068.webp'),
				},
				{
					img: require('../../Assets/images/Slider/unbox/depa_piloto/compress-DSC08068.webp'),
				},
				{
					img: require('../../Assets/images/Slider/unbox/depa_piloto/compress-DSC08073.webp'),
				},
			],
		},
		depa_piloto: [
			{
				img: require('../../Assets/images/Slider/unbox/end_project/sala.webp'),
			},
			{
				img: require('../../Assets/images/Slider/unbox/end_project/sala-2.webp'),
			},
			{
				img: require('../../Assets/images/Slider/unbox/end_project/sala-comedor.webp'),
			},
			{
				img: require('../../Assets/images/Slider/unbox/end_project/cocina.webp'),
			},
			{
				img: require('../../Assets/images/Slider/unbox/end_project/cocina-2.webp'),
			},
			{
				img: require('../../Assets/images/Slider/unbox/end_project/compress-_DSC1549.webp'),
			},
			{
				img: require('../../Assets/images/Slider/unbox/end_project/dormitorio-2.webp'),
			},
			{
				img: require('../../Assets/images/Slider/unbox/end_project/dormitorio.webp'),
			},
			{
				img: require('../../Assets/images/Slider/unbox/end_project/baño.webp'),
			},
			{
				img: require('../../Assets/images/Slider/unbox/end_project/baño-2.webp'),
			},
			{
				img: require('../../Assets/images/Slider/unbox/end_project/baño-3.webp'),
			},
		],
		recorrido: 'https://virtualexperience.pe/3d-model/recorrido-virtual-casaideal-proyecto-unbox/fullscreen/',
		avance: {
			cantButton: 'avance-none',
			title: 'Avance de obra Agosto',
			desktop: [],
		},
	},
	form: [
		{ value: 2, title: '2 Dormitorios' },
		{ value: 3, title: '3 Dormitorios' },
	],
	info: {
		title: 'ABRE ',
		subtitle: 'LA CAJA',
		entrega: 'Entrega Inmediata',
		frase: 'A UN PASO DEL <span class="bold">MALECÓN ARMENDÁRIZ</span>',
		pisos: '7',
		depas: '29',
		dorms: '2 y 3 Dorms.',
		ubicacion: 'Av. San Martín N° 508-512, Barranco',
		precio: 'S/773,100',
		desde: '75m²',
		hasta: '113m²',
		fachada: require('../../Assets/images/fachada/Slide/UNBOX.jpg'),
		fachadaMinified: require('../../Assets/images/fachada/Slide/resize/resize-UNBOX.jpg'),
		// ingreso:require("../../Assets/images/fachada/unbox/ingreso.jpg"),
		parrafo: '<p class="paragraph paragraph-1">En un mundo de cajas cerradas, sé quien las abre y cambia las cosas.</p><p class="paragraph paragraph-2">Ubicado en Av. San Martín, Unbox es un edificio moderno con una arquitectura de tendencia y atractivos detalles en porcelanato tipo mármol y cemento expuesto. Departamentos bien distribuidos para familias nuevas y eternos independientes.</p>',
		parrafoArquitectura1: '<span class="paragraph d-block">Cada detalle del proyecto contribuye al ahorro de agua y energía.</span> Mejor distribución de los espacio y mayor ingreso de luz natural.',
		brochure: '/brochure/Brochure-unbox.pdf',
		arquitectura: '<span class="bold br-movil">ARQUITECTURA </span>& DISEÑO',
		mapa: require('../../Assets/mapas/unbox.jpg'),
		sitios: [
			{
				title: 'Supermercados',
				sitio: ['Plaza Vea Cortijo', 'Metro Barranco'],
			},
			{
				title: 'Colegios y Universidades',
				sitio: ['Colegio Los Reyes Rojos', 'Colegio Santa Rosa', 'Colegio Pedro de Osma', 'Cenfotur', 'UAP Arquitectura', 'UTEC'],
			},
			{
				title: 'Cafés & Rest',
				sitio: ['La 73', 'La bodega Verde', 'Isolina', 'Canta Rana'],
			},
			{
				title: 'Iglesias',
				sitio: ['Iglesia Sagrado Corazón de Jesús', 'Iglesia del Nazareno de Barranco'],
			},
			{ title: 'Entretenimiento', sitio: ['MAC', 'M.A.T.E', 'Dedalo'] },
			{
				title: 'Interconexión con',
				sitio: ['Costa Verde', 'Paseo de la república', 'Miraflores'],
			},
		],
	},
	slideDepa: [
		{
			desktop: require('../../Assets/images/Slider/unbox/slidermain/newUnbox-1.jpg'),
			movil: require('../../Assets/images/Slider/unbox/slidermain/newUnbox-1.jpg'),
		},
		{
			desktop: require('../../Assets/images/Slider/unbox/slidermain/newUnbox-2.jpg'),
			movil: require('../../Assets/images/Slider/unbox/slidermain/newUnbox-2.jpg'),
		},
		{
			desktop: require('../../Assets/images/Slider/unbox/slidermain/newUnbox-3.jpg'),
			movil: require('../../Assets/images/Slider/unbox/slidermain/newUnbox-3.jpg'),
		},
		{
			desktop: require('../../Assets/images/Slider/unbox/slidermain/newUnbox-4.jpg'),
			movil: require('../../Assets/images/Slider/unbox/slidermain/newUnbox-4.jpg'),
		},
	],
	atributos: [
		{
			icon: 'edificio',
			text: '<strong>7</strong> Pisos</br><strong>29</strong> Departamentos',
		},
		{
			icon: 'lamp-malecon',
			text: 'Cercano al</br><strong>Malecón Armendáriz</strong>',
		},
		{ icon: 'gas', text: 'Conexiones de</br><strong>gas natural</strong>' },
		{
			icon: 'camara',
			text: '<strong>Cámaras de seguridad</strong></br> en áreas comunes',
		},
		{ icon: 'lobby', text: 'Lobby de </br><strong>doble altura</strong>' },
		{ icon: 'terraza', text: '<strong>Terraza BBQ</strong>' },
		{ icon: 'bebida', text: '<strong>Zona lounge</strong>' },
		{ icon: 'coworking', text: '<strong>Sala Coworking</strong>' },
		{ icon: 'pet', text: '<strong>PetShower</strong>' },
	],
	tipologias: {
		page: 'unbox',
		template_id: 90,
		buttons: [
			{ text: '02', value: 2 },
			{ text: '03', value: 3 },
			{ text: 'Todos', value: 'all' },
		],
		brand: require('../../Assets/images/proyectos/logo-unbox.png'),
		cards: [
			{
				id: 58,
				type: 'FLAT 102',
				dorms: '2',
				vista: 'exterior',
				img: require('../../Assets/images/tipologias/unbox/102.jpg'),
				metersTotal: '75,29 m²',
				areaTechada: '72,05 m²',
				areaLibre: '18,66 m²',
				min_price: 750000,
				units: [{ price: '' }],
				units_available: 0,
			},
			{
				id: 160,
				type: 'FLAT 103',
				dorms: '2',
				vista: 'exterior',
				img: require('../../Assets/images/tipologias/unbox/Unbox-103.jpg'),
				metersTotal: '75,48 m²',
				areaTechada: '72,05 m²',
				areaLibre: '18,66 m²',
				min_price: 739000,
				units: [{ price: '' }],
			},
			{
				id: 60,
				type: 'FLAT 403',
				dorms: '2',
				vista: 'exterior',
				img: require('../../Assets/images/tipologias/unbox/403.jpg'),
				metersTotal: '81,24 m²',
				areaTechada: '72,05 m²',
				areaLibre: '18,66 m²',
				min_price: 739000,
				units: [{ price: '' }],
			},
			{
				id: 63,
				type: 'FLAT 503',
				dorms: '3',
				vista: 'exterior',
				img: require('../../Assets/images/tipologias/unbox/503-Plano.png'),
				metersTotal: '111.12 m²',
				areaTechada: '72,05 m²',
				areaLibre: '18,66 m²',
				min_price: 739000,
				units: [{ price: '' }],
			},
			{
				id: 67,
				type: 'Dúplex S02',
				dorms: '2',
				vista: 'exterior',
				img: require('../../Assets/images/tipologias/unbox/unbox-s02.jpg'),
				metersTotal: '90,71 m²',
				areaTechada: '72,05 m²',
				areaLibre: '18,66 m²',
				min_price: 750000,
				units: [{ price: '' }],
			},
			{
				id: 68,
				type: 'Flat S03',
				dorms: '2',
				vista: 'exterior',
				img: require('../../Assets/images/tipologias/unbox/S03.jpg'),
				metersTotal: '109,25 m²',
				areaTechada: '75,29 m²',
				areaLibre: '33,96 m²',
				min_price: 848000,
				units: [{ price: '' }],
			},
			{
				id: 69,
				type: 'Flat S04',
				dorms: '2',
				vista: 'interior',
				img: require('../../Assets/images/tipologias/unbox/S04.jpg'),
				metersTotal: '113,62 m²',
				areaTechada: '75,48 m²',
				areaLibre: '38,14 m²',
				min_price: 869000,
				units: [{ price: '' }],
			},
			{
				id: 61,
				type: 'Flat 501',
				dorms: '3',
				vista: 'exterior',
				img: require('../../Assets/images/tipologias/unbox/501.jpg'),
				metersTotal: '112,73 m²',
				areaTechada: '109,17 m²',
				areaLibre: '3,56 m²',
				min_price: 1077000,
				units: [{ price: '' }],
			},
			{
				id: 70,
				type: 'Flat X01',
				dorms: '3',
				vista: 'interior',
				img: require('../../Assets/images/tipologias/unbox/X01.jpg'),
				metersTotal: '94,58 m²',
				min_price: 717000,
				units: [{ price: '' }],
			},
			{
				id: 71,
				type: 'Flat X02',
				dorms: '3',
				vista: 'interior',
				img: require('../../Assets/images/tipologias/unbox/X02-Plano.png'),
				metersTotal: '99,03 m²',
				min_price: 717000,
				units: [{ price: '' }],
			},
			{
				id: 72,
				type: 'Flat X03',
				dorms: '2',
				vista: 'interior',
				img: require('../../Assets/images/tipologias/unbox/unbox-x03.jpg'),
				metersTotal: '78,46 m²',
				min_price: 717000,
				units: [{ price: '' }],
			},
			{
				id: 73,
				type: 'Flat X04',
				dorms: '2',
				vista: 'interior',
				img: require('../../Assets/images/tipologias/unbox/X04.jpg'),
				metersTotal: '75,48 m²',
				min_price: 717000,
				units: [{ price: '' }],
			},
		],
	},
};

export default unbox;
