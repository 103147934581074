import { createSlice } from "@reduxjs/toolkit";

const initialState ={
    numbers:[1,2,3],
    projects:[
        {
            id: "Print",
            img: require("../../Assets/images/Slider/print/fachada/fachada-inicio.png"),
            infImg: require("../../Assets/images/proyectos/brand-print.svg").default,
            imgDesk:require("../../Assets/images/Slider/print/fachada/fachada-inicio.png"),
            type: "En Construcción",
            bono: "",
            ubicacion: "CHORRILLOS",address: "Avenida Arenales 1109, Cercado de Lima.",
            dorms: '2 y 3 Dorms',
            desde:  50,
            hasta: 65,
            ver: "Ver proyecto",
            url: "/print",
        },
        {
            id: "Stay",img: require("../../Assets/images/proyectos/renders/render_stay.png"),
            infImg: require("../../Assets/images/proyectos/renders/brand-stay.jpg"),
            imgDesk:require("../../Assets/images/proyectos/stay-edifice.jpg"),
            type: "En Construcción",
            bono: "",
            ubicacion: "JESÚS MARÍA",address: "Avenida Arenales 1109, Cercado de Lima.",
            dorms: '1, 2 y 3 Dorms',
            desde:  38,
            hasta: 77,
            ver: "Ver proyecto",
            url: "/stay",
        },
        {
            id: "Midway",img: require("../../Assets/images/proyectos/renders/midway.png"),
            infImg: require("../../Assets/images/proyectos/renders/brand-midway.jpg"),
            imgDesk:require("../../Assets/images/proyectos/midway-edifice.png"),
            type: "Próxima Entrega",
            // bono: require('../../Assets/iconos/mivivienda.png'),
            ubicacion: "MAGDALENA",address: "Avenida Arenales 1109, Cercado de Lima.",
            dorms: '1, 2 y 3 Dorms',
            desde:  41,
            hasta: 77,
            ver: "Ver proyecto",
            url: "/midway",
        },
        {
            id: "Unity",
            type: "Próxima Entrega",
            img: require("../../Assets/images/proyectos/renders/unity.png"),
            infImg: require("../../Assets/images/proyectos/renders/brand-unity.jpg"),
            imgDesk:require("../../Assets/images/proyectos/unbox-edifice.png"),
            // bono: require('../../Assets/iconos/mivivienda.png'),
            ubicacion: "CERCADO DE LIMA",
            address: "Avenida Arenales 1109, Cercado de Lima.",
            dorms: '1, 2 y 3 Dorms',
            desde:  40,
            hasta: 106,
            ver: "Ver proyecto",
            url: "/unity",
        },
        {
            id: "Unbox",
            img: require("../../Assets/images/proyectos/renders/unbox.png"),
            infImg: require("../../Assets/images/proyectos/renders/brand-unbox.jpg"),
            type: "Entrega Inmediata",
            bono: "",
            ubicacion: "BARRANCO",
            address: "Av. San Martin 508",
            dorms: '2 y 3 Dorms',
            desde:  75,
            hasta: 113,
            ver: "Ver proyecto",
            url: "/unbox",
        },
        // {
        //     id: "Trend",
        //     img: require("../../Assets/images/proyectos/renders/trend.png"),
        //     infImg: require("../../Assets/images/proyectos/img-info-3.png"),
        //     type: "Entrega Inmediata",
        //     bono: "",
        //     ubicacion: "CERCADO DE LIMA",
        //     dorms: '3 Dorms',
        //     desde: 60,
        //     hasta: 68,
        //     ver: "Ver proyecto",
        //     url: "/trend",
        // },
        // {
        //     id: "Town",
        //     img: require("../../Assets/images/proyectos/renders/town.png"),
        //     infImg: require("../../Assets/images/proyectos/renders/brand-town.jpg"),
        //     type: "Entrega Inmediata",
        //     bono: require('../../Assets/iconos/mivivienda.png'),
        //     ubicacion: "SANTA BEATRIZ",
        //     dorms: '3 Dorms',
        //     desde: 60,
        //     hasta: 67,
        //     ver: "Ver proyecto",
        //     url: "/town",
        // },
        {
            id: "Nou",
            img: require("../../Assets/images/proyectos/renders/nou.png"),
            infImg: require("../../Assets/images/proyectos/renders/brand-nou.jpg"),
            type: "Entrega Inmediata",
            bono: "",
            ubicacion: "PUEBLO LIBRE",
            dorms: '2 y 3 Dorms',
            desde: 58,
            hasta: 72,
            ver: "Ver proyecto",
            url: "/nou",
        },
    ],
    form:[{value:1,title:"1 Dorm"},{value:2,title:"2 Dorms"},{value:3,title:"3 Dorms"}]
}
export const projectsStore = createSlice({
    name:'projectsStore',
    initialState,  
    reducers:{
        
    }
})

export default projectsStore.reducer
