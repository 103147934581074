import React, { Component } from 'react'
import { connect } from 'react-redux'
import Data from '../../components/data/data'
import "./separarDepa.scss"
import { deleteCard, formatCurrency, handleLoader, updateOnlyTipologiesSelected} from '../../data/carts'
import KRGlue from '@lyracom/embedded-form-glue'
import axios from 'axios'
import Swal from 'sweetalert2'
import { FormContainer, setInputProps } from '../../components/common/forms/Form'
import { CheckoutValidationSchema } from '../../components/common/forms/constraints/ValidatonSchemas'
import { initialState } from '../../data/projects/compactProjects'
import { SperantService } from '../../components/common/Http/SperantService'
import { withRouter } from 'react-router-dom'
class SeraparDepa extends Component {
    constructor(props) {
        super(props)
        console.log(props)
        this.optionReceipt = this.optionReceipt.bind(this)
        this.toggleExpand = this.toggleExpand.bind(this)
        this.toggleComprovante = this.toggleComprovante.bind(this)
        this.onCheckout = this.onCheckout.bind(this)
        this.onPaid = this.onPaid.bind(this)
        this.displayPaymentForm = this.displayPaymentForm.bind(this)
        this.sperantService = new SperantService();
        this.state = {
            receipt: "boleta",
            expandUserData: true,
            expandComprovante: true,
        }
    }
    addScripts(){
        // datos de checkealo.pe
        // var imported = document.createElement('script');
        // imported.src = 'https://static.micuentaweb.pe/static/js/krypton-client/V4.0/stable/kr-payment-form.min.js';
        // imported.setAttribute("kr-public-key", "60080920:publickey_TNhvjtud7lOPVL3kJUV0EajqXywALtOpt44e7vK32yg0o");
        // imported.setAttribute("kr-post-url-success", "../compra/gracias");
        // imported.setAttribute("kr-language", "es-ES");
        // document.head.appendChild(imported);
    }
    formatCurrency = value => value &&
        !isNaN(value.toString().replace(/,/g, '')) &&
        parseFloat(value.toString().replace(/,/g, ''))
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")

    optionReceipt(receipt, form){
        form.values.invoice = receipt === "factura"
        this.setState({receipt:receipt})
    }

    toggleExpand(){
        this.setState({expandUserData:!this.state.expandUserData})
    }

    toggleComprovante(){
        this.setState({expandComprovante:!this.state.expandComprovante})
    }

    /**
    * Called on 'checkout' click
    */
    async onCheckout(values, { setSubmitting, resetForm }) {
        // project: this.props.value.tipologiesSelected[0]?.project,
        // start loader
        this.props.handleLoader(true)
         document.querySelector("form button[type=submit]").style.display = "none"

        // Create the order, based on your cart
        const order = {
            "amount": (this.props.value.priceSeparation * this.props.value.tipologiesSelected.length) * 100,
            "currency": "PEN",
            "orderId":  new Date().valueOf(),
            "customer": {
                "email": values.email
            }
        };

        try {
            // Call merchant server to get form token and then display payment form
            const {data:formToken} = await this.getFormToken(order)
            this.displayPaymentForm(formToken, values)
        } catch (error) {
            console.error("Invalid server response. Code " + error.status);
        }
    }

    /**
    * Get form token from merchant server
    * @param order
    * @param resolve
    * @param reject
    */
    getFormToken(order) {
        const project = initialState.projects.find(p=> p.page == this.props.value.tipologiesSelected[0].project).page

        // return axios.post(`http://localhost:3001/init/${project}`, {order: order}, {
        return axios.post(`https://casaideal.com.pe/api/init/${project}`, {order: order}, {
            headers: {'Content-Type': 'application/json'}
        })
    }

    componentDidMount() {
        console.log(this.props.value.tipologiesSelected)
    }

    formatCurrency = value => value &&
        !isNaN(value.toString().replace(/,/g, '')) &&
        parseFloat(value.toString().replace(/,/g, ''))
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")

    async displayPaymentForm(formToken, formValues){
        const endpoint = "https://api.micuentaweb.pe";
        const project = initialState.projects.find(p=> p.page == this.props.value.tipologiesSelected[0].project)
        const publicKey = project.iziPubKey.prod;

        const {KR} = await KRGlue.loadLibrary(endpoint, publicKey) /* Load the remote library */
        KR.setFormConfig({ formToken: formToken, "kr-language": "es-PE" })

        const {result} = await KR.addForm("#myPaymentForm")
        KR.showForm(result.formId)
        KR.onSubmit(payment => this.onPaid(payment, formValues))

        this.props.handleLoader(false);
    }

    async onPaid(payment, formValues){
        const project = initialState.projects.find(p=> p.page == this.props.value.tipologiesSelected[0].project)
        const {orderDetails} = payment.clientAnswer
        const date = new Date().toLocaleString('es-PE', {timeZone: 'America/Lima'})
        const tipologias = [...document.querySelectorAll("select[data-type]")]
                .map(s=>`${s.getAttribute("data-type")} - Piso ${s.value}`).join(', ')
        orderDetails.orderTotalAmount = orderDetails.orderTotalAmount / 100

        // Fecha	Nro. Orden	Proyecto	Tipologias	Nombres	Apellidos	DNI	Correo	Teléfono	Direccion	RUC	Razon social	Direccion fiscal	Monto
        axios.post("https://cors.formulaperu.com/https://script.google.com/macros/s/AKfycbws7GCpc1eEN5ScQ_IisUkLEwKQHvY_XCe5_KEbXA3ytUWVtA/exec", {
            "ss_id": "1w1kLNR1vScNeN9UgBqEJp3VnVvMA3Ii1KZoAM82FlTE",
            "range": "Separaciones!A:XX",
            "values": [[
                date, orderDetails.orderId, orderDetails.orderTotalAmount, project.page, tipologias, formValues.fname, formValues.lname, formValues.dni,
                formValues.telephone, formValues.email, formValues.address, formValues.ruc, formValues.companyName,
                formValues.fiscalAddress
            ]]
        }).catch(e=>console.log(e))

        let client = {
            "fname": formValues.fname,
            "lname": formValues.lname,
            "email": formValues.email,
            "phone": formValues.telephone,
            "project_id": project.sperant_id,
            "interest_type_id": 4,
            "input_channel_id": 4,
            "observation": [
                `Nro Orden: ${orderDetails.orderId}`,
                formValues.dni && `DNI: ${formValues.dni}`,
                formValues.address && `Dirección: ${formValues.address}`,
                formValues.ruc && `RUC: ${formValues.ruc}`,
                formValues.companyName && `Razón social: ${formValues.companyName}`,
                formValues.fiscalAddress && `Dirección fiscal: ${formValues.fiscalAddress}`,
                orderDetails.orderTotalAmount && `Monto pagado: S/. ${orderDetails.orderTotalAmount}`,
                `Tipologías: ${tipologias}`,
            ].filter(Boolean).join(", ")
        }
        window.dataLayer?.push({ ecommerce: null });  // Clear the previous ecommerce object.
        window.dataLayer?.push({
            event: "purchase",
            ecommerce: {
                transaction_id: orderDetails.orderId,
                currency: this.props.value.tipologiesSelected[0].unit.currency,
                value: orderDetails.orderTotalAmount,
                //   tax: 0,
                //   coupon: "",
                items: this.props.value.tipologiesSelected.map(type => ({
                    item_id: type.id,
                    item_name: type.unit.type,
                    item_brand: "Casaideal",
                    item_category: project.page.toLowerCase(),
                    item_category2: type.vista,
                    item_category3: type.dorms,
                    item_category4: type.unit.total_area,
                    item_variant: type.unit.name,
                    price: type.unit.price,
                    quantity: 1
                }))
            }
        })

        await this.sperantService.createClient(client)
        this.props.history.push("/gracias/compra-realizada")
        // Swal.fire({
        //     title: '',
        //     text: 'Pago exitoso, uno de nuestros asesores te contactará pronto',
        //     icon: 'success',
        //     confirmButtonText: 'Aceptar',
        //     confirmButtonColor: 'red'
        // })
    }

    initialValues = {
        fname: "",
        lname: "",
        email: "",
        telephone: "",
        dni: "",
        address: "",
        invoice: false,
        ruc: "",
        fiscalAddress: "",
        companyName: "",
        terms: false
    }
    
    render() {
        return (
            <div className="separar-depa">
                <div className="cart-buy-container">
                    <div className="content-cart">

                        <h2 className="title">
                            <div className="icon icon-buy"></div>
                            <span className="m-light">CARRITO DE <span className="bold">COMPRAS</span></span>
                        </h2>
                        <div className="container-card">
                            <div className=" sub-container-card scroll">
                                {
                                     this.props.value.tipologiesSelected.map((e,index)=>{
                                         return(
                                            <div className='content-all-cards'>
                                                <div className="content-img">
                                                    <img src={e.img}></img>
                                                </div>
                                                <div className="content-text">
                                                    <div className="title-text">{`${e.type}`}</div>
                                                    <div className='inf-content-text dormitorio dormitorio-1'>
                                                        <span className='dorms bold'>Dorms</span>
                                                        <div className="subtitle-text">{`${e.dorms} ${e.dorms > 1 ? 'Dormitorios' : 'Dormitorio'}`}</div>
                                                    </div>
                                                    <div className='inf-content-text dormitorio dormitorio-2'>
                                                        <span className='dorms bold'>Proyecto</span>
                                                        <div className="subtitle-text" style={{textTransform:"capitalize"}}>{e.project}</div>
                                                    </div>
                                                    <div className='inf-content-text piso'>
                                                        <span className='dorms bold'>Pisos</span>
                                                        <select className="select" data-type={e.type}>
                                                            {e.units?.map(u=>
                                                                <option selected={u?.name == e.unit?.name ? true : false} value={u.floor}>Piso {u.floor} - S/ {this.formatCurrency(u.price)}</option>
                                                            )}
                                                        </select>
                                                    </div>
                                                    <div className='inf-content-text area'>
                                                        <span className='dorms bold'>Area Total</span>
                                                        <div className="subtitle-text">{`${e.metersTotal}`}</div>
                                                    </div>
                                                    <div className='inf-content-text vista'>
                                                        <span className='dorms bold'>Vista</span>
                                                        <div className="subtitle-text">{`${e.vista}`}</div>
                                                    </div>
                                                    {/* <div className='inf-content-text'>
                                                        <span className='dorms bold'>Precio</span>
                                                        <div className="subtitle-text">S/ {this.formatCurrency(e.min_price)}</div>
                                                    </div> */}
                                                </div>
                                                <div className='delete_item'>
                                                    <div className='btn-cart icon-trush' onClick={() => this.props.deleteCard(e)}>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                         )
                                    })
                                }
                            </div>
                            <div className="cuota">
                                <img src={require("../../Assets/images/iconos/Calcular-Cuota.png")}></img>
                                <div className="content-text-cuota content-text">
                                    <span className="c-white">¿Tienes un código Promocional?</span>
                                    <div className="content-input mt-1">
                                        <input placeholder=""></input>
                                    </div>
                                </div>
                            </div>
                            <div className="flex-column content-amount">
                                <div className="flex-row">
                                    <span className="subtitle-text">Sub total</span>
                                    <span className="subtitle-text">S/ {this.formatCurrency(this.props.value.priceSeparation * this.props.value.tipologiesSelected.length)}</span>
                                </div>
                                {/* <div className="flex-row">
                                    <span className="subtitle-text">Descuento</span>
                                    <span className="subtitle-text">S/ -500</span>
                                </div> */}
                            </div>
                            <div className="flex-column content-amount content-amount-2">
                                <div className="flex-row">
                                    <span className="subtitle-text">Total a pagar</span>
                                    <span className="subtitle-text">S/ {this.formatCurrency(this.props.value.priceSeparation * this.props.value.tipologiesSelected.length)}</span>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div className="information">
                    <h2 className="title">
                        INFORMACIÓN <span className="bold"> DE PAGO</span>
                    </h2>
                    <FormContainer
                        initialValues={this.initialValues}
                        validationSchema={CheckoutValidationSchema}
                        onSubmit={this.onCheckout}
                    >
                    {form => {const {values, handleSubmit} = form;
                    process.env.NODE_ENV === "development" && console.log(form.errors)
                    return(
                        <form className="flex-row" onSubmit={handleSubmit}>
                            <div className="target">   
                                <div className="separe-block flex-column">
                                    <div className="flex-row separe-item">
                                        <div className="icon">
                                            <div className="user">
                                            </div>
                                        </div>
                                        <span>Datos personales</span>
                                        <div className="icon-out-border pencil" onClick={()=>{this.toggleExpand()}}></div>
                                    </div>
                                    <div className={`data-user ${this.state.expandUserData ? "active" : ""}`}>
                                        <div className="content-input mt-1">
                                            <input placeholder="Nombre*" {...setInputProps("fname", "", form)}></input>
                                        </div>
                                        <div className="content-input">
                                            <input placeholder="Apellidos*" {...setInputProps("lname", "", form)}></input>
                                        </div>
                                        <div className="content-input">
                                            <input placeholder="Correo*" {...setInputProps("email", "", form)}></input>
                                        </div>
                                        <div className="content-input">
                                            <input placeholder="Teléfono*" {...setInputProps("telephone", "", form)}></input>
                                        </div>
                                    </div>
                                </div>
                                <div className="separe-block separe-block-2 flex-column">
                                    <span className="sub-title" style={{marginBottom:".5rem"}}>Añadir método de pago</span>
                                    
                                    <>
                                        <button type="submit" className="btn-submit">Pagar</button>
                                        <div className={`method_pay ${this.props.value.loader == true ? "active" : ''}`}>
                                            <div className={`loader ${this.props.value.loader == true ? "active" : ''}`}>
                                                <div className='loading'>
                                                </div>
                                            </div>
                                            <div id="myPaymentForm"></div>
                                        </div>
                                    </>
                                </div>
                            </div>
                            <div className="target target-facture"> 
                                <div className="facture">
                                    <div className={`type-recibo ${this.state.receipt == "boleta" ? "active" : ""}`} onClick={()=>{this.optionReceipt("boleta", form)}}>Boleta</div>
                                    <div className={`type-recibo ${this.state.receipt == "factura" ? "active" : ""}`} onClick={()=>{this.optionReceipt("factura", form)}}>Factura</div>
                                </div>
                                <div className={`select-receipt ${this.state.receipt}`}>
                                    <div className="content-recibo separe-block flex-column content-recibo-1">
                                        <div className="content-input">
                                            <input placeholder="DNI" {...setInputProps("dni", "", form)}></input>
                                        </div>
                                        <div className="content-input">
                                            <input placeholder="Dirección" {...setInputProps("address", "", form)}></input>
                                        </div>
                                        <div className="content-input">
                                            <span className="title-select">Región</span>
                                            <select className="select b-white">
                                                <option selected>Perú</option>
                                            </select>
                                        </div>
                                        {/* <button className="btn-submit-facture">Datos correctos <div className=""></div></button> */}
                                    </div>
                                    <div className="content-recibo separe-block flex-column content-recibo-2">
                                        <div className="content-input">
                                            <input placeholder="RUC." {...setInputProps("ruc", "", form)}></input>
                                        </div>
                                        <div className="content-input">
                                            <input placeholder="Dirección fiscal" {...setInputProps("fiscalAddress", "", form)}></input>
                                        </div>
                                        <div className="content-input">
                                            <input placeholder="Razón Social" {...setInputProps("companyName", "", form)}></input>
                                        </div>
                                        {/* <button className="btn-submit-facture">Datos correctos <div className=""></div></button> */}
                                    </div>
                                </div>
                            </div>
                        </form>)}}
                    </FormContainer>
                </div>
            </div>
        )
    }
}
const MapStateProps = (state)=>{
    return(
        {
            value:state.cartStore
        }
    )
}
export default withRouter(connect(MapStateProps,{deleteCard,handleLoader, updateOnlyTipologiesSelected})(SeraparDepa))