import { useEffect, useState } from 'react';
import './modalproject.scss'
export const ModalProject = ({img,showmodal}) => {
    console.log(showmodal)
    const [showModal, setShowModal] = useState(false);
    useEffect(() => {
        setShowModal(showmodal)
    }, [showmodal]);
    if(showModal){
        return (
            <div className='content-modal'>
                <div className='modal'>
                    <div className='icon icon-close mask' onClick={()=>{setShowModal(false)}}>
    
                    </div>
                    <img className='' src={img.url} ></img>
                </div>
            </div>
        )
    }
    return(
        <></>
    )

}