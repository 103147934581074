import React, { Component } from 'react';
import './areas.scss';
import { iconos, paginas } from './icons.js';
import Slider from 'react-slick';
import OwlCarousel from 'react-owl-carousel2';
import 'lightgallery/dist/css/lightgallery.css';
import 'lightgallery/dist/js/lightgallery';
import 'lightgallery/dist/js/lightgallery-all';
import $ from 'jquery';
import { getKeyThenIncreaseKey } from 'antd/lib/message';
export default class Areas extends Component {
	constructor(props) {
		super(props);
		this.onDragged = this.onDragged.bind(this);
		this.onDraggedInteriores = this.onDraggedInteriores.bind(this);
		this.activeRecorrido = this.activeRecorrido.bind(this);
		this.btnNext = this.btnNext.bind(this);
		this.btnPrev = this.btnPrev.bind(this);
		this.activeAreas = this.activeAreas.bind(this);
		this.selectSlideComunes = this.selectSlideComunes.bind(this);
		this.selectSlideInteriores = this.selectSlideInteriores.bind(this);
		this.state = {
			areaActive: this.props.areas?.depa_piloto?.length == 0 ? 0 : 6,
			areaComun: 0,
			interior: 0,
			recorrido: '',
			events: { onDragged: this.onDragged, onChanged: function (item) {} },
			eventsInterior: { onDragged: this.onDraggedInteriores, onChanged: function (item) {} },
			options: {
				rewind: false,
				loop: false,
				nav: true,
				center: false,
				autoWidth: true,
				speed: 1000,
				smartSpeed: 300,
				dots: true,
				responsive: {
					0: {
						items: 1,
						autoWidth: true,
					},
					500: {
						items: 1,
						center: true,
					},
					1100: {
						items: 2,
					},
				},
			},
		};
	}
	componentDidMount() {
		$('.owl-slider').lightGallery({
			selector: '.slider',
			thumbnail: true,
			animateThumb: false,
			showThumbByDefault: false,
			subHtmlSelectorRelative: true,
			rotate: false,
			actualSize: false,
			download: true,
			pinterest: false,
			googlePlus: false,
			twitter: false,
			fullScreen: false,
		});
	}
	activeAreas(e) {
		this.setState({ areaActive: e });
	}

	onDragged = e => {
		let time = setInterval(() => {
			this.setState({ areaComun: e.page.index });
			clearInterval(time);
		}, 300);
	};
	onDraggedInteriores = e => {
		let time = setInterval(() => {
			this.setState({ interior: e.page.index });
			clearInterval(time);
		}, 300);
	};
	selectSlideComunes(index) {
		this.setState({ areaComun: index });
		let time = setInterval(() => {
			this.sliderComunes.goTo(index);
			clearInterval(time);
		}, 100);
	}
	selectSlideInteriores(index) {
		this.setState({ interior: index });
		let time = setInterval(() => {
			this.sliderInteriores.goTo(index);
			clearInterval(time);
		}, 100);
	}
	btnNext() {
		let max = this.props.areas.comunes.length;
		this.state.areaComun + 1 == max ? this.selectSlideComunes(0) : this.selectSlideComunes(this.state.areaComun + 1);
	}
	btnPrev() {
		let max = this.props.areas.comunes.length;
		this.state.areaComun <= 0 ? this.selectSlideComunes(max - 1) : this.selectSlideComunes(this.state.areaComun - 1);
	}

	btnNextInterior() {
		let max = this.props.areas.interiores.length;
		this.state.interior + 1 == max ? this.selectSlideInteriores(0) : this.selectSlideInteriores(this.state.interior + 1);
	}
	btnPrevInterior() {
		let max = this.props.areas.interiores.length;
		this.state.interior <= 0 ? this.selectSlideInteriores(max - 1) : this.selectSlideInteriores(this.state.interior - 1);
	}
	activeRecorrido(e) {
		this.setState({ areaActive: e, recorrido: this.props.areas.recorrido });
	}
	render() {
		return (
			<div className={'areas ' + this.props.page}>
				<div className='controlador'>
					<h2 className='title'>
						<span className='bold'>DESCUBRE</span> SUS AMBIENTES
					</h2>
					<div className={`flex-row change-row change-row-${this.props.areas.cant}`}>
						{this.props.areas?.depa_piloto?.length == 0 ? null : (
							<div
								className={`btn btn-radio btn-project_piloto ${this.state.areaActive == 6 ? 'active' : ''}`}
								onClick={() => {
									this.activeAreas(6);
								}}
							>
								Depa piloto
							</div>
						)}
						<div
							className={`btn btn-radio ${this.state.areaActive == 0 ? 'active' : ''}`}
							onClick={() => {
								this.activeAreas(0);
							}}
						>
							Áreas comunes
						</div>
						<div
							className={`btn btn-radio ${this.state.areaActive == 1 ? 'active' : ''}`}
							onClick={() => {
								this.activeAreas(1);
							}}
						>
							Interiores
						</div>
						{this.props.areas.exteriores.desktop.length == 0 ? null : (
							<div
								className={`btn btn-radio ${this.state.areaActive == 2 ? 'active' : ''}`}
								onClick={() => {
									this.activeAreas(2);
								}}
							>
								Exteriores
							</div>
						)}
						{this.props.areas?.recorrido.length == 0 ? null : (
							<div
								className={`btn btn-radio btn-iframe ${this.state.areaActive == 3 ? 'active' : ''}`}
								onClick={() => {
									this.activeRecorrido(3);
								}}
							>
								Recorrido 3D
							</div>
						)}
						{this.props.areas?.avance.desktop.length == 0 ? null : (
							<div
								className={`btn btn-radio btn-avance-de-obra ${this.state.areaActive == 4 ? 'active' : ''}`}
								onClick={() => {
									this.activeRecorrido(4);
								}}
							>
								Avance de obra
							</div>
						)}
						{this.props.areas?.end_project.images.length == 0 ? null : (
							<div
								className={`btn btn-radio btn-end-project ${this.state.areaActive == 5 ? 'active' : ''}`}
								onClick={() => {
									this.activeRecorrido(5);
								}}
							>
								Proyecto terminado
							</div>
						)}
					</div>
					<div class={`content-icons ${this.state.areaActive >= 2 ? 'none' : ''}`}>
						<div className={`icon-comunes ${this.state.areaActive == 0 ? 'active' : ''}`}>
							<div className={`item-scroll ${this.props.areas.comunes.length > 5 ? 'scroll' : ''}`}>
								{this.props.areas.comunes.map((item, index) => {
									return (
										<div
											className={`icon ${this.state.areaComun == index ? 'active' : ''}`}
											onClick={e => {
												this.selectSlideComunes(index);
											}}
										>
											<div className={`mask ${item.icon}`}></div>
										</div>
									);
								})}
							</div>
							<div className={`signal ${this.props.areas.comunes.length < 5 ? 'none' : ''}`}>
								<div className='btn btn-prev'>
									<div className='signal-prev mask'></div>
								</div>
								<div className='btn btn-next'>
									<div className='signal-next mask'></div>
								</div>
							</div>
						</div>
						<div className={`icon-interiores ${this.state.areaActive == 1 ? 'active' : ''}`}>
							<div className={`item-scroll ${this.props.areas.interiores.length > 5 ? 'scroll' : ''}`}>
								{this.props.areas.interiores.map((item, index) => {
									return (
										<div
											className={`icon ${this.state.interior == index ? 'active' : ''}`}
											onClick={e => {
												this.selectSlideInteriores(index);
											}}
										>
											<div className={`mask ${item.icon}`}></div>
										</div>
									);
								})}
							</div>
							<div className={`signal ${this.props.areas.interiores.length < 5 ? 'none' : ''}`}>
								<div className='btn btn-prev'>
									<div className='signal-prev mask'></div>
								</div>
								<div className='btn btn-next'>
									<div className='signal-next mask'></div>
								</div>
							</div>
						</div>
					</div>
					<div className='content-slider'>
						<div className={`areas-comunes ${this.state.areaActive == 0 ? 'active' : ''}`}>
							<OwlCarousel ref={sliderComunes => (this.sliderComunes = sliderComunes)} options={this.state.options} events={this.state.events} className='owl-slider'>
								{this.props.areas.comunes.map((item, index) => {
									return (
										<div className='slider' data-src={item.desktop} href={item.desktop}>
											<picture className='expand-img'>
												<source media='(min-width: 650px)' srcset={item.desktop}></source>
												<img src={item.movil} style={{ objectPosition: `${item.position ? item.position : ''}` }}></img>
											</picture>
										</div>
									);
								})}
							</OwlCarousel>
							<div className='icon-signal'>
								<div className='btn-prev' onClick={() => this.btnPrev()}>
									<div className='mask signal-prev'></div>
								</div>
								<div className='btn-next' onClick={() => this.btnNext()}>
									<div className='mask signal-next'></div>
								</div>
							</div>
							<div class='info-slider'>
								<div class='sky'>
									<div class={`mask icon ${this.props.areas.comunes[this.state.areaComun].icon}`}></div>
									<span class='title'>{this.props.areas.comunes[this.state.areaComun].title}</span>
								</div>
								<span class='paragraph'>{this.props.areas.comunes[this.state.areaComun].paragraph}</span>
							</div>
						</div>
						<div className={`interiores ${this.state.areaActive == 1 ? 'active' : ''}`}>
							<OwlCarousel ref={sliderInteriores => (this.sliderInteriores = sliderInteriores)} options={this.state.options} events={this.state.eventsInterior} className='owl-slider'>
								{this.props.areas.interiores.map((item, index) => {
									return (
										<div key={'interiores-' + index} className='slider' data-src={item.desktop} href={item.desktop}>
											<picture className='expand-img'>
												<source media='(min-width: 650px)' srcset={item.desktop}></source>
												<img src={item.movil} style={{ objectPosition: `${item.position ? item.position : ''}` }}></img>
											</picture>
										</div>
									);
								})}
							</OwlCarousel>
							<div className='icon-signal'>
								<div className='btn-prev' onClick={() => this.btnPrevInterior()}>
									<div className='mask signal-prev'></div>
								</div>
								<div className='btn-next' onClick={() => this.btnNextInterior()}>
									<div className='mask signal-next'></div>
								</div>
							</div>
							<div class='info-slider'>
								<div class='sky'>
									<div class={`mask icon ${this.props.areas.interiores[this.state.interior].icon}`}></div>
									<span class='title'>{this.props.areas.interiores[this.state.interior].title}</span>
								</div>
								<span class='paragraph'>{this.props.areas.interiores[this.state.interior].paragraph}</span>
							</div>
						</div>
						{this.props.areas.exteriores.desktop.length == 0 ? null : (
							<div className={`exteriores ${this.state.areaActive == 2 ? 'active' : ''}`}>
								<OwlCarousel ref={exterior => (this.exterior = exterior)} options={this.state.options} events={this.events} className='owl-slider'>
									{this.props.areas.exteriores.desktop.map((item, index) => {
										return (
											<div key={'exteriores-' + index} className='slider' data-src={item.img} href={item.img}>
												<picture className='expand-img'>
													<source media='(min-width: 650px)' srcset={item.img}></source>
													<img src={item.img}></img>
												</picture>
											</div>
										);
									})}
								</OwlCarousel>
								<div className='icon-signal'>
									<div className='btn-prev' onClick={() => this.exterior.prev()}>
										<div className='mask signal-prev'></div>
									</div>
									<div className='btn-next' onClick={() => this.exterior.next()}>
										<div className='mask signal-next'></div>
									</div>
								</div>
								{/* <div class="info-slider avance">
                                <div class="sky">
                                    <div class={`mask icon excavadora`}></div>
                                    <span class="title">Avance del obra Febrero</span>
                                </div>
                            </div> */}
							</div>
						)}
						{this.props.areas.recorrido.length == 0 ? null : (
							<div className={`virtual-frame ${this.state.areaActive == 3 ? 'active' : ''}`}>
								<div className='frame'>
									<iframe frameborder='0' allowfullscreen='true' allow='vr,gyroscope,accelerometer,fullscreen' scrolling='no' src={this.state.recorrido}></iframe>
								</div>
							</div>
						)}
						{this.props.areas.avance.desktop.length == 0 ? null : (
							<div className={`avance-de-obra ${this.state.areaActive == 4 ? 'active' : ''}`}>
								<OwlCarousel ref={avance => (this.avance = avance)} options={this.state.options} events={this.events} className='owl-slider'>
									{this.props.areas.avance.desktop.map((item, index) => {
										return (
											<div key={'interiores-' + index} className='slider' data-src={item.img} href={item.img}>
												<picture className='expand-img'>
													<source media='(min-width: 650px)' srcset={item.img}></source>
													<img src={item.img}></img>
												</picture>
											</div>
										);
									})}
								</OwlCarousel>
								<div className='icon-signal'>
									<div className='btn-prev' onClick={() => this.avance.prev()}>
										<div className='mask signal-prev'></div>
									</div>
									<div className='btn-next' onClick={() => this.avance.next()}>
										<div className='mask signal-next'></div>
									</div>
								</div>
								<div class='info-slider avance'>
									<div class='sky'>
										<div class={`mask icon excavadora`}></div>
										<span class='title'>{this.props.areas.avance.title}</span>
									</div>
								</div>
							</div>
						)}
						{this.props.areas['end_project']?.images.length == 0 ? null : (
							<div className={`end_project ${this.state.areaActive == 5 ? 'active' : ''}`}>
								<OwlCarousel ref={endproject => (this.endproject = endproject)} options={this.state.options} events={this.events} className='owl-slider'>
									{this.props.areas.end_project.images.map((item, index) => {
										return (
											<div key={'interiores-' + index} className='slider' data-src={item.img} href={item.img}>
												<picture className='expand-img'>
													<source media='(min-width: 650px)' srcset={item.img}></source>
													<img src={item.img}></img>
												</picture>
											</div>
										);
									})}
								</OwlCarousel>
								<div className='icon-signal'>
									<div className='btn-prev' onClick={() => this.endproject.prev()}>
										<div className='mask signal-prev'></div>
									</div>
									<div className='btn-next' onClick={() => this.endproject.next()}>
										<div className='mask signal-next'></div>
									</div>
								</div>
							</div>
						)}
						{this.props.areas['depa_piloto']?.length == 0 ? null : (
							<div className={`depa_piloto ${this.state.areaActive == 6 ? 'active' : ''}`}>
								<OwlCarousel ref={depapiloto => (this.depapiloto = depapiloto)} options={this.state.options} events={this.events} className='owl-slider'>
									{this.props.areas.depa_piloto.map((item, index) => {
										return (
											<div key={'interiores-' + index} className='slider' data-src={item.img} href={item.img}>
												<picture className='expand-img'>
													<source media='(min-width: 650px)' srcset={item.img}></source>
													<img alt={'Depa piloto - imagen-' + (index + 1)} src={item.img}></img>
												</picture>
											</div>
										);
									})}
								</OwlCarousel>
								<div className='icon-signal'>
									<div className='btn-prev' onClick={() => this.depapiloto.prev()}>
										<div className='mask signal-prev'></div>
									</div>
									<div className='btn-next' onClick={() => this.depapiloto.next()}>
										<div className='mask signal-next'></div>
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		);
	}
}
