import React, { Component } from 'react'
import "./information.scss"
import MediaQuery from 'react-responsive';
import sr from '../scrollreveal/scrollreveal'
import $ from 'jquery';
$(document).ready(function(){
    // sr.reveal(".img-info1", {origin:"bottom",duration:1800,distance:"100px",delay:200,reset:false,easing:"ease-out"});
    // sr.reveal(".img-info2", {origin:"bottom",duration:1800,distance:"100px",delay:200,reset:false,easing:"ease-out"});
    // sr.reveal(".text_content", {origin:"bottom",duration:1800,distance:"100px",delay:200,reset:false,easing:"ease-out"});
    // sr.reveal(".data", {origin:"bottom",duration:1800,distance:"100px",delay:200,reset:false,easing:"ease-out"});
    // sr.reveal(".input ", {origin:"bottom",duration:1800,distance:"100px",delay:200,reset:false,easing:"ease-out"});
});
export default class Information extends Component {
    constructor(props){
        super(props)
    }
    
    render() {
        return (
            <div className={"Information "+this.props.page} id="descripcion">
                <div className='millenial'>
                    <div className='text_content'>
                        <p className="titles"><span className="bold">{this.props.info.title}</span> {this.props.info.subtitle}</p>
                        <p className="paragraph">{this.props.info.parrafo1}
                            <div className="space"></div>
                            {this.props.info.parrafo2}</p>
                    </div>
                    <div className="span-1"></div>
                    <div className='data'> 
                        <div className="send"><span className="etapa">{this.props.info.entregamovil}</span></div>
                        <div className="showMovil attributes">
                            <span className="flex-center"><span className="info-attr">Edificio de </span><span className="title-attr">{this.props.info.movil.pisos}</span></span>
                            <span className="flex-center title-attr">{this.props.info.movil.dorms}</span>
                            <span className="flex-center"><span className="info-attr" >Precio desde </span><span className="title-attr">{this.props.info.movil.precio}</span></span>
                            <span className="flex-center"><span className="title-attr">{this.props.info.movil.ubicacion1}</span><span className="info-attr"></span><span className="info-attr" >{this.props.info.movil.ubicacion2}</span></span>
                        </div>
                        <div className="desktop flex-row">
                            <div className='attributes'>
                                <span className="title-attr">Precio desde</span>
                                <span className="info-attr">{this.props.info.precio}</span></div>
                            <div className='attributes'>
                                <span className="title-attr">Pisos</span>
                                <span className="info-attr">{this.props.info.pisos}</span></div>
                            <div className='attributes'>
                                <span className="title-attr">Depas</span>
                                <span className="info-attr ">{this.props.info.depas} </span>
                            </div>
                            <div className='attributes'>
                                <span className="title-attr">Dormitorios</span>
                                <span className="info-attr plus" dangerouslySetInnerHTML={{__html:this.props.info.dorms}}></span>
                            </div>
                            <div className='attributes' id="ubicacion">
                                <span className="title-attr">Ubicación</span>
                                <span className="info-attr">{this.props.info.ubicacion}</span>
                            </div>
                        </div>
                        <div className="flex-column p-top">
                            <div className='attributes' id="ubicacion-desktop">
                                    <span className="title-attr">Ubicación</span>
                                    <span className="info-attr">{this.props.info.movil.ubicacion1}{this.props.info.movil.ubicacion2}</span>
                                    <span className="info-attr"></span>
                            </div>
                            <div className="content-brochure">
                                <a href={this.props.info.brochure} target="_blank" className="brochure d-block">Ver Brochure</a>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div className='arquitecture'>
                        <p className="titles" dangerouslySetInnerHTML={{__html:this.props.info.arquitectura}}></p>
                        <div className='content-info'>
                            <img className='img-info1' src={this.props.info.ingreso}></img>
                            <img className='img-info2' src={this.props.info.fachada}></img>
                            <div className='content-text'>
                                <div className='texto' dangerouslySetInnerHTML={{__html:this.props.info.parrafoArquitectura1}}>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                

        )
    }
}
