import { SperantService } from "../../components/common/Http/SperantService";

export class ProjectHandler {
    constructor() {
      this.sperantService = new SperantService();
    }

    async processProjects(tempProjects) {
        const projectPromises = tempProjects.map((project) => this.processProject(project));
        const processedProjects = await Promise.all(projectPromises);
        return processedProjects;
    }

    async processProject(project) {
      project = JSON.parse(JSON.stringify(project));
      const unitsData = await this.fetchAllUnits(project.sperant_id);
      
        const typeHashTable = this.createTypeHashTable(project.tipologias.cards);
        this.processUnits(unitsData, typeHashTable);
        project.tipologias.cards = Array.from(typeHashTable.values());
        return project;
    }


    async fetchAllUnits(sperantId) {
      console.log(sperantId)
      const { data: recon } = await this.sperantService.units(sperantId);
      const lastPage = new URL(recon.links.last).searchParams.get("page");
      const pagePromises = Array.from({ length: lastPage - 1 }, (_, i) => i + 2).map((page) =>
        this.sperantService.units(sperantId, { page })
      );
      const responses = await Promise.all(pagePromises);
      return [recon.data, ...responses.map(({ data }) => data.data)].flat();
    }

    createTypeHashTable(cards) {
      return new Map(
        cards.map((t) => [
          !t.units_range ? t.id : t.id + "-" + t.units_range.join("-"),
          { ...t, units: [] },
        ])
      );
    }

    processUnits(unitsData, typeHashTable) {
      const keysRange = Array.from(typeHashTable.keys()).filter((k) =>
        typeof k === "string" && k.includes("-")
      );
      unitsData.forEach((unit) => this.processUnit(unit, typeHashTable, keysRange));
      typeHashTable.forEach((typeObject, key) => {
        if(typeObject.units.length > 0){
            if (typeObject.units_range) {
                const unitsInRange = typeObject.units.filter(u => parseInt(u.name) >= typeObject.units_range[0] && parseInt(u.name) <= typeObject.units_range[1]);
                typeObject.units = unitsInRange.length ? unitsInRange : typeObject.units;
            }
            typeObject.units.sort((a, b) => a.price - b.price) // Sort units by price ascending order
        } else {
            typeHashTable.delete(key) // Delete types with no units
        }
    })
    }

    processUnit(unit, typeHashTable, keysRange) {
      const { attributes: { id, type_id, commercial_status_id, property_type, price } } = unit;
      if (commercial_status_id === 1 && property_type.includes("departamento")) {
        const unitAttributes = unit.attributes;
        const typeObject = typeHashTable.get(type_id) || typeHashTable.get(keysRange.find(k => k.includes(id)));

        if (typeObject) {
          typeObject.units.push(unitAttributes);
          this.updateMinPrice(typeObject, price);
        }
      }
    }

    updateMinPrice(typeObject, price) {
      if (!typeObject.min_price || price < typeObject.min_price) {
        typeObject.min_price = price;
      }
    }
}