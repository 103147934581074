const unity = {
    sperant_id: 20,
    route:"/unity",
    page:"unity",
    district:'Cercado de Lima',
    whatsapp:["924898307"],
    titlehead:"Proyecto Unity | Departamentos en Cercado de Lima",
    metadescription:"Vive en la zona más céntrica de Cercado de Lima, límite con Jesús María. Departamentos de 1, 2 y 3 dorms desde S/298,500. Unity es un proyecto que crece contigo para poder disfrutar de cada espacio junto a tu familia o amigos, cuenta con áreas que te dan esa libertad que tanto necesitas Cotiza aquí.",
    metakeywords:"departamentos en cercado de lima, departamentos en venta en cercado de lima, inmobiliaria, cercado de lima, casaideal inmobiliaria, departamentos límite con jesús maría, departamentos de 1, 2 y 3 dormitorios, edificio en construcción, departamentos en construcción",
    iziPubKey: {
        prod: "71379667:publickey_u4bHfowsjySebqURqXAwsGWY1LXh13LLF3Hb1RWzWjOgp",
        dev: "71379667:testpublickey_cIj2hVIYdsZo3qjyI2Cjg0pn4CBDVBHfFO8NDqiN0PTkG",
    },
    areas:{
        cant:4,
        comunes:[
            {title:"LOBBY",icon:"lobby",desktop:require("../../Assets/images/Slider/unity/comunes/Lobby.jpg"),movil:require("../../Assets/images/Slider/unity/comunes/Lobby.jpg"),paragraph:"Un lobby con detalles en mármol y cemento expuesto dan modernidad al ingreso."},
            {title:"RELAX ZONE",icon:"relax",desktop:require("../../Assets/images/Slider/unity/comunes/Relax-zone.jpg"),movil:require("../../Assets/images/Slider/unity/comunes/Relax-zone.jpg"),position:"center 49%",paragraph:"Ubicado en el último piso, con una zona lounge y una terraza donde podrás pasar momentos increíbles con la familia y amigos."},
            {title:"TERRAZA GRILL",icon:"parrilla",desktop:require("../../Assets/images/Slider/unity/comunes/terraza-grill1.jpg"),movil:require("../../Assets/images/Slider/unity/comunes/terraza-grill1.jpg"),paragraph:"Ubicado en el último piso, con una zona lounge y una terraza donde podrás pasar momentos increíbles con la familia y amigos."},
            {title:"TERRAZA GRILL",icon:"parrilla",desktop:require("../../Assets/images/Slider/unity/comunes/terraza-grill2.jpg"),movil:require("../../Assets/images/Slider/unity/comunes/terraza-grill2.jpg"),paragraph:"Ubicado en el último piso, con una zona lounge y una terraza donde podrás pasar momentos increíbles con la familia y amigos."},
            {title:"YOGA Y BIENESTAR",icon:"yoga",desktop:require("../../Assets/images/Slider/unity/comunes/yoga-y-bienestar.jpg"),movil:require("../../Assets/images/Slider/unity/comunes/yoga-y-bienestar.jpg"),paragraph:"Ejercítate sin salir de casa y lleva un estilo de vida saludable."},
            {title:"COWORKING",icon:"coworking",desktop:require("../../Assets/images/Slider/unity/comunes/coworking.jpg"),movil:require("../../Assets/images/Slider/unity/comunes/coworking.jpg"),paragraph:"Ubicado en el último piso, con una zona lounge y una terraza donde podrás pasar momentos increíbles con la familia y amigos."},
            {title:"AREA CHILL",icon:"area-chill",desktop:require("../../Assets/images/Slider/unity/comunes/area-chill.jpeg"),movil:require("../../Assets/images/Slider/unity/comunes/area-chill.jpeg"),position:"center",paragraph:"Un espacio diseñado para relajarte y distraerte con tus amigos."},
            {title:"SALA DE NIÑOS",icon:"area-child",desktop:require("../../Assets/images/Slider/unity/comunes/sala-child.jpeg"),movil:require("../../Assets/images/Slider/unity/comunes/sala-child.jpeg"),position:"center",paragraph:"Un lugar para dejar correr la imaginación, con piscina de pelotas y juegos increíbles."},
            {title:"CENTRO DE LAVADO",icon:"lavado",desktop:require("../../Assets/images/Slider/unity/comunes/Centro-de-lavado.jpg"),movil:require("../../Assets/images/Slider/unity/comunes/Centro-de-lavado.jpg"),position:"center",    paragraph:"Contar con un centro de lavado como área común es un gran beneficio ya que aprovechamos más los espacios dentro de nuestro departamento. Un ambiente completamente funcional donde podrás realizar tus tareas de aseo."},
        ],
        interiores:[
            {title:"SALA",icon:"relax",desktop:require("../../Assets/images/Slider/unity/interiores/sala.jpg"),movil:require("../../Assets/images/Slider/unity/interiores/sala.jpg"),paragraph:"Con un balcón que refresca y piso texturizado para darle calidez al departamento"},
            {title:"SALA DE ESTAR",icon:"sala-estar",desktop:require("../../Assets/images/Slider/unity/interiores/sala-de-estar.jpg"),movil:require("../../Assets/images/Slider/unity/interiores/sala-de-estar.jpg"),position:"center",paragraph:"Después de un día lleno de actividades, que mejor que relajarse con una película."},
            {title:"DORMITORIO PRINCIPAL",icon:"bed",desktop:require("../../Assets/images/Slider/unity/interiores/dormitorio-principal.jpg"),movil:require("../../Assets/images/Slider/unity/interiores/dormitorio-principal.jpg"),position:"center 72%",paragraph:"Habitación ideal para tu día a día. Buena luz natural con amplio espacio."},
            {title:"COCINA",icon:"refri",desktop:require("../../Assets/images/Slider/unity/interiores/cocina.jpg"),movil:require("../../Assets/images/Slider/unity/interiores/cocina.jpg"),position:"center 25%",paragraph:"Cocina de concepto abierto con detalles en granito, muebles altos y bajos con luminarias elegantes para hacer de este un espacio acogedor."},
            {title:"BAÑO PRINCIPAL",icon:"ducha",desktop:require("../../Assets/images/Slider/unity/interiores/bano-principal.jpg"),movil:require("../../Assets/images/Slider/unity/interiores/bano-principal.jpg"),paragraph:"Habitación ideal para tu día a día. Buena luz natural con amplio espacio."},
        ],
        exteriores:{
            cantButton:"avance-none",
            title:"",
            desktop:[
                {img:require("../../Assets/images/Slider/exteriores/ingreso-unity.jpg")},
                {img:require("../../Assets/images/Slider/exteriores/dia/unity.jpg")},
                {img:require("../../Assets/images/Slider/exteriores/noche/unity.jpg")},
            ]
        },
        recorrido:{
            cantButton:"avance-none",
            title:"",
            desktop:[
                {img:require("../../Assets/images/Slider/exteriores/ingreso-midway.jpg")},
                {img:require("../../Assets/images/Slider/exteriores/dia/unity.jpg")},
                {img:require("../../Assets/images/Slider/exteriores/noche/unity.jpg")},
            ]
        },
        avance:{
            cantButton:"avance-none",
            title:"Avance de obra Junio",
            desktop:[
                {img:require("../../Assets/images/Slider/unity/avance/img-1.webp")},
                {img:require("../../Assets/images/Slider/unity/avance/img-2.webp")},
                {img:require("../../Assets/images/Slider/unity/avance/img-3.webp")},
                {img:require("../../Assets/images/Slider/unity/avance/img-4.webp")},
                {img:require("../../Assets/images/Slider/unity/avance/img-5.webp")},
                {img:require("../../Assets/images/Slider/unity/avance/img-6.webp")},
            ],
        },
        end_project:{
            cantButton:5,
            title:"",
            images:[
            ]
        },
        depa_piloto:[
            {img: require('../../Assets/images/Slider/unity/depa_piloto/sala-comedor.jpg')},
            {img: require('../../Assets/images/Slider/unity/depa_piloto/sala.png')},
            {img: require('../../Assets/images/Slider/unity/depa_piloto/cocina.jpg')},
            {img: require('../../Assets/images/Slider/unity/depa_piloto/dormitorio.png')},
            {img: require('../../Assets/images/Slider/unity/depa_piloto/dormitorio-3.jpg')},
            {img: require('../../Assets/images/Slider/unity/depa_piloto/pasillo.png')},
            {img: require('../../Assets/images/Slider/unity/depa_piloto/bathroom.jpg')},
            
            {img: require('../../Assets/images/Slider/unity/depa_piloto/sala-204.jpg')},
            {img: require('../../Assets/images/Slider/unity/depa_piloto/cocina-204.jpg')},
            {img: require('../../Assets/images/Slider/unity/depa_piloto/dormitorio-2.jpg')},
            {img: require('../../Assets/images/Slider/unity/depa_piloto/dormitorio-204.jpg')},
            {img: require('../../Assets/images/Slider/unity/depa_piloto/escritorio-204.jpg')},
            {img: require('../../Assets/images/Slider/unity/depa_piloto/bath-204.jpg')},
        ],
        recorrido:"https://kuula.co/share/collection/7Pym4?fs=1&vr=1&sd=1&initload=0&thumbs=3&inst=es&info=0&logo=-1",
    },
    form:[
        {value:1,title:"1 Dormitorio"},
        {value:2,title:"2 Dormitorios"},
        {value:3,title:"3 Dormitorios"}
    ],
    info:{
        title:"UNA VIDA EN EL",subtitle:"LUGAR CORRECTO",
        entrega:'Próxima Entrega',
        entregamovil:'Próxima Entrega',
        frase:'LA ZONA MÁS CÉNTRICA DE LIMA LÍMITE </br> CON <span class="bold">JESÚS MARÍA</span>',
        pisos:"17",depas:"96",dorms:"1, 2 y 3 Dorms.",ubicacion:"Av. Arenales N° 1109, Cercado de Lima",precio:" S/298,500",desde:"40.02m²",hasta:"106.26m²",
        fachada:require("../../Assets/images/fachada/Slide/UNITY.jpg"),
        fachadaMinified:require("../../Assets/images/fachada/Slide/resize/resize-UNITY.jpg"),
        // ingreso:require("../../Assets/images/fachada/unity/acercamiento-unity.jpg"),
        parrafo:'<p class="paragraph">Un proyecto que crece contigo, porque entendemos que hoy por hoy, lo más importante es encontrar un lugar correcto para vivir.</p><p class="paragraph paragraph-2">Unity tiene como principal objetivo; pasar en familia, aprovechar al máximo el tiempo y sobre todo disfrutar de la comodidad y libertad que le da cada espacio. Nuestro proyecto cuenta con la mejor ubicación, para ti y para los que te acompañan. </p>',
        parrafoArquitectura1:'En Unity podrás disfrutar de cada espacio junto a tu familia o amigos, porque contamos con áreas que te dan esa libertad que tanto necesitas. ¿Qué esperas para formar parte de Unity?',
        brochure:'/brochure/Brochure-unity.pdf',
        arquitectura:'<span class="bold">ARQUITECTURA </span>Y DISEÑO',
        mapa:require("../../Assets/mapas/unity-map.jpg"),
        class:"",
        sitios:[
            {title:"Clínicas - Hospitales",sitio:["Policlínico Jesús María","INCOR","Hospital Edgardo Rebagliati","ESSALUD","Clínica Santa Beatriz","Policlínico Pablo Bermúdez","Clínica de Fertilidad"]},
            {title:"Bancos",sitio:["Cajero BCP","Scotiabank"],},
            {title:"Entidades",sitio:["Serpost","SUTRAN","MINSA","MININTER"]},
            {title:"Colegios, institutos",sitio:["Instituto SISE","Instituto Italiano de Cultura","Trilce"]},
            {title:"Teatros, museos, parques",sitio:["Museo de Historia Natural UNMSM","Teatro Pirandello","Circuito Mágico del Agua","Parque Habich","Jardín Botánico de Plantas Medicinales","Estadio Nacional","Campo de Marte"]},
            {title:"Metropolitano",sitio:["Estación México"]},
        ],
    },
    slideDepa:[
        {desktop:require("../../Assets/images/Slider/unity/sliderMain/newUnity-1.jpg"),movil:require("../../Assets/images/Slider/unity/sliderMain/newUnity-1.jpg")},
        {desktop:require("../../Assets/images/Slider/unity/sliderMain/newUnity-2.jpg"),movil:require("../../Assets/images/Slider/unity/sliderMain/newUnity-2.jpg")},
        {desktop:require("../../Assets/images/Slider/unity/sliderMain/newUnity-3.jpg"),movil:require("../../Assets/images/Slider/unity/sliderMain/newUnity-3.jpg")},
        // {desktop:require("../../Assets/images/Slider/unity/sliderMain/newUnity-4.jpg"),movil:require("../../Assets/images/Slider/unity/sliderMain/newUnity-4.jpg")},
        {desktop:require("../../Assets/images/Slider/unity/comunes/area-chill.jpeg"),movil:require("../../Assets/images/Slider/unity/comunes/area-chill.jpeg")},

    ],
    atributos:[
        {icon:"gas",text:"Conexiones de gas</br><strong>natural</strong>"},
        {icon:"camara",text:"<strong>Cámaras de seguridad</strong></br> en áreas comunes"},
        {icon:"sala-de-espera",text:"<strong>Lobby &</br> sala de espera</strong>"},
        {icon:"relax",text:"<strong>Relax zone</strong>"},
        {icon:"terraza",text:"<strong>Terraza Grill</strong>"},
        {icon:"yoga",text:"<strong> Yoga y bienestar</strong>"},
        {icon:"sala-coworking",text:"<strong>Coworking</strong>"},
        {icon:"area-chill",text:"<strong>Área chill</strong>"},
        {icon:"lavado",text:"<strong>Zona </br> de lavado</strong>"},
    ],
    tipologias:{
        page:"unity",
        template_id: 92,
        buttons:[{text:"01",value:1},{text:"02",value:2},{text:"03",value:3},{text:"Todos",value:"all"}],
        brand:require('../../Assets/images/tipologias/unity/logo.png'),
        cards: [
            {id: 80, vista:"exterior",type:"Flat 101",dorms:"2",img:require("../../Assets/images/tipologias/unity/Tipo101.jpg"),metersTotal:"59.36 m²", units:[{price:''}], units_available: 0},
            {id: 81, vista:"exterior",type:"Flat 102",dorms:"2",img:require("../../Assets/images/tipologias/unity/Tipo102.jpg"),metersTotal:"59.39²", units:[{price:''}], units_available: 0},
            {id: 83, vista:"exterior",type:"Flat 104",dorms:"1",img:require("../../Assets/images/tipologias/unity/Planos_Unity_Tipo-104-2023.png"),areaTechada:"53.54 m²", units:[{price:''}], units_available: 0},
            {id: 84, vista:"interior",type:"Flat 105",dorms:"1",img:require("../../Assets/images/tipologias/unity/Tipo105.jpg"),areaTechada:"40.12 m²", units:[{price:''}], units_available: 0},
            {id: 86, vista:"interior",type:"Flat 306",dorms:"2",img:require("../../Assets/images/tipologias/unity/Tipo-306.jpg"),metersTotal:"65.90 m²",areaTechada:"59.39 m²",areaLibre:"6.51 m²", units:[{price:''}], units_available: 0},
            {id: 197, vista:"interior",type:"Flat 1405",dorms:"1",img:require("../../Assets/images/tipologias/unity/unity-x05D-1405.jpg"),metersTotal:"82.06 m²",areaTechada:"69.38 m²",areaLibre:"12.68 m²", units:[{price:''}], units_available: 0},//
            {id: 198, vista:"interior",type:"Flat 1505",dorms:"1",img:require("../../Assets/images/tipologias/unity/unity-x05D-1505.jpg"),metersTotal:"82.06 m²",areaTechada:"69.38 m²",areaLibre:"12.68 m²", units:[{price:''}], units_available: 0},//
            {id: 91, vista:"interior",type:"Flat 1705",dorms:"2",img:require("../../Assets/images/tipologias/unity/Tipo-1705.jpg"),metersTotal:"82.06 m²",areaTechada:"69.38 m²",areaLibre:"12.68 m²", units:[{price:''}], units_available: 0},//
            {id: 93, vista:"exterior",type:"Flat SS02",dorms:"2",img:require("../../Assets/images/tipologias/unity/TipoSS202.jpg"),metersTotal:"100.91 m²",areaTechada:"64.49 m²",areaLibre:"36.42 m²", units:[{price:''}], units_available: 0},    
            {id: 94, vista:"interior",type:"Flat SS03",dorms:"1",img:require("../../Assets/images/tipologias/unity/ss03-tipologia.png"),areaTechada:"40.10 m²", units:[{price:''}], units_available: 0},//
            {id: 95, vista:"exterior",type:"Flat X01",dorms:"2",img:require("../../Assets/images/tipologias/unity/Tipo - x01.png"),metersTotal:"63.49 m²", units:[{price:''}], units_available: 0},
            {id: 96, vista:"exterior",type:"Flat X02",dorms:"3",img:require("../../Assets/images/tipologias/unity/Tipo - x02.png"),metersTotal:"64.43 m²", units:[{price:''}], units_available: 0},
            {id: 97, vista:"exterior",type:"Flat X03",dorms:"3",img:require("../../Assets/images/tipologias/unity/Tipo - x03.png"),metersTotal:"64.90 m²", units:[{price:''}], units_available: 0},
            // {id: 98, vista:"exterior",type:"Flat X03A",dorms:"2",img:require("../../Assets/images/tipologias/unity/TipoX03A.jpg"),metersTotal:"61.25 m²", units:[{price:''}], units_available: 0},
            {id: 100, vista:"exterior",type:"Flat X04",dorms:"2",img:require("../../Assets/images/tipologias/unity/TipoX04.jpg"),metersTotal:"58.27 m²", units:[{price:''}], units_available: 0},
            {id: 102, vista:"exterior",type:"Flat X04B",dorms:"2",img:require("../../Assets/images/tipologias/unity/flat-x04B.png"),metersTotal:"106.26 m²",areaTechada:"90.91 m²",areaLibre:"15.35 m²", units:[{price:''}], units_available: 0},
            {id: 106, vista:"interior",type:"Flat X05D",dorms:"1",img:require("../../Assets/images/tipologias/unity/TipoX05D.jpg"),metersTotal:"50,07 m²",areaTechada:"40.11 m²",areaLibre:"9.96 m²", units:[{price:''}], units_available: 0},
        ],
    }
}

export default unity