const benefits = {
    Referidos:{
        url:"/",
        name:"Campaña de referidos",
        paragraph:`<p class="paragraph paragraph-1">El Beneficio “Campaña de Referidos” es otorgado a los clientes que hayan adquirido un inmueble con nosotros y deseen referirnos a un familiar o amigo para la compra de un departamento en un proyecto desarrollado por Casaideal Inmobiliaria. 
        </p>
        <p class="paragraph paragraph">Bajo este beneficio, se reconoce el pago de hasta 6 meses en las cuotas de mantenimiento que variarán según el proyecto en el que compre la persona referida. Este beneficio será realizado bajo condiciones de cada proyecto.</p>`,
        img: require("../../Assets/images/beneficios/cards/desktop/referidos.png"),
        imgMovil: require("../../Assets/images/beneficios/cards/movil/referidos.png"),
        pdf:require("../../Assets/files/campana-de-referidos-2023.pdf"),
        kb:"95",
    },
    CuotaInicial:{
        name:"Ayuda en tu Cuota Inicial",
        paragraph:`
        <p class="paragraph paragraph-1">El beneficio "Ayuda en tu Cuota Inicial" está dirigido a nuestros clientes que deseen adquirir un departamento de estreno en Casaideal Inmobiliaria. Aplica para los proyectos Nou, Unity, Midway, Stay y Print.</p>
        <p class="paragraph paragraph">Bajo este beneficio, Casaideal Inmobiliaria asume un porcentaje de cuota inicial del departamento seleccionado. Esta venta será realizada bajo condiciones de cada proyecto.</p>`,
        img: require("../../Assets/images/beneficios/cards/desktop/cuota-inicial.png"),
        imgMovil: require("../../Assets/images/beneficios/cards/cuota-inicial.png"),
        pdf:require("../../Assets/pdfs/Ayuda-en-tu-Cuota-Inicial.pdf"),
        kb:"97"
    },
    pagarAlaEntrega:{
        name:"Compra hoy y paga  </br> a la entrega",
        img: require("../../Assets/images/beneficios/cards/desktop/paga-a-la-entrega.png"),
        imgMovil: require("../../Assets/images/beneficios/cards/regalo.png"),
        paragraph:`
        <p class="paragraph">El beneficio "Compra hoy y paga a la entrega" está dirigido a nuestros clientes que deseen adquirir un departamento de estreno de Casaideal Inmobiliaria. Aplica para los proyectos Midway, Unity y Print.</p>
        <p class="paragraph paragraph">Bajo este beneficio, Casaideal Inmobiliaria acepta el aplazamiento del desembolso del crédito hipotecario en el departamento vendido. Esta venta será realizada bajo condiciones de cada proyecto.</p>`,
        pdf:require("../../Assets/pdfs/compra-hoy-y-paga-a-la-entrega.pdf"),
        kb:"88",
    },
    cocinaDeRegalo:{
        name:"Kit de cocina de regalo",
        img: require("../../Assets/images/beneficios/cards/desktop/cocina-de-regalo.png"),
        imgMovil: require("../../Assets/images/beneficios/cards/kit-cocina.png"),
        paragraph:`<p class="paragraph paragraph-1">
        El beneficio "Kit de cocina de regalo" está dirigido a nuestros clientes que deseen adquirir un departamento de estreno en Casaideal Inmobiliaria. Aplica para los proyectos Nou, Unbox, Unity, Midway y Stay.
        </p>
        <p class="paragraph paragraph">Bajo este beneficio, Casaideal Inmobiliaria equipa la cocina con encimera, horno empotrado y campana el departamento vendido. Esta venta será realizada bajo condiciones de cada proyecto.</p>`,
        pdf:require("../../Assets/pdfs/kit-de-cocina-de-regalo.pdf"),
        kb:"88",
    },
    domaticaRegalo:{
        name:"Kit inteligente de regalo",
        img: require("../../Assets/images/beneficios/cards/desktop/kit-domotica.png"),
        imgMovil: require("../../Assets/images/beneficios/cards/kit-domatica.png"),
        paragraph:`<p class="paragraph paragraph-1">El beneficio "Kit Inteligente de Regalo" está dirigido a nuestros clientes que deseen adquirir un departamento de estreno en Casaideal Inmobiliaria. Aplica para el proyecto Unbox.</p>
        <p class="paragraph paragraph">Bajo este beneficio, Casaideal Inmobiliaria equipa con un Kit Standard o Premium el departamento vendido. Esta venta será realizada bajo condiciones de cada proyecto.</p>`,
        pdf:require("../../Assets/pdfs/kit-inteligente-de-regalo.pdf"),
        kb:"100",
    },
    productStandard:[
        {img:require("../../Assets/images/beneficios/promos/cerebro.png"),text:"Cerebro - Unidad Central",cant:"1"},
        {img:require("../../Assets/images/beneficios/promos/sensor.png"),text:"Sensor de movimiento",cant:"1"},
        {img:require("../../Assets/images/beneficios/promos/switch-double.png"),text:"Switch double - Monitoreo de luces o tomacorriente",cant:"1 circuito"},
        {img:require("../../Assets/images/beneficios/promos/enchufe.png"),text:"Adaptador de enchufe inteligente",cant:"1"},
        {img:require("../../Assets/images/beneficios/promos/google-home.png"),text:"Google Home mini - Asistente virtual",cant:"1"},
        {img:require("../../Assets/images/beneficios/promos/router.png"),text:"Router amplificador Wifi",cant:"1"},
    ],
    promosform:[
        {img:require("../../Assets/images/beneficios/promos/cerebro.png"),text:"Cerebro - Unidad Central",cant:"1"},
        {img:require("../../Assets/images/beneficios/promos/switch-double.png"),text:"Dimmer (varía la intensidad de la luces)",cant:"1 circuito"},
        {img:require("../../Assets/images/beneficios/promos/switch-double.png"),text:"Double Switch (Monitoreo de las luces / tomacorrientes)",cant:"2 circuitos"},
        {img:require("../../Assets/images/beneficios/promos/enchufe.png"),text:"Adaptador de enchufe inteligente",cant:"1"},
        {img:require("../../Assets/images/beneficios/promos/chromecast.png"),text:"Google Chromecast (Streaming)",cant:"1"},
        {img:require("../../Assets/images/beneficios/promos/google-home.png"),text:"Google Home mini - Asistente virtual",cant:"1"},
        {img:require("../../Assets/images/beneficios/promos/cerradura.png"),text:"Cerradura inteligente",cant:"1"},
        {img:require("../../Assets/images/beneficios/promos/router.png"),text:"Router amplificador Wifi",cant:"1"},
    ]
}
export default benefits