import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    activeCart: 0,
    activePopupSelect:false,
    tipologiesSelected:[{units:[{name:'default'}]}],
    priceSeparation: 1500,
    toggleShowCard:[],
    popup:false,
    listProjects:[],
    loader:false,
    popupMessage:true,
}

export const cartStore =  createSlice({
    initialState,
    name:"cart",
    reducers:{
        updateTipologiesSelected:(state,value)=>{
            state.tipologiesSelected = value.payload.data
            state.listProjects = value.payload.projectsCart
        },
        updateOnlyTipologiesSelected:(state,value)=>{
            state.tipologiesSelected = value.payload.data
        },
        closeMessageSelect:(state,value)=>{
            state.activePopupSelect = false
        },
        openMessageSelect:(state,value)=>{
            state.activePopupSelect = true
        },
        closeMessage:(state,value)=>{
            state.popupMessage = false
        },
        formatCurrency : (state,string) => {
            
        },
        openCart:(state)=>{
            state.activeCart = 1
        },
        hidePopupSelect:(state,element)=>{
            if(element.payload == 'modal-cart'){
                state.activePopupSelect = false
            }
        },
        hideCart:(state,element)=>{
            if(element.payload == 'modal-cart'){
                state.activeCart = 2
            }
        },
        addCard:(state,card)=>{
            console.log('card',card.payload);
            let ProjectName = card.payload.project.page;
            let newCard = JSON.parse(JSON.stringify(card.payload.card));
            // newCard.unit = newCard.units[card.payload.unit]
            newCard.project = ProjectName;

            if(state.listProjects.includes(ProjectName)){
                
            }else{
                state.listProjects.push(ProjectName);
                localStorage.setItem('projects',JSON.stringify(state.listProjects));
                if(state.listProjects.length > 1){
                    state.popupMessage = true;
                }
            }
            if(state.tipologiesSelected.length > 0){
                let filtered = state.tipologiesSelected.filter((item)=>{
                    if(item.id == newCard.id){
                        return item
                    }
                }) 
                if(filtered.length > 0){
                    // console.log(structuredClone(filtered))
                }else{
                    state.tipologiesSelected.push(newCard)
                    localStorage.setItem("cards",JSON.stringify(state.tipologiesSelected));
                }
            }else{
                state.tipologiesSelected.push(newCard)
                localStorage.setItem("cards",JSON.stringify(state.tipologiesSelected));
            }
            // console.log();
        },
        deleteCard:(state,card)=>{
            let ProjectName = card.payload.project;
            let items = state.tipologiesSelected.filter((item)=>{
                return item.type !== card.payload.type;
            });

            
            state.tipologiesSelected = items
            localStorage.setItem("cards",JSON.stringify(state.tipologiesSelected));
            console.log(state.tipologiesSelected.filter(e => e.project == ProjectName).length)
            if(state.listProjects.includes(ProjectName) && state.tipologiesSelected.filter(e => e.project == ProjectName).length < 1){
                
                let list = JSON.parse(JSON.stringify(state.listProjects));
                list.splice(list.indexOf(ProjectName),1)
                state.listProjects = list;

                localStorage.setItem("projects",JSON.stringify(state.listProjects))
            }
            window.dataLayer?.push({ ecommerce: null });  // Clear the previous ecommerce object.
            window.dataLayer?.push({
                event: "remove_from_cart",
                ecommerce: {
                    currency: card.payload.unit.currency,
                    value: state.priceSeparation,
                    items: [
                        {
                            item_id: card.payload.id,
                            item_name: card.payload.unit.type,
                            item_brand: "Casaideal",
                            item_category: card.payload.project.toLowerCase(),
                            item_category2: card.payload.vista,
                            item_category3: card.payload.dorms,
                            item_category4: card.payload.unit.total_area,
                            item_variant: card.payload.unit.name,
                            price: card.payload.unit.price,
                            quantity: 1
                        }
                    ]
                }
            })
        },
        updateUnits:(state,value)=>{
            let selects = JSON.parse(JSON.stringify(state.tipologiesSelected));
            selects.map((item)=>{
                if(item.id == value.payload.card.id){
                    item.unit = value.payload.item
                }
            })
            localStorage.setItem("cards",JSON.stringify(selects));
            state.tipologiesSelected = selects
        },
        ShowCard:(state,value)=>{
            state.toggleShowCard = value.payload 
        },
        handleLoader:(state,value)=>{
            state.loader = value.payload
        }
    }
})

export const {
    formatCurrency,
    hideCart,
    openCart,
    addCard,
    deleteCard,
    ShowCard,
    updateTipologiesSelected,
    updateOnlyTipologiesSelected,
    handleLoader,
    closeMessage,
    updateUnits,
    closeMessageSelect,
    openMessageSelect,
    hidePopupSelect,
} = cartStore.actions
export default cartStore.reducer