import React, { Component } from 'react'
import Contacto from '../../components/contacto/contacto'
import ContactoPromo from '../../components/contacto/contactoPromo'
import './terminos.scss'

export default class Terminos extends Component {
    constructor(props){
        super(props)
        console.log("terminos: ",props)
        
    }
    componentDidMount(){
        window.scrollTo(0,0)
    }
    render() {
        return (
        <div className="page-terminos">
        <section>
            <div className="SliderDepartamentos">
                <div className='background d-flex'>
                    <picture>
                        <source className="background " media="(min-width: 740px)" srcset={this.props.data.img}></source> 
                        <img className='background' src={this.props.data.imgMovil}></img>
                    </picture>
                </div>
                <span className="title-background" dangerouslySetInnerHTML={{__html:this.props.data.name}}></span>
                
            </div>
        </section>
        <section className="information container">
           
            <div className="info">
                <span className="handing-over bold">Descripción</span>
                <div className="content-paragraph" dangerouslySetInnerHTML={{__html:this.props.data.paragraph}}>
                   
                </div>
                <a href={this.props.data.pdf} target="_blank" className="btn-download outline-gray">
                    <div className="icon-button">
                        <img className="icon-download" src={require("../../Assets/iconos/icon-download.svg").default}></img>
                    </div>
                    <div className="text-button">
                        Términos y condiciones
                        <span className="peso">{this.props.data.kb} kb</span>
                    </div>
                </a>
                {
                    this.props.kitStandar ?
                <div className="content-all">
                
                    <div className="content-list">
                        <h2 className="title-list">Kit de Domótica Standard</h2>
                        <div className="table">
                            <div className="header-table">
                                <div className="name-product">Producto</div>
                                <div className="cant-product">Cantidad</div>
                            </div>
                            <div className="body-table">
                                {
                                    this.props.kitStandar.map((e)=>{
                                        return(
                                            <div className="line-product">
                                            <div className="name-product"><img className="img-product" src={e.img}></img>{e.text}</div>
                                            <div className="cant-product">{e.cant}</div>
                                        </div> 
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <div className="content-list content-list-2">
                        <h2 className="title-list title-list-2">Kit de domótica Premium</h2>
                        <div className="table">
                            <div className="header-table">
                                <div className="name-product">Producto</div>
                                <div className="cant-product">Cantidad</div>
                            </div>
                            <div className="body-table">
                                {
                                    this.props.productPremiun.map((e)=>{
                                        return(
                                            <div className="line-product">
                                            <div className="name-product"><img className="img-product" src={e.img}></img>{e.text}</div>
                                            <div className="cant-product">{e.cant}</div>
                                        </div> 
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div> : null
                }
                
            </div>
            <div className="form" id="formulario">
                <ContactoPromo 
                    page={"benefits"}
                    promo={this.props.data.name}
                    options={this.props.option}
                ></ContactoPromo>
            </div>
        </section>
        </div>
        )
    }
}
