let midway = {
    sperant_id: 21,
    route:"/midway",
    page:"midway",
    district:'Magdalena',
    whatsapp:["968206497"],
    titlehead: "Proyecto Stay | Departamentos en Jesús María",
	metadescription:"Vive en la mejor zona de Magdalena, límite con San Isidro, en Av. Pershing 162. Departamentos de 1, 2 y 3 dorms desde S/321,700. Encuentra en Midway la opción ideal para disfrutar de tu propio espacio y acompañado de tu familia con excelentes áreas sociales. Cotiza aquí.",
	metakeywords:"departamentos en magdalena del mar, departamentos en venta en magdalena, inmobiliaria, magdalena del mar, casaideal inmobiliaria, departamentos límite con san Isidro, departamentos de 1, 2 y 3 dormitorios, edificio en construcción, departamentos en construcción",
    iziPubKey: {
        prod: "68985624:publickey_Eapp5w12r3PSsillp6dax6trRTaoH8I380S0wx12TLQfF",
        dev: "68985624:testpublickey_2OVV3DzDPbjC4im1VdHQrUPaVzSs2nGYGxEATpHWylqzn",
    },
    areas:{
        cant:4,
        comunes:[
            {title:"LOBBY",icon:"lobby",desktop:require("../../Assets/images/Slider/midway/comunes/lobby.jpg"),movil:require("../../Assets/images/Slider/midway/comunes/lobby.jpg"),position:"center 0%",paragraph:"Un lobby con detalles en mármol y cemento expuesto dan modernidad al ingreso."},
            {title:"ZONA COWORKING",icon:"sala-coworking",desktop:require("../../Assets/images/Slider/midway/comunes/coworking.jpg"),movil:require("../../Assets/images/Slider/midway/comunes/coworking.jpg"),paragraph:"Un ambiente ideal para hacer home office o recibir clientes sin salir de casa."},
            {title:"GAME ROOM",icon:"game",desktop:require("../../Assets/images/Slider/midway/comunes/game-room.jpg"),movil:require("../../Assets/images/Slider/midway/comunes/game-room.jpg"),paragraph:"Un ambiente especial para los amantes de los videojuegos. Completamente equipado para que disfrutes con tus amigos."},
            {title:"GYM",icon:"gym",desktop:require("../../Assets/images/Slider/midway/comunes/gym.jpg"),movil:require("../../Assets/images/Slider/midway/comunes/gym.jpg"),paragraph:"Ejercítate sin salir de casa y lleva un estilo de vida saludable. Gimnasio equipado con las mejores máquinas."},
            {title:"ZONA BBQ",icon:"parrilla",desktop:require("../../Assets/images/Slider/midway/comunes/FABRE_PERSHING_TERRAZA.jpg"),movil:require("../../Assets/images/Slider/midway/comunes/FABRE_PERSHING_TERRAZA.jpg"),position:"center 40%",paragraph:"Ubicado en el último piso, con una zona lounge y una terraza donde podrás pasar momentos increíbles con la familia y amigos."},
            {title:"TERRAZA",icon:"icon-terraza",desktop:require("../../Assets/images/Slider/midway/comunes/FABRE_PERSHING_AREA_VERDE.jpg"),movil:require("../../Assets/images/Slider/midway/comunes/FABRE_PERSHING_AREA_VERDE.jpg"),paragraph:"Ubicado en el último piso, con una zona lounge y una terraza donde podrás pasar momentos increíbles con la familia y amigos."},
            {title:"ZONA DE NIÑOS",icon:"zona-child",desktop:require("../../Assets/images/Slider/midway/comunes/FABRE_PERSHING_ZONA_DE_CHILD.jpg"),movil:require("../../Assets/images/Slider/midway/comunes/FABRE_PERSHING_ZONA_DE_CHILD.jpg"),paragraph:"El espacio perfecto para compartir con los pequeños de la familia."},
            {title:"PETSHOWER",icon:"pet",desktop:require("../../Assets/images/Slider/midway/comunes/petshower.jpg"),movil:require("../../Assets/images/Slider/midway/comunes/petshower.jpg"),paragraph:"Un excelente lugar para el cuidado que tu mascota necesita."},
            {title:"ZONA DE LAVADO",icon:"lavado",desktop:require("../../Assets/images/Slider/midway/comunes/zona-de-lavado.jpg"),movil:require("../../Assets/images/Slider/midway/comunes/zona-de-lavado.jpg"),paragraph:"Contar con un centro de lavado como área común es un gran beneficio ya que aprovechamos más los espacios dentro de nuestro departamento. Un ambiente completamente funcional donde podrás realizar tus tareas de aseo."},
        ],
        interiores:[
            {title:"SALA",icon:"lobby",desktop:require("../../Assets/images/Slider/midway/interiores/FABRE_PERSHING_SALA.jpg"),movil:require("../../Assets/images/Slider/midway/interiores/FABRE_PERSHING_SALA.jpg"),paragraph:"Espacios funcionales conectados por estilo, diseño y una perfecta distribución de los ambientes."},
            {title:"DORMITORIO PRINCIPAL",icon:"bed",desktop:require("../../Assets/images/Slider/midway/interiores/FABRE_PERSHING_DORMITORIO_PRINCIPAL.jpg"),movil:require("../../Assets/images/Slider/midway/interiores/FABRE_PERSHING_DORMITORIO_PRINCIPAL.jpg"),paragraph:"Habitación ideal para tu día a día. Buena luz natural con amplio espacio.",},
            {title:"DORMITORIO DE NIÑO",icon:"male",desktop:require("../../Assets/images/Slider/midway/interiores/FABRE_PERSHING_DORMITORIO_CHILD.jpg"),movil:require("../../Assets/images/Slider/midway/interiores/FABRE_PERSHING_DORMITORIO_CHILD.jpg"),position:"center 56%",paragraph:"Habitación ideal para los pequeños de la casa.",},
            {title:"DORMITORIO DE NIÑA",icon:"famele",desktop:require("../../Assets/images/Slider/midway/interiores/dorm-girl.jpg"), movil:require("../../Assets/images/Slider/midway/interiores/dorm-girl.jpg"),position:"center 79%",paragraph:"Habitación ideal para los pequeños de la casa.",},
            {title:"COCINA",icon:"refri",desktop:require("../../Assets/images/Slider/midway/interiores/FABRE_PERSHING_COCINA.jpg"),movil:require("../../Assets/images/Slider/midway/interiores/FABRE_PERSHING_COCINA.jpg"),position:"center 57%",paragraph:"Cocina de concepto abierto con detalles en granito, muebles altos y bajos con luminarias elegantes para hacer de este un espacio acogedor.",},
            {title:"BAÑO PRINCIPAL",icon:"ducha",desktop:require("../../Assets/images/Slider/midway/interiores/FABRE_PERSHING_BATH_PRINCIPAL.jpg"),movil:require("../../Assets/images/Slider/midway/interiores/FABRE_PERSHING_BATH_PRINCIPAL.jpg"),paragraph:"Con acabados en granito y detalles en madera para tener momentos relajantes.",},
            {title:"BAÑO SECUNDARIO",icon:"ducha",desktop:require("../../Assets/images/Slider/midway/interiores/FABRE_PERSHING_BATH_SECUNDARIO.jpg"),movil:require("../../Assets/images/Slider/midway/interiores/FABRE_PERSHING_BATH_SECUNDARIO.jpg"),paragraph:"Con acabados en granito y detalles en madera para tener momentos relajantes.",},
        ],
        exteriores:{
            cantButton:"recorrido-none",
            title:"Avance de obra Agosto",
            desktop:[
                {img:require("../../Assets/images/Slider/exteriores/ingreso-midway.jpg")},
                {img:require("../../Assets/images/Slider/exteriores/dia/midway.jpg")},
                {img:require("../../Assets/images/Slider/exteriores/noche/midway.jpg")},
            ]
        },
        avance:{
            cantButton:"avance-none",
            title:"Avance de obra Junio",
            desktop:[
                {img:require("../../Assets/images/Slider/midway/avance/img-1.webp")},
                {img:require("../../Assets/images/Slider/midway/avance/img-2.webp")},
                {img:require("../../Assets/images/Slider/midway/avance/img-3.webp")},
                {img:require("../../Assets/images/Slider/midway/avance/img-4.webp")},
                {img:require("../../Assets/images/Slider/midway/avance/img-5.webp")},
                {img:require("../../Assets/images/Slider/midway/avance/img-6.webp")},
            ],
        },
        end_project:{
            cantButton:5,
            title:"",
            images:[]
        },
        depa_piloto:[
            { img: require('../../Assets/images/Slider/midway/depa_piloto/compress-DSC09394.webp') },
            { img: require('../../Assets/images/Slider/midway/depa_piloto/compress-DSC09399.webp') },
            { img: require('../../Assets/images/Slider/midway/depa_piloto/compress-DSC09404.webp') },
            { img: require('../../Assets/images/Slider/midway/depa_piloto/compress-DSC09424.webp') },
            { img: require('../../Assets/images/Slider/midway/depa_piloto/compress-DSC09438.webp') },
            { img: require('../../Assets/images/Slider/midway/depa_piloto/compress-DSC09443.webp') },
            { img: require('../../Assets/images/Slider/midway/depa_piloto/compress-DSC09463.webp') },
            { img: require('../../Assets/images/Slider/midway/depa_piloto/compress-DSC09468.webp') },
            { img: require('../../Assets/images/Slider/midway/depa_piloto/compress-DSC09509.webp') },
            { img: require('../../Assets/images/Slider/midway/depa_piloto/compress-DSC09543.webp') },
            { img: require('../../Assets/images/Slider/midway/depa_piloto/compress-DSC09554.webp') },
            { img: require('../../Assets/images/Slider/midway/depa_piloto/compress-DSC09613.webp') },
            { img: require('../../Assets/images/Slider/midway/depa_piloto/compress-DSC09628.webp') },
        ],
        recorrido:"https://kuula.co/share/collection/7Yk8N?fs=1&vr=1&sd=1&initload=0&thumbs=3&inst=es&info=0&logo=-1",
    },
    form:[
        {value:1,title:"1 Dormitorio"},
        {value:2,title:"2 Dormitorios"},
        {value:3,title:"3 Dormitorios"}
    ],
    info:{
        title:"TU NUEVO ",subtitle:"HOGAR",
        entrega:'Próxima Entrega',
        entregamovil:'En Construcción',
        frase:"EN MAGDALENA DEL MAR LÍMITE CON <span class='bold'>SAN ISIDRO</span>",
        pisos:"20",depas:"118",dorms:"1, 2 y 3 Dorms.",ubicacion:"Av. Pershing N° 162, Magdalena del Mar",precio:"S/321,700",desde:"41m²",hasta:"77m²",
        fachada:require("../../Assets/images/fachada/Slide/MIDWAY.jpg"),
        fachadaMinified:require("../../Assets/images/fachada/Slide/resize/resize-MIDWAY.jpg"),
        // ingreso:require("../../Assets/images/fachada/midway/fachada-midway-noche.png"),
        parrafo:'<p class="paragraph paragraph-1">Midway se encuentra a tan solo unos pasos de parques, universidades, centros comerciales y las principales vías de acceso. Nuestro proyecto ha sido diseñado bajo una propuesta que busca transmitir calidez y confort a sus usuarios. </p><p class="paragraph paragraph-2">Encuentra en Midway la opción ideal para disfrutar de tu propio espacio y acompañado de tu familia con excelentes áreas sociales.</p>',
        parrafoArquitectura1:'Departamentos para vivir cerca a las cosas que te gustan. Cafés, restaurantes, supermercados, parques, etc.',
        brochure: '/brochure/Brochure-midway.pdf',
        arquitectura:'<span class="bold">ARQUITECTURA </span>Y DISEÑO',
        mapa:require("../../Assets/mapas/mapa-midway.jpg"),
        class:"",  
        sitios:[
            {title:"Centros de Salud",sitio:["Hospital Militar","Centro Médico Jesús María","Policlínico Peruano Japonés","Medicis","Centro de Salud Magdalena","MedSolutions"]},
            {title:"Colegios y Centros de estudio",sitio:["Colegio San Felipe","Isil","Euroidiomas","Bibliotea Municipal","Universidad Jaime Bausate y Meza","Colegio de Jesú","Colegio Newton","Colegio Retama","Pamer","CEPRE UNI","Toulouse Lautrec"]},
            {title:"Supermercados y centros comenrciales",sitio:["Metro","Centro Comercial San Felipe","Real Plaza Salaverry","Vivanda"]},
        ],
    },
    slideDepa:[
        {desktop:require("../../Assets/images/Slider/midway/sliderMain/newMidway-1.jpg"),movil:require("../../Assets/images/Slider/midway/sliderMain/newMidway-1.jpg")},
        {desktop:require("../../Assets/images/Slider/midway/sliderMain/newMidway-2.jpg"),movil:require("../../Assets/images/Slider/midway/sliderMain/newMidway-2.jpg")},
        {desktop:require("../../Assets/images/Slider/midway/sliderMain/newMidway-3.jpg"),movil:require("../../Assets/images/Slider/midway/sliderMain/newMidway-3.jpg")},
        {desktop:require("../../Assets/images/Slider/midway/sliderMain/newMidway-4.jpg"),movil:require("../../Assets/images/Slider/midway/sliderMain/newMidway-4.jpg")},
        {desktop:require("../../Assets/images/Slider/midway/sliderMain/newMidway-5.jpg"),movil:require("../../Assets/images/Slider/midway/sliderMain/newMidway-5.jpg")},
        {desktop:require("../../Assets/images/Slider/midway/sliderMain/newMidway-6.jpg"),movil:require("../../Assets/images/Slider/midway/sliderMain/newMidway-6.jpg")},
        {desktop:require("../../Assets/images/Slider/midway/sliderMain/newMidway-7.jpg"),movil:require("../../Assets/images/Slider/midway/sliderMain/newMidway-7.jpg")},
    ],
    atributos:[
        {icon:"gas",text:"Conexiones de</br><strong>gas natural</strong>"},
        {icon:"camara",text:"<strong>Cámaras de seguridad</strong></br> en áreas comunes"},
        {icon:"lobby",text:"Lobby de </br><strong>doble altura</strong>"},
        {icon:"sala-coworking",text:"<strong>Zona coworking</strong>"},
        {icon:"game",text:"<strong>Game room</strong>"},
        {icon:"gym",text:"<strong>Gym</strong>"},
        {icon:"parrilla",text:"<strong>Zona BBQ</strong>"},
        {icon:"jardin",text:"<strong>Terraza Jardín</strong>"},
        {icon:"area-child",text:"<strong>Zona de niños</strong>"},
        {icon:"pet",text:"<strong>Petshower</strong>"},
        {icon:"lavado",text:"<strong>Zona de lavado</strong>"},
        {icon:"none",text:""},
    ],
    tipologias:{
        page:"midway",
        template_id: 91,
        buttons:[{text:"01",value:1},{text:"02",value:2},{text:"03",value:3},{text:"Todos",value:"all"}],
        brand:require('../../Assets/images/proyectos/midway.png'),
        cards: [
            // {id: 112,type:"Flat 102",dorms:"2",vista:"interior",img:require("../../Assets/images/tipologias/midway/Tipo-102.jpg"),metersTotal:"71,73 m²",areaTechada:"50,40 m²",areaLibre:"21,33 m²", units:[], units_available: 0},
            // {id: 113,type:"Flat 103",dorms:"2",vista:"interior",img:require("../../Assets/images/tipologias/midway/Tipo-103.jpg"),metersTotal:"80,59 m²",areaTechada:"59,15 m²",areaLibre:"21,44 m²", units:[], units_available: 0},
            {id: 114,type:"Flat 104",dorms:"2",vista:"interior",img:require("../../Assets/images/tipologias/midway/Tipo-104.jpg"),metersTotal:"67,72 m²",areaTechada:"51,31 m²",areaLibre:"16,41 m²", units:[{price: ""}], units_available: 0},
            {id: 115,type:"Flat 105",dorms:"2",vista:"interior",img:require("../../Assets/images/tipologias/midway/midway-105.jpg"),metersTotal:"69,62 m²",areaTechada:"54,07 m²",areaLibre:"15,55 m²", units:[{price: ""}], units_available: 0},
            // {id: 116,type:"Flat 106",dorms:"2",vista:"interior",img:require("../../Assets/images/tipologias/midway/Tipo-106.jpg"),metersTotal:"66,63 m²",areaTechada:"48,90 m²",areaLibre:"17,73 m²", units:[{price: ""}], units_available: 0},
            // {id: 121,type:"Flat 202",dorms:"3",vista:"exterior",img:require("../../Assets/images/tipologias/midway/Tipo-202.jpg"),metersTotal:"76,18 m²", units:[{price: ""}], units_available: 0},
            {id: 122,type:"Flat 203",dorms:"3",vista:"exterior",img:require("../../Assets/images/tipologias/midway/Tipo-203.jpg"),metersTotal:"76,47 m²", units:[{price: ""}], units_available: 0},
            // {id: 96,type:"Flat 705",dorms:"1",vista:"interior",img:require("../../Assets/images/tipologias/midway/Midway-705.png"),metersTotal:"58.01 m²",areaTechada:"41,16 m²",areaLibre:"16,85 m²", units:[{price: ""}], units_available: 0},
            {id: 126,type:"Flat 706",dorms:"1",vista:"interior",img:require("../../Assets/images/tipologias/midway/Tipo-706.jpg"),metersTotal:"50,56 m²",areaTechada:"42,97 m²",areaLibre:"7,59 m²", units:[{price: ""}], units_available: 0},
            {id: 127,type:"Flat 707",dorms:"1",vista:"interior",img:require("../../Assets/images/tipologias/midway/Tipo-707.jpg"),metersTotal:"46,67 m²",areaTechada:"40,03 m²",areaLibre:"6,64 m²", units:[{price: ""}], units_available: 0},
            // {id: 109,type:"Flat 1005",dorms:"3",vista:"interior",img:require("../../Assets/images/tipologias/midway/Tipo-1005.jpg"),metersTotal:"90,40 m²",areaTechada:"77,68 m²",areaLibre:"12,72 m²", units:[{price: ""}], units_available: 0},
            {id: 110,type:"Flat 1006",dorms:"3",vista:"exterior",img:require("../../Assets/images/tipologias/midway/Tipo-1005.jpg"),metersTotal:"77.79 m²",areaTechada:"77,68 m²",areaLibre:"12,72 m²", units:[{price: ""}], units_available: 0},
            {id: 117,type:"Flat 1105",dorms:"3",vista:"interior",img:require("../../Assets/images/tipologias/midway/Tipo-1105.jpg"),metersTotal:"76,75 m²",areaTechada:"69,89 m²",areaLibre:"6,86 m²", units:[{price: ""}], units_available: 0},
            {id: 119,type:"Flat 1205",dorms:"2",vista:"interior",img:require("../../Assets/images/tipologias/midway/midway-1205.jpg"),metersTotal:"71.41 m²", units:[{price: ""}], units_available: 0},
            {id: 129,type:"Flat X01",dorms:"2",vista:"interior",img:require("../../Assets/images/tipologias/midway/X01.jpg"),metersTotal:"49,44 m²", units:[{price: ""}], units_available: 0},
            {id: 130,type:"Flat X02",dorms:"3",vista:"exterior",img:require("../../Assets/images/tipologias/midway/X02.jpg"),metersTotal:"61,83 m²", units:[{price: ""}], units_available: 0},
            {id: 131,type:"Flat X03",dorms:"3",vista:"exterior",img:require("../../Assets/images/tipologias/midway/X03.jpg"),metersTotal:"63,56 m²", units:[{price: ""}], units_available: 0},
            {id: 132,type:"Flat X04",dorms:"2",vista:"interior",img:require("../../Assets/images/tipologias/midway/x04.png"),metersTotal:"50,69 m²", units:[{price: ""}], units_available: 0},
            {id: 133,type:"Flat X05",dorms:"2",vista:"interior",img:require("../../Assets/images/tipologias/midway/X05.jpg"),metersTotal:"58,85 m²", units:[{price: ""}], units_available: 0},
            {id: 134,type:"Flat X05A",dorms:"1",vista:"interior",img:require("../../Assets/images/tipologias/midway/X05A.jpg"),metersTotal:"58,01 m²",areaTechada:"41,16 m²",areaLibre:"16,85 m²", units:[{price: ""}], units_available: 0},
            {id: 135,type:"Flat X06",dorms:"2",vista:"interior",img:require("../../Assets/images/tipologias/midway/X06.jpg"),metersTotal:"50,57 m²", units:[{price: ""}], units_available: 0},
            {id: 136,type:"Flat X06A",dorms:"1",vista:"interior",img:require("../../Assets/images/tipologias/midway/X06A.jpg"),metersTotal:"42,97 m²", units:[{price: ""}], units_available: 0},
            {id: 137,type:"Flat X07",dorms:"2",vista:"interior",img:require("../../Assets/images/tipologias/midway/X07.jpg"),metersTotal:"52,53 m²", units:[{price: ""}], units_available: 0},
            {id: 138,type:"Flat X07A",dorms:"1",vista:"interior",img:require("../../Assets/images/tipologias/midway/X07A.jpg"),metersTotal:"40,03 m²", units:[{price: ""}], units_available: 0},
            {id: 139,type:"Flat X08",dorms:"2",vista:"interior",img:require("../../Assets/images/tipologias/midway/X08.jpg"),metersTotal:"48,71 m²", units:[{price: ""}], units_available: 0},
            {id: 140,type:"Flat X08A",dorms:"1",vista:"interior",img:require("../../Assets/images/tipologias/midway/X08A.jpg"),metersTotal:"39,62 m²", units:[{price: ""}], units_available: 0}, 
        ],
    },
}

export default midway