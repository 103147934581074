import { useEffect } from 'react';
import { Route } from 'react-router-dom';
import Thanks from '../../components/thanks/thanks';

export const ContentThanks = (props) => {
  useEffect(() => {
    if (typeof window.gtag !== 'undefined' && props.id !== undefined) {
        window.gtag('event', 'conversion', {'send_to': `${props.id}`});
      }
  }, []);

  return (
    // Aquí va el contenido de tu componente de agradecimiento
    <Thanks />
  );
};
