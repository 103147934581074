import React from "react"
import Skeleton from "react-loading-skeleton";
import MediaQuery from "react-responsive";

export const ProductSkeletons = ({number, cards}) => Array(number).fill().map((item, index) => (
    <div className='content-project' >
        <div className='reference-project'>
            <div className='content-text'>
                <span className='text'>CARGANDO DATOS ...</span>
                <span className='radius b-secondary semi-bold-poppins c-white uppercase'>Distrito</span>
            </div>
            <div className='content-img'>
                <Skeleton height={150} width={`100%`} />

                {/* <img className='' src={item.info.fachadaMinified} ></img> */}
            </div>
        </div>
        <div className='targets'>
            <div className='resume'>
                <div className='radius b-white c-black shadow'>
                    Ocultar
                    <img className={``} src={require('../../Assets/images/iconos/signal-down.svg').default}></img>
                </div>
            </div>
            <div className='content-cards'>

            <MediaQuery query="(min-width: 500px)">
                {Array(cards).fill().map((item, index) => (
                    <Skeleton height={384} width={`100%`} />
                ))}
            </MediaQuery>
            <MediaQuery query="(max-width: 499px)">
                <div style={{padding: "1em"}}>
                    <Skeleton height={384} width={`100%`} />
                </div>
                {/* <SliderStoreComponent item={item} active={this.state.toggleShowCard} project={item}/> */}
            </MediaQuery>
            </div>
        </div>
    </div>
))